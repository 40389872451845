"use strict";

import React from "react";
import Helpers from "../mixins/Helpers";

const Button = function ({ children }) {
  return <input>{children}</input>;
};


export default Helpers.Scroll(Button);
