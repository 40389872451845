import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, compose } from "redux";
import renderRoutes from "routes";
import Reducers from "./reducer";
import "core-js/stable";
import "regenerator-runtime/runtime";
import PropTypes from "prop-types";

import "css";

class App extends React.Component {
  render() {
    return (
      this.props.children
    );
  }
}

App.propTypes = {
  children: PropTypes.object,
};

const store = compose(
  window.devToolsExtension ? window.devToolsExtension() : (f) => f
)(createStore)(Reducers);

ReactDOM.render(
  <Provider store={store}>
    <App children={renderRoutes()} />
  </Provider>, document.getElementById("app")
);

export default App;
