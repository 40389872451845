import React from "react";
import "./styles.scss";
import { COLORS } from "../../shared/constants";
import PropTypes from "prop-types";

export default class UKMapSVGContainer extends React.Component {
  UNSAFE_componentWillMount() {
    this.setState({
      currentLocation: this.props.currentLocation,
    });
  }

  shouldComponentUpdate() {
    return this.props.currentLocation === this.state.currentLocation;
  }

  getColor(key) {
    const year = this.props.data.get("year");
    const value = this.props.data.get("data")[key].jobsTotal[year];
    const legendGroups = [
      [0, 500],
      [500, 749],
      [750, 999],
      [1000, 1499],
      [1500, 2000],
      [2000, 9999],
    ];

    let color = 0;
    legendGroups.forEach((group, k) => {
      if (value >= group[0] && value <= group[1]) {
        color = k;
      }
    });

    return COLORS[color];
  }

  /* eslint-disable max-len */

  render() {
    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 462.5 529.6"
        enableBackground="new 0 0 462.5 529.6"
        className={this.props.className}
        onMouseMove={this.props.onMouseMove}
        onClick={this.props.onClick}
        onMouseOver={this.props.onMouseOver}
      >
        <g id="Layer_2">
          <g id="map">
            <path
              id="ukSvg-london"
              className="st0"
              fill={this.getColor("london")}
              d="M389.2,446c0,0.6-2,2.7-2.3,3.2c1.8-5.6-4.5-5.3-6-8c-0.1-0.2-1.9-9-1.1-10
                c3.6,2.7,9.6-2,11.2-2.7c0.3-0.1,1.4-1.2,2-1.3c4-0.7,3.4,1.3,5.1,2.5c4.1,3.1,2.6,0.9,7.4,1.2c2.4,0.1,4.9,5,5.7,4.9
                c-1.4,0.3-1.7,1-2.5,1.4c-1.1-0.5,0.3-0.6-0.4-0.4c-0.9,2.2-5.2,16-7.6,13.5c-0.4,0.2-0.5,0.5-0.5,0.9c-0.9-6.1-4.1,0.7-6.1-0.3
                c-0.2-1.8-1.7-2.8-3.5-2C391,447,390.2,446.7,389.2,446z"
            />
            <path
              id="ukSvg-eastEngland"
              className="st1"
              fill={this.getColor("eastEngland")}
              d="M436,416.7c-0.6-0.6-1.3-1.1-1.9-1.7c0.5,1,0.9,1.8,1.5,2.8c-0.2-0.1-0.9-0.6-0.8-0.8
                c0.2,0.5,0.1,0.6-0.4,0.4c0.4,0.2,1.1,0.7,1.5,1c-2-0.6-3.1,0.1-3.6,2.1c-0.5-0.2-1.9-0.3-2.2-0.4c0.9,0.3,1.7,0.7,2.2,1
                c-0.6,0.6-1.1-0.2-1.5-0.4c0.2,0.1-0.6,0.3-0.6,0.3c0.9,0.5,0.6,0.3,1.6,0.6c-0.9,0.7-1.9,1.9-3.3,0.9c-1.1,0.4-2.5,0.9-4.2,0.8
                c1.3,0.7,1.5,1.2,3.3,1.4c-0.1,0.2-0.9,0.7-0.6,1c2.6,0.7,7.1-7.7,7.5,0.2c0.1,1.3,0.2,3-1.6,4c-0.9,0.5-8.5,0-9-0.4
                c0.6,0.7,6.3,0.9,7.6,1c0.1-0.1-1.2,2.9-1.8,0.9c0.2-0.2-1.4,1.6-1.3,1.2c0-0.3,2.4,1.5,2.7,1.6c0.8,0.4-4.3,1.9-4,1.8
                c-1.1,0.3-3.1-0.4-4.2-0.4c-0.4,0-5.5-0.8-3.5,1.8c-0.3,0.5-3.1,0.8-3.7,1.2c-1.7,1.1-0.7,2.4-3.6,2.7c-3.1,0.3-6-2.5-3.6-4.7
                c-0.1,0.6,0.1,0.8,0.6,0.7c0.7-0.4,1.2-1.1,2.5-1.4c-0.8,0.1-3.3-4.8-5.3-5.1c-4.9-0.6-2.9,2.2-7.2-1c-1.4-1.1-0.5-2.3-3.5-2.8
                c-2.5-0.4-2.5,0.8-4,1.5c-0.1,0.1-1.7,1.3-2.7,1.7c-2.3,1-6.1,2.7-8.6,1c-2.6,3.2,0.4-2.8-1.9-2.7c0.2-5.1-3.8-3.4-5.9-8.2
                c-2.2-5,1,0.5,4.5,0.4c0.8-0.6,1.1-0.9,1.6-1.3c-0.9-1.7-2.3-2-3.4-3.3c-2.1-2.5-1.6-0.6-2.2-3.7c-0.2-1.3,1.9-4.8-0.1-6.9
                c3.5-3.8,1.9-5,1-8.4c-0.3-1.1-1.1-4.1,0.3-5.3c1.5-1.4,1.8,1.5,3.4,0.6c-0.6,0.4,2.7-4.8,2.6-4.5c0.3-0.9-0.6-1.9,0-2.9
                c2.3-3.9,4.4-3,3.3-8c-0.9-4.1-3.7-3.5-5-7.6c1.9,0.1,4.5-2.1,6.4-2.2c1.6-0.1,3.4,0.7,4.7,0.7c1.2,0,2.6-0.4,4.1-0.5
                c1-0.1,2.7,0.6,3.5,0.2c2.2-1.1,0.3-2.8,2-3.9c2.2-1.5,5-0.7,7.6-3c-2.2-2.6,2.3-3.6,3.9-0.3c-0.3-0.8,3-10,4.1-11.1
                c3.5-3.4,10.2-0.8,13.2-0.5c0.4,0-0.3-1.7,0.4-1.5c2.1,0.6,3.4,1.2,6.1,0.5c-0.4-0.1-2.5-0.5-2.5-0.4c0.6-2.9,19.2,6.4,21,8.1
                c0.2,0.2,1.3,1,1.7,1.3s1.4,1.2,1.7,1.5s1.3,1.2,1.6,1.5c0.5,0.5,0.9,1,1.3,1.5c0.2,0.3,0.4,0.6,0.5,0.9c0.2,0.5,0.4,0.9,0.6,1.4
                s0.6,1.4,0.6,1.5s0.3,1,0.4,1.3c0.2,0.4,0.2,0.8,0.2,1.2v2.9c-0.1,0-0.2-0.1-0.2-0.2c0-0.2-0.2-0.7-0.2-0.9s-0.1-0.8-0.1-0.9
                s-0.2-0.6-0.6-0.6s-0.6,0-0.7,0.3c-0.1,0.2-0.3,0.3-0.5,0.4c-0.3,0.1-0.6,0.3-0.8,0.6c-0.2,0.4,0.1,0.2,0.5,0
                c0.3-0.1,0.6-0.2,0.8-0.4c0.2-0.2,0.5-0.4,0.8-0.5c0.1,0,0.3,0,0.3,0.3s0.2,0.7,0.2,1s0.1,0.6,0.3,0.9c0.1,0.1,0.2,0.3,0.2,0.5
                s0.1,0.9,0.1,1.2s0.1,0.4,0.1,0.7s0.1,0.3,0.1,0.3c0,0.1,0,0.2,0.1,0.2l0.1,0.2l0.1,0.3v0.6l0.1,0.3v0.2l0.1,0.5v0.2l0.1,0.2
                l-0.1,0.2c0,0,0,0.3-0.1,0.3s-0.4-0.2-0.5-0.1s0.1,0.4,0.1,0.4l0.1,0.3l-0.2,0.5c-0.1,0.4-0.1,0.8-0.1,1.1c0,0.5,0,1,0,1.5
                c0,0.2,0.1,0.4,0.2,0.6l-0.3,0.9l-0.5,1.3l-0.3,1c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.2-0.1,0.3-0.2,0.4l-0.2,0.9l-0.3,0.9
                c-0.1,0-0.2,0.1-0.3,0.2c-0.2,0.3-0.5,0.7-0.7,1c-0.4,0.5-0.6,1.1-0.6,1.8v1.9c0,0-0.1,1.1-0.1,1.5c0.1,0.6,0.1,1.2,0,1.8
                c-0.2,0.3-0.3,0.7-0.4,1s-0.2,0.5-0.2,0.8c0,0.2,0,0.5-0.1,0.7l-0.2,1l-0.2,1.1c0,0.4,0,0.9-0.1,1.3c-0.2,0.3-0.4,0.5-0.7,0.6
                c-0.3,0.1-1.8,0.9-1.8,0.9l-1,0.6c0,0-0.5,0.4-0.5,0.6c-0.1,0.5-0.2,0.5-0.1-0.2c0.2-0.2,0.4-0.4,0.7-0.6c0.2-0.1,0.6-0.3,0.5-0.4
                s-0.2-0.1-0.3,0l-0.6,0.5c-0.5,1.6-1.5,3.2-2.8,4.4c-1.5-1.5-1.7-5.7-4-5.4c1.4,0.9,6,8.4,1.3,8.8c0,0-4.8-6.5-6.2-6.5
                c-1.7,0.7,5.4,3.9,4.6,5.1c-0.6,1.1-7.6-1-7.9,0.8c-0.1,1,7.9-0.2,8.2-0.3c0.9,0-2.7,4.4-2.7,3.3c0.3,0,0.5,0.1,0.6,0.4
                c-0.4,0.5-1.2,0.8-1.6,1.3c0.1,0,2.7,1,3.2,0.5c0.2-0.5-0.4-1-0.3-1.4c2.9,3-6.2,9.8-8.5,6.2c0.2,0.1,0.3,0.2,0.3,0.3
                c0.3-0.5-0.8-0.9-0.7-1c-0.3,0.3,1.9-0.6,1.6-0.5C436.3,419,436.6,417.6,436,416.7z"
            />
            <path
              id="ukSvg-southEast"
              className="st0"
              fill={this.getColor("southEast")}
              d="M454.4,443.9c0,0.1,0,0.3,0,0.4c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.1,0,0.2,0.1,0.3
                c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.2-0.1,0.4-0.2,0.6l-0.2,0.4l-0.2,0.4l-0.4,0.3c-0.9,0-1.6,0-2.3,1.3c7.4,7-2,12.2-7.4,14.6
                c-2.2,1-4.4,1-5.8,3.4c-0.6,1.1-0.2,5.5-0.4,5.7c-1.5,1.6-6.5-0.3-8.7,0.6c-3,1.2-4.6,3.6-8.1,4.7c-3.8,1.2-5.6,2.6-8.6,4.6
                c-4.6,3-4.1,2-9.5,0.2c-2.3-0.7-6-1.8-8.7-2.2c-1.9-0.3-23,3.9-22.7,4.3c0,0,0-0.8-0.4-0.8c-0.2,0-0.5,0.7-0.8,0.8
                c0.3-0.1,1.1,0.2,1.1,0.2c1.4,0.9-2,1.2-2.5,1c-1.2-0.4-2.6-1.9-3.6-2.6c0.2-0.2,3.7-2.9,3.7-2.9c-0.3,0.1-0.4,2.2-1.5,1.5
                c-0.9-0.6,0.1-0.7,0.1-1.3c0,0-0.5-0.4-0.6-0.4c0.2,0-0.1,1.1-0.2,1.2c-0.3,0.6-0.9-1.7-1-1.7c-0.7-0.1-0.3,1.8-0.3,2.2
                c-1.6,0.3-1-1.6-1.1-2.3c-1.2,0.7-2.4,0.5-3.3,1c-0.7,0.4,0,1.7-0.1,1.8c0.1,0.7-4.3,1.9-2.4-0.8c0.1-0.6-0.3-1.2-0.5-2
                c-0.6,0-2.2,0-3.1,0.1c-0.1,0.2,4,3.4,1.7,4.1c-1.3,0.4-8.5-5-6.9-6c-0.1,0.1-0.2,1.4-0.1,1.5c0.1,0.2-4.5-4.2-2.7-3.9
                c-0.3,0-0.5,1.9-0.5,2c-1-0.9-2.2-1.3-3.2-2.1c0.8,0,6.5,5.8,6.5,6c0,3.8-4,0.9-4.1,0.9c-0.2,0,0.7,1.7,0.8,1.9
                c-3,2.2-4.5,1.3-6.9,3.6c-0.5,0.5,1.2,0.2,1,0.6c0.2-0.3-5-1.8-5.6-1.8c-0.4-0.3,0.8-1.8-2.1-0.7c0.1-0.8,0.2-1.3-0.2-1.9
                c-0.5,0.3-0.8,0.7-1.3,1c-0.4-1-0.7-6.1-1.1-6.1c-2.5,0.2-1.2-1.9,0-3.7c-1.3,0.2-6.2-1.3-5.6-3.9c0.8-0.4,3.1-1.4,3.3,0.3
                c0.5-0.7,0.6-1.3,1.5-1.5c0.1,0.3,5.3,3.7,6.2,3.7c2.6-0.1,1.7-1.3,2.1-2.3c0.4-0.9,0.8-7.3-1.8-8.5c0.5-2.6-0.9-4.1-0.2-5.4
                c0.2-0.3,2.8-2,3.1-2.2c2.3-1.6,0.3,0.1,1.6-1.7c1.1-1.5-0.1-1.5,0.7-3.5c0.5,0,0.7,0.6,1.1,0.6c0.5-3.1-2.1-3.1-2.9-5.3
                c0,0.1,1.9-0.7,1.7-2.2c-0.2-1.4-2.2-2.1-2.2-4.4c-2.4-1.7-2.9-2.9-3.7-5.4c-0.3-1.1-1.4-4.6,1.2-4.3c-1.9-1.5-1.2-3.6-2.7-5.9
                c2.3-1.8,2.6-7.3,3.9-9.4c-1.5-1.3-1-1.6-1.8-2.9c1,0.4,1.2,1.2,2,1.9c0,0,0.9-1.7,0.4-1.3c3.7,0.7,3.3-5.4,4.5-7.6
                c-0.1,0.1,1.1,0.3,1.1,0.3c0.1-1.9,2.3-0.8,3.3-0.3c-0.6-0.6-0.7-0.5-1.3-1c0.3,0.3,2.1-3,2.3-3.2c0.9,1.5,2,2.9,3.1,4.4
                c-0.9,0-1.8,0.5-2.7,0.5c0,0.1,0.1,0.3,0.1,0.4c0.7,0.6,2,4.1,1.5,6.1c5,0.4,6.9-3.9,9-5.8c3.3-0.5,3.9-0.3,5,3
                c0-0.1,1.7-1.8,1.7-1.9c0.7-1.4-0.5-2-0.3-2.8c0.4-1.4,0.9-2.5,2.8-3.1c4.8-4.7,10.1,0.7,4.7,6.2c2.2,2.4-0.7,5.1-0.3,6.8
                c0.6,3.3,3.6,3.1,5.6,6.6c-0.4,0.3-0.9,0.8-1.6,1.3c-3.5,0.1-6.7-5.4-4.5-0.4c2.1,4.8,6.1,3.1,5.9,8.2c0.8,0,0.8,0.2,1.5,0.3
                c-2.9,1.4,0.5,3.7,1,6.4c0.2,0.9-0.9,3.7-0.6,5.1c0.9,4.3,9.1,3,7,9.4c0.8-1.4,1.9-2.1,2.9-3.2c0.3,1.4,0.9,1.1,0.5,2.7
                c1.8-0.7,3,0.5,3.3,2.2c2,0.7,5.4-5.4,6.3,0.5c-0.1-0.4,0.1-0.7,0.5-0.9c0.4,0.5,0.6,0.6,0.8,0.8c2.2-1,2.4-3.7,3.5-6.4
                c-0.1,0.2,2.4-5.4,2.4-5.5c5.2,3.5,7.5,0.3,12.2-0.8c0.9-0.2,4.6,0.1,5.3,0.6c2.9,1.7,0.9,2.7-1.1,1.8c0.1-0.3,0.7,0.7,0.5,0.5
                c-0.6,0-0.4-0.4-1-0.3c0.9,0-1.2,1-0.9,1.4c-0.5-0.7,1.4,0.4,1.2,0.3c-2.3-0.2-3.7,0.3-4.5,2c-0.8-0.9-0.9,0-1.7,0.7
                c1.2-0.7,1.8-0.2,2.3-1.9c0,0,3.1,1.7,3,1.6c0.3-0.6,0-0.3,0.7-0.6c-0.1,0.3-0.4,0.5-0.4,0.8c0.1-0.1,0.6-1.3,0.7-1.4
                c0.7,0,0.6,1,1,1.2c0.2-0.2,0.6-1.5,1.1-1.1c-0.1,0.2-0.2,0.5-0.2,0.7c0.4,0,0.6-0.2,0.8-0.5c-0.4-1.1,0-3.2,0.5-1.7
                c0,0.4,0.7,2,1,3.3c7.7,0.2,16.6-1.2,25.4-3.1c0.1,0.1,0.2,0.2,0.3,0.2l0.3,0.1v0.1c0.1,0.2,0.3,0.3,0.3,0.5L454.4,443.9z"
            />
            <path
              id="ukSvg-southWest"
              className="st0"
              fill={this.getColor("southWest")}
              d="M285.2,450.9c-0.1-1.1,0.4-2.1-0.2-3.2c0.5-1.1,1.2,0.2,0.8-1.8c0-0.2,8.7-6.6,10.1-6.3
                c0.7-2.5,4.3-8.1,7.2-11.3c0.5-0.5,4.5-3.9,4.6-4.2c0.2-0.6-0.9-4-0.7-3.9c1.8,0.9,3,2.5,2.3-0.3c0.2,0.7-0.1,0.8,0.6,1.1
                c-0.5,1.8-2.6-1.7-3.4-0.7c-1.5,2,1.3,1.5,1.2,2.5c-4.5,2.6-6.5,6.7-9.4,9.9c0.1-0.1-1.2-3.7-1.3-3.5c0.3-0.2,0.6-0.3,0.9-0.2
                c0.2-0.2-0.9-6-0.4-7.5c1.2-3.3,2.7-2.9,5-4.1c2.5-1.2,5.1-3.7,1.4-5.9c0.1-0.6,0.3-1.9,1.2-2.2c-0.2-0.3-1.2-2,0.3-1.8
                c1.6,1.4,1.8,2.4,4.2,0.3c0.9,3.9,6.8,3.4,7.1,1.4c0-0.1-1.4-2.5-0.6-2.6c0.4-0.1,2.4-1,2.5,0.3c-0.7,0.7-1,0.6-1.3,1.8
                c0.3,0.1,0.5,0.2,0.4,0.4c3.2-1.3,8.3-3.8,11.2-0.5c0.4-1.8,0.1-2.7-1.3-4c0.9,0,2.1-0.4,2.4-0.5c-0.5,0.1,1.7-2.2,1.6-2.1
                c0.7,1.1,1.1,0.5,1.5,0.8c0.7,1.8-0.4,1.3,1.1,1.6c0,1.9,0.9,2.7,2.9,2c0,0.2,0,0.4,0.2,0.6c-2.6,1.8-2,4.1,0.1,5.5
                c-1.3,2.1-1.7,7.7-3.9,9.4c1.4,2.3,0.8,4.3,2.7,6c-4.1-0.5,0.2,8.2,2.4,9.7c-0.1,2.4,2,2.9,2.3,4.4c0.4,2.1-1.7,1.6-1.7,2.2
                c0.3,2.4,3.4,2.2,2.9,5.5c-0.5,0-0.5-0.6-1.1-0.6c-0.7,2,0.4,2-0.6,3.5c-0.1,0.2,0.6,1.4,0.5,1.8c-0.6,1-1.3-0.6-2.1,0
                c-3.1,2.3-4,2.8-3,7.7c2.7,1.1,2.3,7.8,1.9,8.7c-0.4,0.9,0.4,2.2-2.1,2.2c-0.8,0-6.1-3.4-6.2-3.7c-0.9,0.2-1,0.9-1.5,1.5
                c-0.1-1.7-2.5-0.7-3.3-0.3c-0.6,2.6,4.4,3.9,5.6,3.8c-1.2,1.9-2.5,3.9,0,3.7c0.4,0,0.6,5.1,1.1,6.1c0.5-0.2,0.9-0.7,1.3-1
                c0.4,0.6,0.4,1.1,0.2,1.9c0.8-0.3,1.6-0.4,2.4-0.2c-1.6,1.4-2.4,0.8-3.9,1.4c0.1,0,1.8,0.5,1.7,0.5c-0.1,0-9,1.2-8.1,2.3
                c0.1,0.1-1.3-3.1-2.2-3.1c-0.6,0,0.6,1.8,0.7,1.6c-1.2,1-1.7-1.1-1.8-1.5c0-0.2-0.7,1.2-0.2,1.2c-0.9,0.5-0.5,0.1-0.6,0.5
                c-0.2,0.4-0.6,0.7-0.9,1.2c0.1-0.1,2.8-1.6,2.5-0.8c-0.1,0.3-0.6,0.8-1.5,1.5c0.3,0.1,1-0.1,1.2-0.4c-0.1,0.2-0.7,0.8-0.7,0.8
                c0,0.1,0.8,0.1,0.7,0.1l0.1-0.7c0.3,0.1,1.8,0.5,1.6,0.3c0,0,0.1,1,0.2,1c0.1-0.1,0.2-0.1,0.3,0c0.2-0.4,0.3-1,0.8-1.1
                c0,0.2,0.1,0.3,0.3,0.3c-1.6,0.2,0.8,2.5,0.6,2c1,1.2-2.8,3.6-4.4,3.7c-0.9,0.1-3.7-1.8-5-2.2c-2-0.6-11.3-2-10.4,0.5
                c0-0.1,0.1,6.5-0.6,5.8c-0.5-0.5-1-4.1-2.2-5.5c-1.5-1.8-4.2-3.5-6.2-4.7c-4.5-2.6-6.9-2.9-11.2-2.1c-4.7,0.9-8.3,1.9-12.5,3.9
                c-5.7,2.8-3.6,1.1-6.9-2.4c0,1.8,1.1,4,1.6,4.3c2.1,1.1-6.2,4.2-6.3,4.2c2.7,0.6,2-0.7,3,1.3c-0.1-0.2,0.4,4.7,0.9,3.7
                c-0.9,0.8-3.1-0.9-2.9,1.5c0.1,1.7,2.8,2.2,2.8,2.2c0,7.1-4.5,0.2-6.2-0.9c0.3,0-1.4,0.8-1.1,0.7c0.5,0.3,2.5,0.3,2.4,0.3
                c2.7,3.8-1.8,5.9-1.8,10.2c0,1.4-5.7,0.7-5.1-0.3c0.3-0.4,1.2-0.7,1.3-0.8c-0.1-0.8,0-0.1,0.3-1.1c0.1-0.4-1.1,0-0.8,0.2
                c0,0-0.4-1.1-0.6-1.3c-0.2,1.3,0.2,0.5-0.6,1.2c0.6,0.5,1.9,1.9,0,2.9c-2.1,1.1-4.6-4.6-2.9-5.1c-1.7,0.5-3.3,0.2-2.9-1.9
                c-2,1.3-3.2,1.1-5.4,0.9c0,0,1.5-0.9,0.9-0.9c-3.3,0-1.2-1.7-3.2-2.5c0.7,0.2,0.6,0.1,1-0.3c-2.7,1-4.4-2-2.4-3.5
                c-0.8-0.2,0.3-1.6,0.7-2.1c-0.3,0.3-0.8,1.4-1.3,1.4c0,0-1.3-1.5-1.7-1.5c-0.7-0.1,0.6-1,0.6-1.1c0,0.3-1.4,0.4-0.8,1.1
                c0.6,0.7,0.5-0.5,0.8-0.4c0,0.3,0.3,2,0.4,2.1c0.1,0.1-1.5-0.2-1.1-0.5c2-1.7-2.2,7.2-2.9,2.9c0.1,1,3.9,0.5,4.3,1
                c-0.4,0.2-1.3,0.2-1.7,0.5c1,0.2,1.1,0.6,2.5,0.6c-0.4,1-0.5,1.5-0.8,2.4c-0.5,0-0.9,0.3-1.3,0.4c-1.1-9-14,2.2-16.2-1.6
                c0.1,0.2,0.5-3.1,0.2-3.2c0.5,0.1-1.6-0.8-1.1-1c1.4-0.3,0.4,4.3-0.7,4.9c0-0.4-0.4-2-1.1-2.1c-0.5-0.1-3.8,1.2-2.2,2.8
                c-1.1-1.1-1.2,5.3-2.2,4.8c-2.8-1.3-8.6,4.1-8.3,4.3c-0.4-0.3,1.5-1.9,1.1-2.3c-0.2,0.2-0.3,0.1-0.3-0.1c-0.3,0.2-0.3,1-0.4,1.4
                c-0.3-0.5-0.4,0.2-0.7,0s0.4-1.8,0.4-2.1c-0.1,0.2-0.2,0.4-0.5,0.5c-0.4-0.1,0.1-1.8-0.3-1.8c0,0-0.9,1.1-1,1
                c-0.5-0.5,0.1-0.9-1-0.9c0.2,0.3,1.3,1.1,1.1,1.6c-0.3,0-0.6,0-0.9-0.3c0,0.1,1.2,0.9,1.2,0.9c-0.7,0.1-1.1,0.2-2,0.1
                c0.9,0.4,1.5,1.2,2.1,1.5c-1.3,1.6-2.1,3.3-3.5,2c-0.9,0.4-0.2,1.4-0.9,0.1c-0.3,1.1-0.2,0.6-0.7,1.3c0.1,0,3.6-0.7,3.3,0.1
                c0,0-0.9,0.1-0.7,0.3c0.3,0,1.1-0.2,1.3-0.1c2.7,1.7-3.3,6.7-4.6,6.8c-4.2,0.3-2.2-9.1-9.2-8.1c-3.2-6.2-8.5,8.8-11.3,1.1
                c1.7-0.5,0.5-3.2,1.2-4.2c1-1.5,3.1-2.4,4-2.8c4.8-2.1,2.7-0.7,6,1.2c-1.2-0.7,6.8-5.9,8.1-7.1c0.4-0.4,2.7-1.9,3.2-2.6
                c1-1.5-0.3-2.2,0.5-3.4c0,0,2.6,0.2,2.6,0.1c0.1-0.2-1.8-0.4-1.8-0.5c0.2,0.8,1.5-1,1.2-0.8c2.4-1.2,3.4-4.3,2.7-7.3
                c0.1-0.1,0.2-0.2,0.3-0.3c0.4,0,3.6-0.8,3.1-0.9c-0.1,1.8,1.4,2.9,3.9,2.8c-1.6,0.5-3.8-2.6-3.2-3.8c0.1-0.1,0.7-0.5,0.6-0.5
                c4.3,0,5.8-2.3,8.5-5.3c1-1.2,4.7-3.5,5.7-5.5c1.2-2.3,0.4-6.5,0.6-8.7c0-0.4,0.7-5.8,1.1-6c1.1-0.5,3,0.8,4.3,0.9
                c1.1,0.1,1.5,1.6,3.2,1.3c1.6-0.3,3.5-4.5,4.3-4.4c0.6,0.1,1.4,1.5,1.1,1.7c0.9-0.6,0.5-2,1.5-2.5c1.3-0.8,2.3,0.6,3.4,0.1
                c-1.7-1.1-3.8-1.1-4.4,0.6c-0.1,0.2-2.4-5.2-2.7-4.8c0.1-0.1,0.2-0.1,0.2-0.3c2.6,1.4,1.2-1.9,1-2.4c4.4-1.9,9.2-1.7,13.7-2
                c3.4-0.3,6,0.5,10,1c1.8,0.2,3.2-0.3,5.4,0.3c2.1,0.6,3.4,2.1,6.3,2.2c2.4,0.1,9.5-1.1,11.2-2.3c-0.8,1.6-0.8,2-1.1,3.3
                c0.3-0.8,1.4-2.6,1.6-3.8c0.2-1.1-1.4-5.8-1.3-6.2C283.9,448.5,285.3,451.1,285.2,450.9z"
            />
            <g id="ukSvg-wales">
              <path
                id="ukSvg-wales1"
                className="st0"
                fill={this.getColor("wales")}
                d="M283.4,332.2c3.6,0.3,8.2,5.4,3.2,6.1c1.2,2,3.2,1.4,4.1,2.1c0.1,0.1,2.2,7,2.9,8.1
                  c0.7,1,2.2-0.5,2.6,1.5c0.4,2.1-1.1,3.4-2.8,4.4c-1-4.9-4.4-1.6-6.3-4.7c-1.6,2.3-4.7,1.7-5.2,4.4c-1,0.4-1.8,1.7-1.6,3.4
                  c0.5,3.8-0.4,0.7,2.4,2.6c1.4,1,3.2,3.1,4.6,4.9c-2.7-2-2.8,2.9-4.6,4.3c0.3,0.2,0.5,0.4,0.8,0.6c-2.3,1.9-2.9,4-2.8,6.3
                  c0.9-0.3,3.4-3.3,4.4-2.9c1.9,0.8-0.5,4.4-0.6,4.5c-12.4-1.2-5.1,11.1,2.6,9.3c-1.7,1.7-3.6,4.4,0,4.8c-4.1,0.7-4.4,3.5-6.5,6.4
                  c0.8,0.1,1.3,0.4,1.9,0.4c-0.9,0.3-2,1.4-2.8,1.7c1.6,0.9,0.9,3.8,1.7,6.1c1,2.9,3.4,5.5,4.4,7.7c1.8-1.3,3.3-1.7,4.8-0.9
                  c-0.3-0.5,2.1,3.1,2.6,3.6c1.3,1,3.5,1.1,3.7,1.5c0.2,0.3,0.6,10,0.5,10.1c-0.3,0-0.6,0.1-0.9,0.2c-0.6,2.6,2.1,2.8-3.1,5.7
                  c-1.9,1.1-6.1,3.8-8.1,1.6c0,0,0.6-0.8-0.1-1.5c1.4,2.3-5.4,4.5-6.4,7.1c-0.1-0.2-0.1-0.8,0-0.8c-0.3,0.5-0.7,0.1-0.8,0.1
                  c-0.4,0.3,0.9,2.7-0.6,3.8c-0.9,0.7-3.9,0.7-4.8,0.7c-4.4,0.1-7.1-0.7-10.2-2.8c-4.8-3.2-5.6-5.4-9.7-11.2
                  c0.3-0.4,0.7-0.4,0.8-0.9c-2.3,1.2-7.2,0.3-5.6,3.5c-1.5,0.2-2.9,0-4.2,0.2c-2.1,0.4-1.3,0.6-2.6,1.2c-0.6,0.3-5.2-0.3-5.7-1.2
                  c-0.6-1,1-4.6,2.5-5.2c0,0.2,0.2,0.3,0.4,0.3c0,0.1-0.4,0.8-0.4,0.7c1,0.3,1-0.3,0.9-0.3c0.2,0,5.8-0.5,5.5-0.8
                  c-0.2-1.3,0.5-1.6,0.8-2.8c-1.6,5.4-4.9,0-8.5,1.2c0.3-0.1,0.5,0,0.6,0.3c-1.5,0.6-4.4-2.1-4.2-3.7c0,0.2,2.5,0.4,2.7,0.5
                  c-3.6-0.9-0.6-3-0.6-4.5c0,2-4.2,2.8-4.9,0.5c0.3,0.5-0.6,2.5,1.3,2.5c-1.2,0-6.4-0.2-8.1,0.5c-2.8,1.2-2.2,2.6-3,4.8
                  c-2,0.7-6.9-0.6-7.2,2.3c-1.1,0.7-4.7,0.5-6.7-1c1.5,1.1-1.9-5.1-2.1-3.2c0.1-2.1,1.4-0.4,1.5-0.5c1.4-0.4,3.5-0.5,5.8,0.1
                  c-0.1,0-1.9,0-1.8-0.4c0.3-1.2,4.2-1.2,4.9-0.6c0.1-0.4-0.1-0.8-0.5-1.1c0.3,0,1,0,1.1-0.4c-0.5,0.1-1.5,0.3-1.9,0.3
                  c-0.8,0,0.1-3.1,0.8-3.9c-0.4,0.5-2.4,1.1-2.8,1.1c4.5,0.6-2,6.1-6.5,2.2c0,0.1-0.2,0.7-0.3,0.8c-0.4,0.1-1.5-0.6-2-0.7
                  c0.1,0.4,0.9,2.4-0.3,2.4c-0.1,0-1.8-3-2.9-3.4c0.1-0.1,0.1-0.2,0-0.3c2-1.4,5.9-0.8,5.8-4.4c0-6-7.1-1.4-7.9-3.2
                  c-0.5-1.1,1-1.4,0.3-2.4c0.9,1.3,9.7-6.1,8.5-5.2c1.9-1.5,0.4-1.6,3.8-1.8c-1,0.1,3.4,3.4,3.2-0.4c0,1,2.5,0.6,3,0.8
                  c-0.1-0.2,3-5.6,3.9-5.6c0.6,0,1.9,1.2,1.8,1.9c-1.7-4.4,4.7-2.4,7.1-3.4c1.9-0.8,5.6-3.4,7-4.3c3.2-2,13.9-14.7,10.2-18.9
                  c0.7,0.8,4.3-0.4,4.1-1.4c-0.5,3.3-6-1.1-6.6-3c0.1,0.1,1.1,0.6,1.2,0.2c0.1-0.5-1.2-1.5-0.2-3.7c0.3-0.6,2.6-2.5,2-3.2
                  c0-0.1-0.5,0.8,0,0.7c1.4-0.6,2.5-1.9,4.1-2.6c-1.2,0-3.7,1.8-4.5,1.5c-1.8-0.7-3-5.5-2.5-6.4c-0.2,0.1-0.2,0.3-0.2,0.5l0.7-0.6
                  c0-0.8-0.7-3.9-0.5-4.3c-0.2,0.6,3-1.5,3.3-1.8c-0.7,0.2-1.9,0.8-2.5,1c0.4,0.1,0-0.9-0.5-1c-1.6,1.8-8.3,1.4-10.1,2.4
                  c-3.9,2.3-2.5,4.9-5.2,6.2c-0.7-3.9-6.3-2.1-7.4-0.5c-0.3,0.4,0.6-3.8,1.7-5.1c0.5-0.6,3.6-4.9,4.8-4.9c4.8,0,7.8-5.1,9.1-10.3
                  c0,0.2,0.1,0.3,0.4,0.1c-0.1,0.2-1.2,1.1-0.1,1.4c-1.5-0.7,7.7-7.7,8.1-7.9c2-1,13.5-5.6,10.6,0.3c-0.3,0,0.7-2.4,0.8-2.7
                  c-0.4-0.3-3.2-3.3-2.9-3.9c0.7-0.4,3.9,1.2,4.7,1.5c3.2,1.1,5.3,2,8.7,0.4c7.4-5.1,9.7,1.4,15.8,5.4l-0.5-0.6c0.3,0,0.6,0,0.9,0
                  C282.7,332.5,283.3,332,283.4,332.2z"
              />
              <path
                id="ukSvg-wales2"
                className="st0"
                fill={this.getColor("wales")}
                d="M248.5,327.8c1.5,2.1-11.3,9.7-11.3,9.6c0.2-0.2-0.3,1.2-0.4,1c-0.5-0.9,1,0.7,0.8,0.4
                  c-1.2-0.2-2.2-0.7-3-0.8c-0.6,0,1.1-3.2,1-3.2c-0.7,0.3-1.5,1.6-2.1,2.1c-1-1.1-3.4-7.6-4.9-7.8c0.2-0.1,2.2-1.6,2.2-1.6
                  c0-0.2-1.2,0.4-1.4,0.5c-0.1-0.7-0.4-4.9,0-5.6c1-1.7,3.4-0.3,4.3-1.1c1.1-0.9,2.2-0.8,3.9,0c1.9,1,1.6,3,1.9,3.2
                  c0.1,0,1.6-0.2,2.1,0.6c0.2,0.4,0.6,3.5,0.7,3.5C244,328,247,327.5,248.5,327.8z"
              />
            </g>
            <path
              id="ukSvg-westMidlands"
              className="st0"
              fill={this.getColor("westMidlands")}
              d="M309.1,348.1c-0.9-3.2,5.4-7.5,8.6-9.5c-0.1-0.6-0.1-1.1,0-1.7
                c0.4,0.6,1.5,1.3,2.1,0.7c1.7-0.8,2.9-1.6,4.4-3.3c3.9,3.9,8.3,7.7,7.3,13.3c-0.2,1.3-3.5,3.1-3.4,5c0.2,2.1,2,2.5,3.8,3.2
                c1.3,0.5,4.1,0.2,6,2.2c0,0.2-0.7,0.5-0.8,0.9c1.7,2.9-2.6,0.9-3.1,4c-0.3,1.9,2.4,2.7,3.2,3.3c4,3.3,1,5.6,7.6,10
                c3,2,7,4.1,8,8.2c1.3,5.9-1.4-0.2-2.3,4.2c0.2,0.1,2.1,0.6,2.1,0.8c-0.1,2.4-0.6,4-2.7,4.5c0.5,0.6,1.1,1.7,1.2,2.4
                c-1.3,0.1-5.5,4.1-5.2,5.1c-0.2-0.6,1.3,1,1.3,1c-1.1-0.5-3.2-1.5-3.3,0.3c-0.3,0-0.9-0.3-1.1-0.3c-1.1,2.2-0.6,8.3-4.4,7.6
                c0.1-0.1-0.2,1-0.4,1.3c-2.3-1.3-2.6-3-0.3-4.4c-0.1-0.2-0.2-0.4-0.2-0.6c-2,0.6-2.8-0.2-2.8-2c-1.5-0.3-0.4,0.2-1.1-1.6
                c-0.3-0.2-0.7,0.4-1.5-0.8c0.1-0.1-2.1,2.1-1.6,2.1c-0.3,0-1.5,0.5-2.3,0.5c1.4,1.2,1.6,2.1,1.3,4c-2.7-3-8.3-1-11.2,0.4
                c0.3-0.7,0-1.3,0.9-2.2c-0.1-1.3-2.1-0.4-2.5-0.3c-0.8,0.2,0.6,2.4,0.6,2.6c0,1.4-6.3,3.1-7.3-1.3c-1.8,1.6-3.4,1.7-4.2-0.3
                c-1.5-0.2-0.5,1.5-0.3,1.8c-0.8,0.2-1,1.5-1.2,2c5.7,3.5-3,6.6-5.7,8.2c-2.3-1.7-5.5-3.5-7.1-5.9c-1.5-0.8-3-0.4-4.8,0.9
                c-1.1-2-3.4-4.6-4.5-7.5c-0.9-2.4,0.2-5.3-1.6-6.3c0.7-0.3,1.8-1.4,2.8-1.7c-0.7,0-1-0.2-1.9-0.4c2.1-2.8,2.3-5.8,6.5-6.4
                c-0.8,0-1.5-0.3-2.2-0.5c0.3-1.9,0.9-3,2.3-4.3c-7.7,1.8-15.1-10.5-2.7-9.3c0.1-0.1,2.5-3.7,0.6-4.5c-1-0.4-3.5,2.5-4.4,2.9
                c-0.2-2.2,0.5-4.4,2.8-6.3c-0.3-0.2-0.5-0.4-0.8-0.6c1.8-1.5,1.7-6.2,4.5-4.3c-1.4-1.9-3.1-3.8-4.5-4.9c-2.6-2.1-1.9,1.4-2.4-2.6
                c-0.2-1.7,0.5-3,1.6-3.4c0.5-2.8,3.5-2,5.2-4.4c1.9,2.9,5.3-0.1,6.4,4.6c4.4-2.5,1.1-6.2,6.4-5.9c1.2,0.1,1.4,1.9,3.4,2.5
                c0.5-0.6,0.1-1.1,0.3-1.7C306,351,307.8,348.5,309.1,348.1z"
            />
            <path
              id="ukSvg-eastMidlands"
              className="st1"
              fill={this.getColor("eastMidlands")}
              d="M337.7,329c2.2,0.2,6.2-1.1,9.2-0.4c1.4,0.3,4.9,1.7,7.4-1.9
                c-0.3-0.3-0.3,0.1-0.7-0.7c2.4-3,4.3-4.6,7.9-8.9c0.3,1.5,0.9,1.4,0.8,2.6c0.9-0.2,3.6,0.6,4.3,0.3c0.9-0.4,0.4-2.5,1.2-2.8
                c1.7-0.6,2.5-1.5,5-0.9c-2,4.1,2.7,3.5,5.6,2.2c-1.5-3.5,2.5-0.9,2.2-3.2c-0.1-1.1-3.2-1.3-3.3-0.6c0.5-0.7,1.5-1.5,2.4-2.2
                c-0.1,1.6,4.9,1,4.4-2.5c0.1,0,0.2,0,0.2-0.1c1.9,1,2.2,2.3,4.1,2.5c-0.9,2.8,0.7,9.9,4.1,7.2c-0.5-0.8-0.9-3.3,0.5-4.4
                c1.5-1.1,6.8,1.8,6.8,1.7c-0.1-0.3-0.1-0.5-0.2-0.8c0.2-0.4,1.4,1.6,1,1.4c0.7,0.4,0.2-0.8,0.4-0.8c0,0.1,1,1,0.8,1.1
                c-0.2-0.1-0.3-0.2-0.5-0.4c1.7,2.2,1.7,3,2.8,5.4c1.8,3.8,4.8,8.4,4.9,12.9c0.2,5.9-3.7,6.3-7,10.2c-2.1,2.5-2.7,5.3-5,7.3
                c3-1.6,6.7,1.1,7.5,4.7c0.6-0.1,1.4,0.1,2,0c0.1,0.4,0.3,2.2,0.3,2.4c-2.6,2.4-5.4,1.5-7.6,3c-1.3,0.9,0.2,2.6-0.9,3.3
                c-1.8,1.1-2.7,0.1-4.1,0.3c-3.8,0.5-5.2-0.2-8.8-0.1c-2,0.1-4.8,2.3-6.7,2.2c1.9,5.8,8.2,7.1,2.7,15.2c-0.9,1.3-1.2,0.7-2,2
                c-0.2,0.4,1.4,1.2,1.1,2.1c-0.1,0.3-1.8,0.6-2.3,1.2c-0.6,0.8,0.1,2.6-0.5,2.8c-0.6,0.3-2-0.5-1.7-0.9c0.4-0.4-1.6-0.1-1.9,0.1
                c-1,0.8,0.5,2.1-0.5,3.4c-1.4,1.8-4.7,3.5-5.5,3.4c-1.6,2.4-1.8,2.3-2.3,3.9c-0.2,0.8,1.2,1.8,0.3,3c-0.5,0.7-1.6,0.9-1.9,1.6
                c-1.2-3.4-1.8-3.4-5.1-3c0.4,0,0.5,0.2,0.5,0.5c-0.8,0-5.6,2-3.4,2.8c-1.2,2.1-3.9,2.7-6.2,2.4c0.5-2-0.9-5.5-1.5-6.1
                c0-0.1,0-0.3-0.1-0.4c0.9-0.1,1.9-0.6,2.9-0.7c-1.8-2.2-5-6-0.2-6.2c-0.1-0.8-0.7-1.9-1.2-2.4c2.2-0.6,2.6-2.2,2.7-4.5
                c0-0.1-1.9-0.7-2.1-0.8c0.5-2.2,3.2-0.5,3.2-2.5c0-2.4-3.6-6.3-5.4-7.9c-3.6-3.3-7.4-3.8-9-7.5c-0.7-1.5,0.7-2.8-1.3-4.8
                c-0.6-0.6-3.7-0.5-3.8-2.3c-0.2-4.1,5-1.7,3.2-4.8c0.1-0.4,0.9-0.7,0.8-0.9c-3.1-3.2-8.2-1-9.4-4.7c-0.6-1.8,1.6-3.1,2.2-4.8
                c0.6-1.8,0.7-4.5,0-6.7c-1.4-4.4-3.7-4.8-6.6-7.8c-0.2,0.2-0.6,0.7-0.9,0.8c-0.2-2.3-0.2-8.1-1.7-9.8c0.9,0.5,1.2-1.9,1.6-2.4
                c-2.5-1.3,2.1-7.9,4.9-7c4,1.2,4.2,9.5,9.6,11.1c-0.9,0.5-0.7,0.9-1.5,1.6C336.9,328.3,337.1,328.4,337.7,329z"
            />
            <path
              id="ukSvg-yorkshireAndHumber"
              className="st2"
              fill={this.getColor("yorkshireAndHumber")}
              d="M346.3,258.6c3.8-2,3-2.1,7.8-2.2c2.8-0.1,6.8,0.3,9.9,0.4
                c0.5-1.3-1.2-2.8,1.1-3.8c0.9-0.4,3.5,1.2,3.5,1c2.5,3,4.9,4,7.1,7.2c1.7,2.4,3.6,5.1,4.9,7.5c0.9,2.3,1.4,3.4,4.4,4.3
                c-2.2,4.4,7.5,4.3,6.9,6.2c-0.5,1.4-3.1,0.3-3.8,1.9c-1.3,2.9-0.8,5.2,0.2,8.2c0.9,2.7,2.3,4.9,3.8,7.2c2.9,4.3,7.7,8.1,8.7,13.3
                c-2.9-4-4.4-0.4-7.3-1.1c-3.6-0.9-4.2-4.6-7.1-5.9c-2.8-1.2-4.5,0.6-7.5,1c-3.3,0.4-9.6-1.6-12.2,1c3.1-3.1,3.4,2.9,3.5,3
                c-2.3-1,2.4-3.6,3.1-3.8c-1,0.3,5.2,1.5,4.2,1.5c0.7,0,2.5-0.8,3.5-0.9c4.1-0.2,2.9-0.5,6.2,2.6c1.5,1.3,2.8,2.8,4.4,4.1
                c2.8,2.3,3.9,1.8,3.1,4.2c-0.3,1-3.8,1.3-2,4c-3.1,3-5-4.3-4-7.1c-1.9-0.1-2.3-1.5-4.2-2.5c0,0.1,0,0.2-0.2,0.1
                c0.5,3.5-4.5,3.9-4.4,2.4c-0.9,0.7-1.9,1.5-2.4,2.2c0-0.4,3.1-0.6,3.3,0.6c0.2,2-3.7-0.4-2.2,3c-2.7,1.2-7.7,2-5.6-2.2
                c-2.6-0.5-3.4,0.3-5.1,0.9c-0.8,0.3-0.4,2.4-1.2,2.8c-0.7,0.3-3.4-0.5-4.3-0.3c0.3-1.5-0.4-0.7-0.8-2.6c-3.6,4.3-5.7,6-8.2,9
                c0.5,0.7,0.4,0.5,0.8,0.9c-1.5,2.2-2.5,2.3-4.6,2.7c0.4-0.1-3.3-0.9-2.7-0.7c-2.5-0.7-1.9-0.7-4.4-0.6c-2,0-3.4,2.4-4.5,0.3
                c-0.8,0.2-0.8,0-1.7,0.1c0.6-0.5,0.9-1.1,1.5-1.6c-4.7-1.4-5.7-7.6-8.3-10.1c-2.8-2.6-8.4-5-8.5-10.4c-1.5,0.7-2.4,0.4-3.3,0.2
                c-4.1-0.8,3.6-9.1,3.6-9.2c0.5-4.1-5.1-5.3-5.2-8.5c0.1,0.1-1,0.6-1,0.6c-0.2-0.6-0.2-0.5,0.2-1.1c-2.5,0.4-5-0.5-4.8-2
                c-1.2-3.2-7.8-2.8-8-7.8c-0.2-5.3,15.4-7.2,7.2-13.2c3.8,0,1.2-3.3,2.6-4.7c1.2-1.2,4.2-1.7,6.3-1.8c3.2-0.2,7.9,1.5,9.8-2.9
                c-0.1,0,2.1,1.7,1.6,1.4c0.1-1.2,0.6-1.7,0.5-2.9c1-0.2,1.6-0.6,2-0.7c1.3,0.5,3.2,0.5,2.9,1.5c0-0.1,1.9,0.3,2.1,0.3
                c0.2,1.2,0.8,1.6,2.2,2.7c0-0.1-0.6-0.5-0.3-0.8c0.6-0.3,0.1,0.4,1.1-0.1c0.1,0,1,1.4,1.3,2.1c0.1,0,0.1-3.2-0.5-3.1
                c1-0.2,1.2,1.2,1.5,1.4c0.2,0,0.5,0.1,0.7,0.2c0.2-0.2,0-1,0.2-1c0.5-0.1,0.3,1,0.8,0c0.2,0.5,0.4,1.1,0.6,1.7
                C345.9,258.4,346.2,258.5,346.3,258.6z"
            />
            <path
              id="ukSvg-northWest"
              className="st3"
              fill={this.getColor("northWest")}
              d="M321.9,325.4c0.7,0.7,2.2,7,1.9,8.3c-0.2,0.7-3.2,4.5-4.1,3.6c-0.7-0.3-1,0.4-1.8-0.7
                c-0.5,5.3-5.7,5.2-8,7.7c-1.4,1.5-2.3,7.8-6.1,5c-0.2,0.6,0.2,1.1-0.3,1.7c-3.1-1.1-2-1.9-5.7-2.5c-2.4-0.4-3.7,0.4-5.7-1.9
                c-0.7-0.8-1-6.1-1.2-6.3c-0.7-0.5-3.2-0.6-4.3-1.9c6.1-0.8-1.5-5.9-3.9-6.9c0.4-0.4,0.5-0.3,0.9-0.5c-0.8-1.2-4.5-5.3-4.2-6.8
                c0.1-0.5,4.9-3.2,5.2-3.1c1.8,0.4,0.9,2.2,1.8,3.5c1.9,2.8,2.2,4.9,5.8,5.3c0.7,0.1,6.9-4.7,7-4.7c-0.6-0.4-5.7,3.2-7.2,2.9
                c-4-0.8-4.9-4.9-6.2-8c-1-2.4-3-4.7-2.5-7.5c0.5-3,3.7-6.7,5-6.7c0.2-0.6,0.9-0.7,1-0.8c0.2-0.4-1.4-0.2-0.5-1.1
                c0.3-0.3,2.5-0.4,3.6-0.2c-0.5-0.7,0.2-0.7,0.8-1.1c-7.9,2.7-12.5-7-6.7-11.3c0.7,0,0,5.2,3.3,4.3c-1.6,0.4-3.3-1.9-2.9-3.5
                c0.5-1.9,3.5-2,4.1-2.6c0.2-0.2,1.3,0,1.6-0.2c-1.7-1.7-0.7-1,0.4-2c0-0.7-0.3-3,0.1-3.2c-0.1,0.9-1.4,2.6-1.3,2.4
                c-0.1,0.1-0.3,1.2-0.5,1.2c-4.3,0,2.7-7.3,3-8.1c-1.6-0.8-2.4-2.6-2.3-4.1c0-0.1,4.6-3.2,1.8-3.2c0.3,0-4,4.2-4.3,5.6
                c-0.5-1.1-1.5,0.8-2.2-1.7c-0.3-0.7-1.3-2.2-2-3.3c0.3,1.4-2.4,9.8-3.6,10.1c-0.7,0.2-1.3-2-1.4-2.5c-2.2,0.5-2.4-2.5-2.5-3.8
                c0.2,1.3,2.7-4.5,2.6-4.5c-0.9,0-0.6-2.1-1.3-2.1c0.1,0-0.3,4-1,2.9c-0.3,1,0.3,3.3-2.5,1.8c-3.8-2.1-1.8-9.1-3.1-10.1
                c-0.7-0.5-0.1,0.8-0.5,0.9c0,0-6.6-8.9-6.7-9.3c-0.3-1.2,1.2-6.7,1.8-8.6c0.9-2.5,3.8-5.8,5.2-8.8c0.9-2.1,1.8-10.3,5.4-5.3
                c0.3-1.3,0-1.3,1-2.4c-0.9,0.9-2.9-0.5-1.4-1.4c2.1-1.3,2.9-0.4,4.4-0.5c1.6-0.2,3.7,0.8,5.4-0.3c-0.3,0.1-2.9-0.4-2.8-0.5
                c0.4-1.1,1.5-1.2,2-1.3c-0.5-0.4,0-4.1-0.1-4.7c3.3,1.1,4.2-1,5.8-2.5c1.8-1.7,4.7-3.1,6.5-5.5c0.2,3.6,4.7,6.7,7,6.6
                c-0.1,1-0.9,1,0,2.4c-0.5-1.1-3.5,3.1-3.7,3.7c-0.3,0.9,0.6,3.5,0.7,4.5c0.1,1-2.2,5.3,1,6.2c1.5,0.4,3.6-2,4.4-2.9
                c0.7,1.2,3.2,2.9,3.5,4c0.3,1.5-1.3,3.5-1.2,4.8c0.1,0.6,1,2,1,2.5c0.1,0.7-0.1,3,0.7,4.7c1.7,3.5,7.5,4,4.1,8.7
                c-0.5,0.7-2.8,0-3.6,0.8c-0.4,0.4-0.5,1.6-0.7,1.9s0.5,3.5-2.2,3.2c8.4,5.5-7,8.2-7,12.8c0,5.2,6.8,4.9,8,8.1
                c-0.3,1.5,1.7,2.2,4.1,1.9c-0.4,0.7-0.3,0.6-0.2,1.3c0,0,1.1-0.5,1-0.6c0.1,3,5.7,4.5,5.2,8.2c0.1-0.9-7.4,10.2-3.7,9.5
                c0.9-0.2,1.7,0.5,3.3-0.2c0.1,4,4.9,6.6,4.7,10.2c-0.1,1.2-5.4,4.9-2.4,6.3C322.6,323.9,321.8,325.6,321.9,325.4z"
            />
            <path
              className="st4"
              d="M229.5,281.1c-1.1,1.7-0.7-1.8-1.9,0.3c-1.7-1.6-3.3-0.8-4.9,0c1.8-2.9,2.5-6.9,4.4-9.9
                c3.1-5,6.6-8.6,11.8-11.3c0.2,1.2,0.2,6.1,0.2,6.7s1.1,0.6,0.9,1.6c-0.2,1.2-2,3-2.8,4.1s-1.7,3.7-2.7,4.5c-1.2,1-4.9,1.9-5.5,3.5
                C229.1,280.6,229.5,280.6,229.5,281.1z"
            />
            <path
              className="st5"
              d="M222.6,281.8c0.5-0.1-1.1-1-1-0.1C221.8,283,222.3,281.9,222.6,281.8z"
            />
            <path
              id="ukSvg-northEast"
              className="st1"
              fill={this.getColor("northEast")}
              d="M365.9,252.5c-2.5,1.1-1.2,2.6-1.9,4.3c-3.3-0.1-7.5-1-10.7-0.6
                c-2.9,0.3-7.8,3.1-8.4,0.6c-0.6,1-0.3-0.1-0.8,0c-0.3,0,0.1,0.9-0.2,1c-0.2-0.1-0.5-0.1-0.7-0.2c-0.3-0.3-0.5-1.6-1.5-1.4
                c0.6,0,0.6,3.1,0.5,3.1c-0.3-0.6-1.4-2-1.3-2.1c-1,0.6-0.5-0.2-1.1,0.1c-0.4,0.3,0.5,0.7,0.3,0.8c-1.4-1.1-1.9-1.6-2.2-2.7
                c-0.2,0-2.1-0.3-2.1-0.3c0-0.9-1.4-1-2.9-1.5c-0.3,0.1-1,0.5-2,0.7c0.1,1.2-0.4,1.6-0.5,2.9c0.4,0.2-1.7-1.4-1.6-1.4
                c-0.8,2.2-2.7,2.9-4.7,3.2c1-0.2-7.4-1-6.9-0.2c1.3-5-5.3-9.5-5.6-13c-0.1-1.5,0.4-3,0.1-4.8c-0.4-3.3-1.1-3.2-3.1-6.4
                c-0.8,0.9-3,3.3-4.4,2.9c-3.2-0.9-0.9-5.2-1-6.2s-0.9-3.6-0.6-4.5c0.1-0.5,3-4.8,3.7-3.7c-0.9-1.3-0.1-1.4,0-2.4
                c-5.5,0.3-7.9-6.4-4.1-10.3c-0.4-0.3-0.8-0.3-1.1-0.7c0.9-0.9,2.6-3,3.3-3.6c1.3-1,5.1-1.6,5.2-1.7c2.1-1.7,1.9-2.5,3.4-4.1
                c1.3-1.4,3.4-1.3,4.6-3.2c-2.9-1.6-3.4-7.8-5.9-9.9c1.2-0.3,1.7-0.8,3.5-0.4c-0.2-2.3,4.8-9.1,6.9-10.4c0.6,1.3,6.6,11.8,8.4,10
                c0.6,0.5,0.9,2.6,1,2.5c3-4.3,6.3,10.1,6.4,11.2c0.3,2.3,0,4.8,0.7,7.2c0.7,2.5,2.7,6.8,1,8.6c0.8,0.6,1.1,2,1.7,2.7
                c-0.1-0.1-2.3-2-2-0.9c0.1,0.3,2.5,2.5,3,3.8c1.4,3.3-0.1,2.8,0.1,5.1c0-1,1.7-0.8,1.8-1.2c0,0,1.2,5.1,1.4,5.8
                c0.1,0.4,5.3,15.6,6.9,13.2c0,0-0.6,5-0.8,4.5c0.7,1.8,0.4-0.3-0.1,2.4c1.6,0,2.2-3.4,2.2-3.8C356.5,249.9,362,252.5,365.9,252.5z
                "
            />
            <g id="ukSvg-scotland">
              <linearGradient
                id="ukSvg-scotlandMainLand_1_"
                gradientUnits="userSpaceOnUse"
                x1="256.05"
                y1="758.6792"
                x2="256.05"
                y2="1003.0771"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                id="ukSvg-scotlandMainLand"
                className="st6"
                fill={this.getColor("scotland")}
                d="M181.4,117.2c0.8-2.4,6-3.5,8.9-3.8c-0.6,0.2,3.5,2.5,2.6,1.5
                  c0.3,0.4,0.3,1.9,1.2,1.4c0.2-0.1-1-2.7-1-2.8c0,0,1.2,0.8,0.9,0.9c0.3-0.1,0.3,0,0.8,0c0.7,0,1.3-0.6,2.7-0.6
                  c0.9-2.6-5.5,0.5-1.8-3.4c0.6-0.6,3.8-1.4,4.7-1.9c0-0.1,0-0.2,0-0.3c-0.6,0.1-3,2.6-3.9,0.8c-0.3-0.6,1.8-1.4,2.1-1.4
                  c-1.8-1.2-5.1,1.1-6.6-0.7c0.7,0,2-0.1,2.5-0.3c-0.5-0.5,0.2-0.4-1-1.1c0.8-1.4,1.5-2,2.3-2.6c0.8-0.5-0.9-0.7-0.9-0.7
                  c0.2-4.7,3.6-1.1,5-0.7c2.6,0.6,2.9,1,6.2,0c-0.1,0-0.4-0.8-0.4-0.9c-1.3,2.2-3.8,0.2-4.8,0c-1.1-0.3,0-1.9,0-1.9
                  c-1.1-0.8-2.1,0.3-2.3,0.2c-2-1.4-1.3-2.5,0.7-4.2c4.2-3.5,3.8,1.6,6.9,1.3c-0.7,0.1,5-2.2,4.5-1.2c-1,2.4-9.5-3.4-9.8-4.1
                  c-0.3-0.6,3.2-5.3,3.5-5.3c3.3-0.9,3.9,4.2,6.6,2c-4.1-0.3-4.3-3.5-1-5.1c-4.5,1.8-4.8,1.3-9.9,1.3c0.2-0.6,2-2.3,2.1-2.5
                  c0,0,1.1-1.7,1.1-1.1c0,2.8,8.3-3.9,6.7-4.6c-0.2-0.1-4.8,5.9-5.5,3.9c0,0.1-0.9-0.9-0.8-1.2c0.2-0.4,1.1-0.2,1.4-0.7
                  c-0.2,0.4-0.5-1.7-0.4-1.6c0.3,0.3-3.1,2.9-3.5,3c-3.1,0.9-1.3,0.5-3.4-0.9c-0.2-0.1-0.4,1-0.5,0.8c0,0-0.3-1.8-0.2-1.8
                  c0.5-0.1,0.2-0.5,0.7,0.1c-0.2-0.2-0.4-3.2,0.1-3c-2.6-1.3-1.8-8.6,0.2-8.8c0.5-0.1,2.2,2.7,2.5,2.4c0.2-0.2,0.4-0.2,0.7-0.4
                  c-0.5,0.4,1.8,2.4,2.1,2.8c-0.2-0.4-0.4-1.4-0.3-1.8c-0.1-0.1,1.3,1.3,1,1.3c8.6,0.5-2.4-2.6-2.4-2.9c0.1-0.6,0.5,0,0.6,0
                  c-1.7,0.2-7.5-8.6,0.3-7.6c0.5,0.1-7.6-8.4-3.6-10.6c3.4-4.3,6.1,6.7,6.7,6.2c0,0.1-1-1.9-0.1-1.7c0.2,0,0.2,1.2,0.8,0.4
                  c0.7-2.8-3.9-5.2-1.5-7.6c3.1-3.1,2.9,6.3,5.6,4.1c0.2-0.3,0.8-3.4,1.2-3.4c2.3,0.2,4,3.6,6.4,4.3c-0.3-0.4-5.9-5.4-5.7-5.6
                  c0.1-0.2,2,0.1,1.7-0.3c0,0.1,10.8,5.7,8.9,7.7c2.1-2.6-5.2-6-4.7-7c0-0.1,2.6-1,1.6-1.7c-1.5-1-6.3-3.4-7.5-5.8
                  c-1.7-3.4,1.1-4.8,2-0.8c0.1-0.1,0.3-0.8,0.3-1.2c0.2,0.2,1,0.7,1.6,0.9c0.9-1.2,0.7-4,0.1-3.4c0.4-0.3,0.8,0.1,1.2-0.1
                  c0.1,0.1-1.1-1-1-1.1c1.7-0.3,0.7-0.1,1.9-0.9c-0.1,0-2.5,0.3-2.2-0.3c0.1-0.1,0.9-0.5,0.9-0.6s-0.9,0.2-0.9,0.2
                  c0-0.2-0.1-0.4-0.3-0.5c0,0.2-3.3-5.6-1.6-5.8c0.6-0.1,1.7,1.6,2,2.5c1.9-1.8,3.6-2.2,4.9-0.4c0.8-1.9-0.2-1.1,1.3-0.8
                  c0.4,0.1-0.2-1.1-0.3-0.9c1.3-1.7,5.4,3.4,6.3,3.2c0.4-0.1-1.6-2.2-1.6-2.2c0.8-0.2,1.5,0,2.3-0.1c0.4,0.8-9.9-4.2-7.1-3.7
                  c-0.2-0.6,0.4-0.3,0.5-0.5c-0.2-0.1-0.3-0.2-0.2-0.4c-0.5-0.2-0.3,0.5-0.6,0.5c0.3,0-0.7-1.9-0.7-2.1c0,0,1.1,0.2,1,0
                  c-0.1-1,0.2-2.1,0.4-3.5c0.2,0.2,0.3,0.1,0.3-0.1c0.6-0.2,3.1,2.5,2.9,2.5c0.8,0.1-0.4-2.3-0.7-1.9c0.7-0.8,0.6,0.1,1.2-0.3
                  c-0.6,0-2.1,0.2-2.6-0.2c-0.3-0.3,1.2-0.1,1.2-0.1c-0.3-0.3-1-0.4-1.2-0.6c-0.5-0.5,1.9-1.7,1.3-1.6c1.1-0.1,2.2,0.9,2.7,1.6
                  c-0.7-1.7-0.8-1.1-2.6-2c-0.1,0.2-0.5-3.5,0.1-5.1c0.8-2.1,2.2-2.7,2.6-5.3c3.9-0.5,6.9,3.2,5.5,6.5c0.4-0.3,0.5-0.2,0.4,0.3
                  c0.4-2.1,0.8-2.5,1.5-3.5c-0.1-0.4-0.7-1.1-0.7-1.5c0.3,0.1,0.6,0,0.8-0.2c-0.6-0.2,3.9,4.2,3.6,3.4c0.5,1.4-3,5-3.6,6.2
                  c0.2-0.1,0.3-0.1,0.3,0.1c2.3-1.5,2.9-4,4.5-3.9c0.3-5.5,5.5-3.1,5.1-1.9c2.6-1.2-0.5,4.8-0.8,6.1c1.6-1.4,5.8-9.3,6.7-4.1
                  c0.8,0,0.4-0.3,1.5,0.3c-0.2-0.5-0.5-1-0.7-1.5c0.1,0,1.1,0.3,1,0.2s-0.3-1,0-1.1c2,0.3,3-1.7,4.4,0.2c0.3-1,1-2.8,1.7-3.1
                  c0.2-0.1,0.4,2,0.5,2.2c1.6-1.2,4,0.3,6.5-0.3c-0.6,0.1,8.4-3.8,8.4-2.8c-0.1,1.1,3,1.9,4.2,1.4c2.3-1-1.1-4.2,1.3-4.3
                  c-0.2,0,9.8,1.5,10,1.7c1.4,1.5-0.2,2.5-0.5,3.7c-0.2,0.8-1.2,2.2-1.3,2.9c-0.5,4.1-0.4,1.4,1.7,3.6c1.6,1.7-4.4,9.5-6.1,10.7
                  c-1,0.7-2.5,0.5-3.7,1.4c-2.5,2-3.6,4.4-5.8,6.7c-1.8,1.8-17.2,13-16.7,13.7c0,0,0.5-0.7,0.6-0.9c-0.9-0.1-1.5,0.1-2.3-0.2
                  c1.3,0.8,3.4,5.9,2,5.6c-0.3-0.1-4.1,0.5-4.2,0.1c0.1,0.2,0.9-0.8,0.4-0.8c-4.1-0.3-5.9-0.1-9-4.2c-0.8,3.1,8.1,6.8,9.5,6.6
                  c0,0-0.8-0.7-0.7-0.8c-0.6,0.4,4.9,1.2,4.2,1c0.6,0.2,2-0.3,3.1,0.1c-0.2,0.3-1.4,0.8-1.4,0.9c0,1,5.4-2.5,5.9-3
                  c0.9,2.3-7.1,10.2-8.6,10.1c-0.1-0.9,0.9-1.9,0.8-2.7c-2.3-0.3-3,1.9-4.7,2.6c-1,0.4-3.2-0.1-4.3,0.6c-1.7,0.9-4.5,4.7-5.1,6.6
                  c2.7-1.7,4.6-4.9,8-5.9c-0.4,0.1,6.4-0.2,6.6,0c-1,2.6-2.9,3.7-3.4,6.2c0,0-4.8,1.6-5.2,1.7c5.7,1.7-6.7,2.5-7,3.4
                  c0-0.1,6.3,0,7.6-0.2c0.5-0.1,8.7-4.4,5.1-5.2c1-0.6,1.7-0.4,2.1-1.2c-0.2,0.4,1.9,1,2,1c-0.3-0.1-0.5-0.2-0.8-0.3
                  c2.4,0.9,4.9,0.2,6.5-1.7c0.1,0-1.7,0.9-1.6,0.9c-0.3-0.1,3.4-2.1,3.4-2c-0.1,0.2-0.9,0.5-1.2,0.8c1.2-1.1,1.7-0.9,1.7-1.5
                  c3.1-1.8,2.5,1.5,1.9,1.2c1.1,0.5,2.7,0.5,0.6-1.5c1.7-0.6,2.5-0.1,4.2-1c0.5-0.3,0-1.4,0.1-1.4c1.2-0.1,7.9-1.9,7.5-0.1
                  c-0.1-0.2-0.2-0.4-0.4-0.6c6.1,4.5,10.3,1.6,15.5,1.6c2,0,7.4,0.5,9.5,2c0.8-1.8,10.2-1.1,11.6-1.2c6.5-0.2,11.4,1.7,11.4,10
                  c0-0.9,1.6,1.7,1.2,1.3c-0.1,0-0.5-0.3-0.5-0.4c-1.1,0,0.6,2.2,0.6,2.1c-0.1,0.5-6.7,9.9-7.2,9.6c0.5,0.3-1.5-3.2-1.4-3
                  c2.8,2.4-0.7,9.4-1.4,13.1c-0.3,1.8-2.1,5.4-3.5,8.9c-1.3,3.2-0.9,6.5-3.1,9.7c-2,3.1-6.7,8.6-8.3,7c-0.2,0.2-0.7,1.7,0.2,1.5
                  c0.2,0,2.6-0.1,2.6,0c0,0.7-2.1,4.2-2.1,4.3c0.2-0.3-0.5,4.2-1.9,4.2c-1.9,1-3.2,2.6-5,3.7c-0.9,0.5-0.2,1.8-1.2,2.2
                  c-0.6,0.3-2-1.2-2.9-0.9c-2.4,0.7-5.6,0.7-7.7,1.5c-5.6,2.3-5,5.9-11.2,3.8c3.4,4.7,10.5-1.7,14.1-3c5.5-1.9,6.8,3.7,2.2,4
                  c0.9,0,1.3,0.2,2.3,0c1.9,4.2,11.8,3.2,4.7,8.7c-1.9,1.5-5,2-6.3,2.2c-1.4,0.2-1.5-1.5-4.7,0.1c-2.3,1.1-3.7,4.4-5.3,5.7
                  c-0.6,0.5-8.2,3.8-8.2,3.9c-0.3,1.6-1.8-0.1-3.6-0.6c-2.6-0.8-5.3-0.6-7.6-2.4c0.3,2.4,0.7,2.1,1.8,3.7
                  c4.7-2.1,13.5,3.6,16.9,1.7c3.6,4.8,9,1,11.5-1.5c-0.9,0,2.1-3.6,5.4-2.7c3,0.9,2.1,2.9,3,3.7c0.2,0.2,3.4,0,4.5,0.6
                  c1.5,0.8,2.2,2.4,3.9,3.2c3.9,1.8,6,0.6,8.6,3.9c5.3,6.8-5.4,8.4-4.6,14.4c-1.5-0.4-2.5,0.2-3.5,0.4c2.5,2.1,3,8.3,5.9,9.9
                  c-1.2,1.9-3.3,1.8-4.6,3.2c-1.5,1.6-1.3,2.5-3.4,4.2c-0.1,0.1-4,0.8-5.2,1.7c-0.8,0.6-2.5,2.6-3.4,3.5c0.4,0.3,0.8,0.4,1.1,0.7
                  c-3.1,3.2-10.4,13.3-15.2,11.7c2.7,7.4-12,4.9-14.5,4c0-0.3,0.2-0.4,0.6-0.3c-0.2,0-1.5,1.5-2.5,1.4c-1.6,0-2-2.6-2.6-2.6
                  c0.5,0-0.2,7.5-1,8.1c0.7-0.5-4.7,0-4.6,0c-0.8,0-3.2-1.2-2.9-1.2c-0.9,0,0.3,2.7,0.3,2.5c-0.1,0.7-1.2-1-1.4-1
                  c0,0.4,0.1,0.8,0.4,1.1c-0.3-0.2-0.6-0.3-0.9-0.1c-0.1,0.1,1.2,1.3,1.3,1.4c-0.4,0.5-4.6,3.2-5.4,3.2c-4.3-0.1-1.1-2.6-2.6-3.5
                  c-0.3-0.2-0.4,1-0.7,0.7c0-0.7,0.4-0.7,0.4-1.5c-0.7,1.4-0.8,3.8-1.2,4.4c-0.2,0.4-1.1-1.4-0.9-1.4c-3.4-1.4-1.8-1.7-2.8-4.9
                  c0.6,2.1-6,0.6-6.8-2.9c-1.1,1.3-1.1,2.3-0.8,4.5c0.1,1.1,2.1,1.3,2.4,2.7c-0.2-0.8-0.8,0.7-0.8,1c0.9,5.2-1.2,7.4-6.3,2.9
                  c-3.5-3.1-7-7.4-10.9-9.1c-2.6,3.4-3.3,2.9-2.4,7.9c0.5,2.8,1.2,4.2,2,6.2c-2.1,0.4-4.9-1.9-3.1-3.9c-1.5-3.6-3.9-5.3-6.1-9.5
                  c-1-1.8-2-5.8-1.2-7.6c2.3-5.2,4.2,0.8,4.5,0.7c-1.5,0.7,0.4,4.2,1.1,3.7c2-1.5-0.2-2.2-0.2-4c0-4.5,0-7.3,2.8-11.3
                  c3.4-4.8,4-11.1,7.3-14.7c1.2-1.3,3.5-1.3,4.2-2.5c0.8-1.3-0.3-7-1.3-6c2.8-2.8-3.9-4.9-5.4-7.7c-1-1.9-1.5-9.6-0.7-13.2
                  c1-4.7,2.2-3.9,6.1-3.1c3.5,0.7,8.7,1.2,11.1,3.8c-0.8-1-4.9-4-6-3.8c-0.7,0.1-3.1-0.7-4.3-1.4c0.1-0.4-1.8-4.1-2.8-3.1
                  c0,0-1.3-4.1-1.6-4c-2,0.3,4,6.9,1.1,5.8c-4.7-1.8-0.1-14,2.1-13.6c-2.8-0.4-4.6,12.5-5.5,2c-0.2,0.7,0.7,11.4-0.2,11.5
                  c-0.1,0-2.5-1-2.2-0.8c0.9,1.1,2.6,7.3-1,7.9c-2.6,0.5-3.7-7.2-4.4-9.1c-2.1,0,5.4,8.2-0.9,5.6c-0.9-0.4-2.6-3.8-1.6-4.1
                  c-2.4,0.6-0.2,8.5-0.9,9.4c-0.3,0.5-3.8-3.4-3.8-3.2c-0.9-2.1-1-5.2,0.1-7.8c3-7.5,9.8-9.6,14.6-15.5c-1.4,1.2-3.5,2.2-4.6,1.2
                  c-2.8,4-8.5,7.9-10.1,10.5c-0.9,1.4-2.3,6.7-3.7,1.7c-0.9,3.3,0.1,7.8,1.1,10.2c-3.1,1.5-4.8,5.5-7.8,6.4c-0.1,0,0.3-1.6,0.2-1.9
                  c-4.6-0.4,0.6-7.2,1.6-9c-1.5,1.2-2.7,1.9-3.8,3.1c-0.1-1.3,3.6-8.8,4.3-8.2c0,0-0.8,0.8-0.9,0.5c-0.1-0.4,1.3-1,1.1-1.4
                  l-1.4,0.9c0.3-0.6,0.8-1,1.1-1.6c0,0.8-2.4,3.1-3.1,4c0.1-0.2,1-1.5,0.8-1.7c-0.8-0.6-0.8,2.9-0.7,2.1c-0.6,0.9-0.8,1.9-1.5,2.7
                  c0,0.1,0-2.5,0.5-2c-0.3-0.3-1,1.1-1.2,1c0,0,3.4-9.4,6.6-7.4c-1.5-0.5,0.4-7.2,1-7c-0.7-0.2-3.4,3.1-3.7,3.5
                  c0.3-0.5,0.7-0.9,1-1.4c0-0.1-0.6,0.6-0.5,0.2c1.2-2.2,1.9-3.4,1.6-5.2c0-0.3,2.7-1,2.9-1.1c-0.7-0.3-0.3-0.4-0.5-0.8
                  c-0.9,0.5-2.1,0.4-3.3,1c0.9-0.4-0.1-3.7,0.7-4.8c0.1-0.1,4.5-2,4.5-2.1c-0.1-0.3-2.5,1-2.7,1.1c-0.5-1.4,3.4-6.9,3.5-6.7
                  c0.1,0.1-0.5,0.6-0.6,0.6c2-0.3,6.1,1.1,7.1,0.8c1.5-0.4,6.6-6.9,5.7-7.1c-0.4-0.1-3.4,5.6-3.9,5.9c-1.1,0.5-6.3,0.1-7.5-0.1
                  c0-1.1-0.3-1.6-0.5-2.4c-0.3,0.6-0.7,1-1.1,1.4c0.3-1.5,0.1-0.1-0.4-0.5s7.1-4.4,7.7-4.4c-2.1,0-4.2,3.2-5.6,0.9
                  c-0.6-1.1,4.8-5.1,3.2-6.4c1.2,1,9.4-4.5,11.8-3.5c-2-0.8-7.8,3.3-8.9,0.2c-0.8-2.2,5.6-5.5,5.2-7.2c-0.6-2.2-5.9-1.8-7.7-2.2
                  c3.3,2.3,6.8-0.6,6,3.7c-0.1,0.4-4.8,6.1-4.7,6c-0.5,0.1-8.1,4.4-8.4,4.8c-0.5,0.7-0.7,5.7-4.7,6.5c-2,0.4-4.6-1.2-2.8-3.6
                  c0.5,0.3-3.2,1.2-4.9,0.3c-1.2-0.6-2.8-2.1-3.1-3.8c-1.2-6.5,4.3,0.5,5.3-0.4c0.4-0.4-1.9-1.3-1.7-1.9c2.5-4.2,7.9-0.5,11.6-1.4
                  c-1.1-0.3-4.8-1.5-5.6-1.5c-1.4,0.1-5.9,2.3-7.1,1.2c-0.9-0.4-1.3-1.2-2,0.1C187,118.5,182.6,120.2,181.4,117.2z"
              />

              <linearGradient
                id="SVGID_1_"
                gradientUnits="userSpaceOnUse"
                x1="216.2618"
                y1="826.033"
                x2="216.2618"
                y2="837.4093"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st7"
                fill={this.getColor("scotland")}
                d="M219.6,176.2c1,2.4-0.1,3.4-1.9,2c-2.7-2.1-0.9-3.6-2.1-5.9c-0.3-0.7-4.4-2.3-2.6-4.2
                  c2.2-2.2,3.7,2.3,3.9,3.1c0.3,0.8,1.9,1.1,2.2,1.3C219.1,172.3,219.6,176.2,219.6,176.2z"
              />

              <linearGradient
                id="SVGID_2_"
                gradientUnits="userSpaceOnUse"
                x1="211.1432"
                y1="807.6706"
                x2="211.1432"
                y2="825.0644"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st8"
                fill={this.getColor("scotland")}
                d="M216.4,190c-1.8,2,2.1,6.6-3.6,7.1c-5.1,0.4-7.1-7-6.9-10.6c0.2-3.4,2.6-7.8,5.5-6.6
                  C217,182.1,212,187.9,216.4,190z"
              />

              <linearGradient
                id="SVGID_3_"
                gradientUnits="userSpaceOnUse"
                x1="199.8117"
                y1="799.7559"
                x2="199.8117"
                y2="833.2895"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st9"
                fill={this.getColor("scotland")}
                d="M209.1,175.3c-0.9-1.3-6.8,9.3-5.6,12.7c-2,0.3-2.1,7.8-5.5,8.9c8.1,2.3-3.8,11.1-7.3,7.1
                  c-0.5-0.5,0.2-4.5,0.4-5.2c0.3-0.8,2.1-1.8,2.2-2.1c0.7-1.8,0.6-3.5,1-5.5c1.2-5.6,1.6-10.4,5.7-14.9
                  C204.2,171.5,205.8,168.9,209.1,175.3z"
              />

              <linearGradient
                id="SVGID_4_"
                gradientUnits="userSpaceOnUse"
                x1="204.5788"
                y1="870.9971"
                x2="204.5788"
                y2="876.8235"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st10"
                fill={this.getColor("scotland")}
                d="M207.6,128c0.7,0.7-5.2,5.4-6.1,5.8c0.8-1.4,3.6-5,4.6-5.6C205.9,128.3,207.4,128.1,207.6,128z"
              />

              <linearGradient
                id="SVGID_5_"
                gradientUnits="userSpaceOnUse"
                x1="183.9339"
                y1="912.5195"
                x2="183.9339"
                y2="948.3121"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st11"
                fill={this.getColor("scotland")}
                d="M202.4,85.2c1.2,3.1-4.8,6.6-6.5,3.9c0.7,1-9.4,1-6.5-2.6c0.4-0.4-1.3,6.4-1.9,5.8c-0.8-1-0.8-2.5-0.6-3.9
                  c0,0-2.2-0.2-1.9-0.6c0.2-0.2-5.3,3.1-5.3,1.8c0-0.2,2.3-2,1.3-2.4c-0.9-0.5-1.8,2.2-2.8-0.2c-0.7-1.7,1.8-1.8,1.1-3
                  c-1.8-0.9-2.4,2.5-3.6-2.3c-0.5-2.1,0.6-1.8,1.1-3.1c0.2-0.4,3.6,3.6,3.9,2.5c0.2-0.9-5-2.9-2.2-3.8c0,0-2.6,1.2-2.6,1.1
                  c-0.3-0.8-0.3-3.6-0.9-4c0.2,0.1-1,2.1-1.2,2c0.4,0.1-0.2-2.4-0.2-2.6c-1,1.1-1.7,2.8-1.5,4.7c0-0.1-7.7-6.3-6.7-5.8l0.4-2.3
                  c0.4-0.2,1.8,0.8,1.9,0.6c1.6-2.8-2.4-3.7,1.5-1.5c1.5,0.8,0.4,2.5,2.1,2.1c0,0,1,1.5,0.8,1.6c-0.3,0.1-1.1-5-1.4-5.1
                  c0,0,2.6,0.1,2.2,0.7c1.2-1.4-2.6-3-2.6-3c-0.2-0.1,1-3.7,0.9-3.9c2,2.2,3.4,4.4,4.9,6.9c0,0,1.2-1.1,1.3-1.1
                  c0,0-1.2,3.2-0.3,2.9l1.4-3.3c1.9,1.5,1.2,3,2.3,4.5c0-0.9-1.9-7.1-2-7.1c1,0.1,0.2-0.2,1.2-1c-0.7-0.2-0.4,0.3-1.1,0.1
                  c-0.5-2,2-7.1,1.8-7.2c0.7,0,1,0.3,1.9,0c1,2.1,2.8,6.1,4.8,7.5c1.3,0.9-1.2,11.3-2.8,11.9c2.6-1,2.1,1.5,3,3
                  c0.6,1.2-1.3,2.2-2.4,2.8c0,0,2.9-1.1,3.7-0.6c1.5,1-1.3,1.9-1.1,2.3c0.2,0,0.3,0.1,0.2,0.3c2.4-1.7,2.2,0,4.4,0.6
                  c-0.2-0.1,3.9,1.1,3.2,0.6c-0.1,0.3-0.2,0.5-0.5,0.6c-0.5-0.9,5.4-1.5,5-1.3c-0.3,0.1-0.5,0.2-0.7,0.4
                  C200.6,85,201.4,84.8,202.4,85.2z"
              />

              <linearGradient
                id="SVGID_6_"
                gradientUnits="userSpaceOnUse"
                x1="187.3247"
                y1="860.2571"
                x2="187.3247"
                y2="884.1912"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st12"
                fill={this.getColor("scotland")}
                d="M199.8,134.6c0,0.6,0.1,1.1-0.3,1.5c-0.3-0.4-0.5-0.5-0.8-1.1c0.7,1.2,0.3,5.1-1.1,1.6
                  c-0.1,0-1.1-0.2-1.2-0.1c-0.5,0.9-0.1,1.5-1.7,2.6c-0.1,0.1,3.3-1.2,3.4-0.6c0.3,1.8-6.4,5.3-6.8,3.2c0.2-0.3,2.1-1.1,1.3-1.8
                  c-0.2-0.2-13.8,3.9-14.3,4.5c-1.8-0.4-2.7-1.2-2.6-2.6c-2.6-2.4,1.4-2,2.6-1.8c-0.2,0.8,0.5,0.8-0.7,0.6c0.2,0,1.3,0.4,1.7,0.5
                  c-0.1-0.6-0.4-0.7-0.4-1.3c1.5-1,7.7,1.8,8.2-2.2c0-0.2,0.9,0.2,1.2,0c0,0-3.7-0.7-2.9,0.4c-1.1-1.4-7,3.4-3.2-2.1
                  c1.5-2.2,5.6-2.6,5.5-5.4c-1.1-0.3-2.9,1.7-4.3,1.1c-0.5-0.2,0-1.6-1.2-2.1c-2.1-0.9-7.9-1.7-3.3-4.2c-1.3-0.9-0.5-0.9-1.4-2
                  c0.7,0.4,2.4-0.2,2.1-0.4c0.9,0.4,1.1,1.6,2.1,1.9c-0.2-0.1-1.6-2.2-0.9-2.9c0.5,0.1,0.5,0,0.9,0.6c1.7-7,6.8,5.8,7.3,6.3
                  c1.2,1.2,4.5,0.7,5.9,1.4c1.7,0.9,2.6,3.2,3.5,3.9C199.5,134.9,199,133.9,199.8,134.6z"
              />

              <linearGradient
                id="SVGID_7_"
                gradientUnits="userSpaceOnUse"
                x1="191.75"
                y1="841.6954"
                x2="191.75"
                y2="852.1733"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st13"
                fill={this.getColor("scotland")}
                d="M197.4,153.9c-2,0-3,11.2-6.1,8.2c-2,2.3-3,0.2-5.2,0.8c1.1-3.5,10.7-10.4,10.9-10.2
                  C197.2,153.1,197.3,153.8,197.4,153.9z"
              />

              <linearGradient
                id="SVGID_8_"
                gradientUnits="userSpaceOnUse"
                x1="193.0016"
                y1="905.653"
                x2="193.0016"
                y2="915.7919"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st14"
                fill={this.getColor("scotland")}
                d="M197.2,92c-0.2,0-5.7,6.7-7.8,7.2C186.2,99.8,196.9,82.3,197.2,92z"
              />

              <linearGradient
                id="SVGID_9_"
                gradientUnits="userSpaceOnUse"
                x1="192.1372"
                y1="921.2592"
                x2="192.1372"
                y2="924.2486"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st15"
                fill={this.getColor("scotland")}
                d="M193.5,81.8C192,87.5,188.2,77.4,193.5,81.8L193.5,81.8z"
              />

              <linearGradient
                id="SVGID_10_"
                gradientUnits="userSpaceOnUse"
                x1="192.599"
                y1="936.1402"
                x2="192.599"
                y2="939.3968"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st16"
                fill={this.getColor("scotland")}
                d="M193.4,65.6c-0.4,1.6-0.4,2.8-1.4,3c0,0.6,0.3-1.4,0.5-1.1c-0.1-0.1-1,0.5-0.6-0.3
                  C192.4,66,192.7,65.2,193.4,65.6z"
              />

              <linearGradient
                id="SVGID_11_"
                gradientUnits="userSpaceOnUse"
                x1="187.744"
                y1="830.9444"
                x2="187.744"
                y2="841.9871"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st17"
                fill={this.getColor("scotland")}
                d="M191.3,162.8c0.4,0,1,0,1.3,0.1c-0.6,1.5-1.9,3.8-2.6,5.9c0,0,0.3-1-0.1-0.8c-1,0.5-6.3,12.1-7,1.6
                  C182.6,163.5,188,164.9,191.3,162.8z"
              />

              <linearGradient
                id="SVGID_12_"
                gradientUnits="userSpaceOnUse"
                x1="190.2216"
                y1="924.5317"
                x2="190.2216"
                y2="934.6965"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st18"
                fill={this.getColor("scotland")}
                d="M192.3,70.1c-0.3-0.4-1.6,6.9-1.5,6.5c-0.1,0.3,1.3,4.6-1.5,3.4c-3.3-1.4,1.5-7.4,1.6-7.7
                  C191.5,70.7,191.5,70.2,192.3,70.1z"
              />

              <linearGradient
                id="SVGID_13_"
                gradientUnits="userSpaceOnUse"
                x1="187.6607"
                y1="977.0976"
                x2="187.6607"
                y2="981.8088"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st19"
                fill={this.getColor("scotland")}
                d="M189.8,23c0.6,0.4-2.2,6.2-3.9,4.3C184,25.4,188.5,23.5,189.8,23z"
              />

              <linearGradient
                id="SVGID_14_"
                gradientUnits="userSpaceOnUse"
                x1="173.491"
                y1="959.6571"
                x2="173.491"
                y2="997.257"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st20"
                fill={this.getColor("scotland")}
                d="M189.2,12.2c-1.2,2.5,0,3.6,0.3,5.8c-2,1.1-5.7,6.4-6.9,5.9c0.4,0.4,0.2,0.6-0.5,0.8
                  c-0.1-0.2,2.6,1.2,2.9,1.3c-1.2,1.2-2.2,1-3.3-0.4c-1,0.7,1.7,2.9-1.2,4.2c0,0.4,1.7-0.6,1.7,0c0,1.5-3.2,0-3.9-0.3
                  c0.8,0.3,1.2,0.8,2,1.1c-0.3,0.3-6.8,2.9-6.7,2.1c0-0.3,1.9,0.4,2.1,0.5c0-0.2,5.3-1.4,5.5-1.6c0,0.7,0.1,0.7,0.3,0.2
                  c0.3,0.2-0.3,1.3-0.3,1.5c1,0,1.7,2.4-1.2,2.1c2.2,0.6,2.1,3.4,1,3c-0.8-0.2-3.9-0.7-4.3-1.2c-0.1,0.6-0.9,0.9-1.4,0.7
                  c4,1.1,4.9,2.9,1.5,4.6c0.1,0.1-1.1-1.9-0.9-1.9c-0.4,0.1,0.6,2.4,0,2.5c-0.5,0.1-0.9-0.6-0.9-0.6c0.1,0.3,0,0.6-0.2,0.8
                  c-0.5-0.2-1.1-3.7-2.1-3.4c0,0,1.1,2.9,0.9,2.9c-0.7,0.1-0.5-0.4-1.2-0.2c-2.9-2.2-1.8-9.1,1.9-7.9c0-0.5-2.8-0.7-2.8-0.5
                  c0.1-0.3-3.3,6.3-1.6,6.3c1.2,0,0.9,1.3,1.7,2.2c0,0.1-1.2,0.1-1.3,0.2c0.3,0.4,2.2,1.3,1.3,2.3c-2.4-1.1-5.8-1.3-5.3-3.4
                  c-0.2-0.1-0.3-0.2-0.3-0.4c-0.3-0.1-1.6-0.2-2.1-0.2c0-0.2,0.6-0.8,0.4-0.9c0.8,0.2-4-0.4-3.1-1c-1.1,0.8-3.1-1.2-3.4-1.6
                  c-1.4-1.4,2.3-1.2,1.9-1.2c-0.1,0,3.6-2.2,3.8-2.2c-0.6,0-4.1,1.4-4,0.1c0-0.6,1.4-0.2,1.4-0.5c0-0.2-1.2,0-1.2-0.1
                  c0,0,1.8-1.4,1.1-1.5c-2.3-0.3-1.8,1.8-2.5-2.1c-0.2-0.9,0.1-4.4,1-4.8c0.5,0.1,1.2,0.5,1.4,0.6c0.2-0.1,1.4-0.5,1.5-0.3
                  c-0.1-0.2-0.7-1-0.7-1.1c-0.5,0.1,0.3,0.6-0.3,0.8c-0.1-0.3-0.3-0.4-0.7-0.4c0-1.3,1.3-1.9,1.5-2.8c-0.5,1.6,2.2,0.6,1.9,0.6
                  c1.6,1.9,1.5,1.7-0.2,2c0.8,0.3,1.5,0.7,2.1,1c0.4,0.2-0.5,4.4,0.6,4.3c-0.2-0.7-0.8-4.2-0.1-4.4c-1,0.2,6.5-0.6,4.6,1.8
                  c-0.1-0.5,0-0.6,0.2-0.1c-0.7-0.1,0.7-1.2,0.5-0.8c0-0.2-1.5-1-1.7-1.2c0.5-1.1,0.3,0.3,0.8-0.1c-0.1,0.1-2.3-4-2.3-4.2
                  c0.5-0.3,1.1-0.2,1.5-0.5c0.1,0.1-2.1,0-1.7-0.3c0.6-0.4,2.2-2.1,2.5-1.7c1.3-1,2.1-1.2,3.5-2.3c2.1,0.9,12.3-6.7,12.5-8.6
                  C188.6,8.3,189.1,10.8,189.2,12.2z"
              />

              <linearGradient
                id="SVGID_15_"
                gradientUnits="userSpaceOnUse"
                x1="184.3742"
                y1="896.9974"
                x2="184.3742"
                y2="900.9412"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st21"
                fill={this.getColor("scotland")}
                d="M185.5,103.9c1.5,1.2-0.9,5.8-2.5,3.2C182,105.3,184.6,103.8,185.5,103.9z"
              />

              <linearGradient
                id="SVGID_16_"
                gradientUnits="userSpaceOnUse"
                x1="176.2462"
                y1="819.2218"
                x2="176.2462"
                y2="839.8374"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st22"
                fill={this.getColor("scotland")}
                d="M184.5,180.3c0.1,0-2.2,1.4-1.9,1.2c-2.5,1.7-5.8,4.6-9.4,4c0.2-0.6,1.2-2.6,1.2-3c0-0.7,1.5-0.7,1.5-0.6
                  c0-0.7-1.5-4.4-1.5-4c0,0,2.5-3.6,2.5-3.5c0-3.9-5.9,4-6.2,4.2c-2.8,2.1-2.4,1.1-2.7-0.4c-0.2-1.3,2.4-12.4,6.7-10.9
                  c-0.2-0.1-0.5,3.9-0.6,4.2c0.2,0,0.4,0,0.6,0c-0.3-2,3.3-6.7,6-6.4C181.6,165,184.2,179.4,184.5,180.3z"
              />

              <linearGradient
                id="SVGID_17_"
                gradientUnits="userSpaceOnUse"
                x1="183.1261"
                y1="912.9592"
                x2="183.1261"
                y2="914.9438"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st23"
                fill={this.getColor("scotland")}
                d="M184.1,89.9c0.8,0-1.8,3.8-2.1,0.8C183,90.1,183.1,90.2,184.1,89.9C184.3,89.9,184,89.9,184.1,89.9z"
              />

              <linearGradient
                id="SVGID_18_"
                gradientUnits="userSpaceOnUse"
                x1="181.5106"
                y1="872.072"
                x2="181.5106"
                y2="874.3208"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st24"
                fill={this.getColor("scotland")}
                d="M183.2,131.9C178.4,135.1,179,127.4,183.2,131.9L183.2,131.9z"
              />

              <linearGradient
                id="SVGID_19_"
                gradientUnits="userSpaceOnUse"
                x1="179.4568"
                y1="846.2861"
                x2="179.4568"
                y2="851.5898"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st25"
                fill={this.getColor("scotland")}
                d="M182,153.3c-0.6,2.4-2.2,5.1-3.1,5.1c-1.4-0.1-0.3,0.4-0.7-0.3c-0.2-0.3-1,0.9-1.2,0.4
                  C176.2,156.7,181.2,153.5,182,153.3z"
              />

              <linearGradient
                id="SVGID_20_"
                gradientUnits="userSpaceOnUse"
                x1="181.05"
                y1="894.5111"
                x2="181.05"
                y2="895.8811"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st26"
                fill={this.getColor("scotland")}
                d="M182,109.3c-0.5,0.9-0.5,1.6-1.9,0.4c0.5-0.1,0.6-0.3,0.4-0.8C180.1,109.1,181.8,109.3,182,109.3z"
              />

              <linearGradient
                id="SVGID_21_"
                gradientUnits="userSpaceOnUse"
                x1="178.3614"
                y1="901.6548"
                x2="178.3614"
                y2="908.5284"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st27"
                fill={this.getColor("scotland")}
                d="M181.5,99.3c1.9,0.7-1.9,4-3.1,3.9c-0.8-0.1-2.9-3.4-3.7-4.3c1.4-1.6,6.3-4.6,7.1-0.3
                  c-0.3,0.2-0.7,0.3-1.1,0.2C180.4,99.2,181.5,99.6,181.5,99.3z"
              />

              <linearGradient
                id="SVGID_22_"
                gradientUnits="userSpaceOnUse"
                x1="181.3139"
                y1="959.4669"
                x2="181.3139"
                y2="960.4384"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st28"
                fill={this.getColor("scotland")}
                d="M181.7,45c0.3,0.1,0.1,1.1-0.9-0.6C181.7,44.2,181.4,44.9,181.7,45z"
              />

              <linearGradient
                id="SVGID_23_"
                gradientUnits="userSpaceOnUse"
                x1="178.6"
                y1="873.0145"
                x2="178.6"
                y2="874.366"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st29"
                fill={this.getColor("scotland")}
                d="M179.5,130.6c-0.3,0.1-0.7,1-0.9,1.2c0.5-0.4-0.9-0.5-0.9-0.5C177.9,130.1,178.8,130.7,179.5,130.6z"
              />

              <linearGradient
                id="SVGID_24_"
                gradientUnits="userSpaceOnUse"
                x1="173.437"
                y1="863.196"
                x2="173.437"
                y2="865.7764"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st30"
                fill={this.getColor("scotland")}
                d="M174.5,139c-0.2,0.3-0.9,3.7-2.1,2.2C172.1,140.9,174.5,139.1,174.5,139z"
              />

              <linearGradient
                id="SVGID_25_"
                gradientUnits="userSpaceOnUse"
                x1="173.7418"
                y1="908.3536"
                x2="173.7418"
                y2="908.7918"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st31"
                fill={this.getColor("scotland")}
                d="M174.5,96.4c0,0-1.3,0.3-1.5-0.2C172.8,95.7,174.5,96.4,174.5,96.4z"
              />

              <linearGradient
                id="SVGID_26_"
                gradientUnits="userSpaceOnUse"
                x1="173.7866"
                y1="873.8197"
                x2="173.7866"
                y2="874.7171"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st32"
                fill={this.getColor("scotland")}
                d="M173.9,130.1C174.1,130.2,173.2,132.1,173.9,130.1C174,130.2,173.9,130.1,173.9,130.1z"
              />

              <linearGradient
                id="SVGID_27_"
                gradientUnits="userSpaceOnUse"
                x1="171.8"
                y1="908.4045"
                x2="171.8"
                y2="909.7709"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st33"
                fill={this.getColor("scotland")}
                d="M173.7,95.1c0,0-2.9,2.2-3.8,1C170.9,94.9,172.2,94.9,173.7,95.1z"
              />

              <linearGradient
                id="SVGID_28_"
                gradientUnits="userSpaceOnUse"
                x1="169.15"
                y1="879.4348"
                x2="169.15"
                y2="886.4433"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st34"
                fill={this.getColor("scotland")}
                d="M173.6,118.5c0,1.3-1.4,3.1-2.1,4c-0.3,0-0.5-1.1-0.6-1c-1.3,0.5-0.9,3.1-3,3.3c-0.2,0-0.2-0.8-0.4-1
                  c-0.1,0-2.1,1.6-2.3,1.5c-0.4-0.2,0.1-0.8-0.5-0.7C167,123.2,170.8,117.4,173.6,118.5z"
              />

              <linearGradient
                id="SVGID_29_"
                gradientUnits="userSpaceOnUse"
                x1="171.0598"
                y1="957.7947"
                x2="171.0598"
                y2="959.6884"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st35"
                fill={this.getColor("scotland")}
                d="M172,46.2c0.3,0.5,0,0.9-0.5,0.9c-0.2-0.1-1.6-1.1-1.2-0.8c-0.2-0.1-0.2-0.6-0.3-0.8c0.9-0.6,0,0.3,0.3-0.3
                  C170.5,44.7,172,46.2,172,46.2z"
              />

              <linearGradient
                id="SVGID_30_"
                gradientUnits="userSpaceOnUse"
                x1="169.4099"
                y1="979.1176"
                x2="169.4099"
                y2="979.5236"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st36"
                fill={this.getColor("scotland")}
                d="M169.6,25.7C169.8,25.9,168.6,24.7,169.6,25.7L169.6,25.7z"
              />

              <linearGradient
                id="SVGID_31_"
                gradientUnits="userSpaceOnUse"
                x1="167.4"
                y1="979.8854"
                x2="167.4"
                y2="983.0569"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st37"
                fill={this.getColor("scotland")}
                d="M169,24.4c-2.1,1.4-3.1,0.2-3.2-2.5C165.8,22.1,168.9,24.3,169,24.4z"
              />

              <linearGradient
                id="SVGID_32_"
                gradientUnits="userSpaceOnUse"
                x1="168.7875"
                y1="959.4064"
                x2="168.7875"
                y2="959.6044"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st38"
                fill={this.getColor("scotland")}
                d="M168.9,45.2C168.6,45.5,168.6,45.5,168.9,45.2L168.9,45.2z"
              />

              <linearGradient
                id="SVGID_33_"
                gradientUnits="userSpaceOnUse"
                x1="162.5278"
                y1="951.0396"
                x2="162.5278"
                y2="961.8997"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st39"
                fill={this.getColor("scotland")}
                d="M168.7,47.5c1.1,1.6-1.7,0.7-1.8,0.4c0,0,0.3,1.6,0.1,1.6c-0.7,0-1.5-1.1-1.6-1.8c0,0,0,2-0.2,1.9
                  c-1.4-0.7-0.4,1.7-1.1,1.8c-0.2-0.1-0.4-0.2-0.6,0c-0.2-0.4,0.1-0.1-0.7-0.5c0.5,0,0.2,0.4-0.2,0.3c0,0.2,0.8,0.6,0.6,0.8
                  c-0.4,0.5-3.3,1.6-2.4,2c-1.7-0.8-3.9-5.6-4.7-7.1c0.9,0,2.2,1.6,1.9,1.7c0.9-0.2,2.3-3.1,2.8-3.3c0.6-0.2,0.6,0.2,1.4-0.4
                  c0.1-0.4,0.1,0.9-0.1,0.8c0.4-0.1,0.5-0.2,0.3-0.4c1.1,0.6,0.4,0.2,0.7,0.7c0-0.3,0.2-0.4,0.4-0.4c-4.1-3.6,3.9-2.6,3.9-0.6
                  c-0.4-0.1-0.4-0.6-0.9-0.2C166.8,45.2,168.2,46.8,168.7,47.5z"
              />

              <linearGradient
                id="SVGID_34_"
                gradientUnits="userSpaceOnUse"
                x1="166.4536"
                y1="983.4935"
                x2="166.4536"
                y2="983.6137"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st40"
                fill={this.getColor("scotland")}
                d="M166.8,21.3C166.2,21.2,165.6,21.1,166.8,21.3L166.8,21.3z"
              />

              <linearGradient
                id="SVGID_35_"
                gradientUnits="userSpaceOnUse"
                x1="165.7021"
                y1="980.1627"
                x2="165.7021"
                y2="980.3685"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st41"
                fill={this.getColor("scotland")}
                d="M166,24.6C166.1,24.8,164.6,24.2,166,24.6C166,24.7,166,24.6,166,24.6z"
              />

              <linearGradient
                id="SVGID_36_"
                gradientUnits="userSpaceOnUse"
                x1="159.1"
                y1="873.1123"
                x2="159.1"
                y2="879.0928"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st42"
                fill={this.getColor("scotland")}
                d="M163.8,126.6c-1.1,1.3-6.8,6.3-9,5c-0.2-1.2,1-2.5-0.4-3.1c1.2-2,6.6-1.5,8.4-2.6
                  C163,126.5,163.3,126.5,163.8,126.6z"
              />

              <linearGradient
                id="SVGID_37_"
                gradientUnits="userSpaceOnUse"
                x1="159.5"
                y1="960.3163"
                x2="159.5"
                y2="962.4433"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st43"
                fill={this.getColor("scotland")}
                d="M161,42.4c0,0.9-0.1,1.4-0.8,1.9c-0.3,0-0.7-1-1-0.9c0,0-0.7,1.3-1,1.1c0.1,0-0.2-1.1-0.2-1.1
                  C158.6,42.8,160.3,42.3,161,42.4z"
              />

              <linearGradient
                id="SVGID_38_"
                gradientUnits="userSpaceOnUse"
                x1="157.9307"
                y1="946.738"
                x2="157.9307"
                y2="946.9626"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st44"
                fill={this.getColor("scotland")}
                d="M158.1,58.1C157.7,57.8,157.6,57.8,158.1,58.1L158.1,58.1z"
              />

              <linearGradient
                id="SVGID_39_"
                gradientUnits="userSpaceOnUse"
                x1="157.35"
                y1="951.6571"
                x2="157.35"
                y2="952.4755"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st45"
                fill={this.getColor("scotland")}
                d="M157.9,53.2c-0.4-0.3-0.8-0.1-1.1-0.8C157.4,52.2,157.5,52.6,157.9,53.2z"
              />

              <linearGradient
                id="SVGID_40_"
                gradientUnits="userSpaceOnUse"
                x1="157.2809"
                y1="968.6848"
                x2="157.2809"
                y2="970.1223"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st46"
                fill={this.getColor("scotland")}
                d="M157.9,36.1C157.8,36,155.1,33,157.9,36.1C158,36.2,157.8,36,157.9,36.1z"
              />

              <linearGradient
                id="SVGID_41_"
                gradientUnits="userSpaceOnUse"
                x1="157.37"
                y1="953.1395"
                x2="157.37"
                y2="954.1342"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st47"
                fill={this.getColor("scotland")}
                d="M157.7,51.5c0.2,0.2-0.4,0.4-0.7-0.6C157.7,50.3,157.2,51.1,157.7,51.5C157.8,51.6,157.6,51.4,157.7,51.5z"
              />

              <linearGradient
                id="SVGID_42_"
                gradientUnits="userSpaceOnUse"
                x1="157.7"
                y1="972.396"
                x2="157.7"
                y2="973.2519"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st48"
                fill={this.getColor("scotland")}
                d="M157.7,32C157.6,33.5,157.8,30.6,157.7,32C157.7,32.1,157.7,31.8,157.7,32z"
              />

              <linearGradient
                id="SVGID_43_"
                gradientUnits="userSpaceOnUse"
                x1="155.4597"
                y1="944.0871"
                x2="155.4597"
                y2="947.4594"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st49"
                fill={this.getColor("scotland")}
                d="M157.4,58.6c0.2,0.3-1.2,1.9-1.4,2.2c0-0.1-1.2-2.1-0.3-1.7c-0.4-0.2-1.7-0.6-2.2-0.8c0.7,0,1.6-1,1.7,0.4
                  c0.4-0.2,0.6-0.4,1.1-0.4c0.1,0.2-1-1.2-0.3-0.8C156.6,58,156.7,58.2,157.4,58.6z"
              />

              <linearGradient
                id="SVGID_44_"
                gradientUnits="userSpaceOnUse"
                x1="156.5208"
                y1="947.3719"
                x2="156.5208"
                y2="947.5799"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st50"
                fill={this.getColor("scotland")}
                d="M156.6,57.2C156.3,57.7,156.5,57.4,156.6,57.2L156.6,57.2z"
              />

              <linearGradient
                id="SVGID_45_"
                gradientUnits="userSpaceOnUse"
                x1="148.8591"
                y1="941.0285"
                x2="148.8591"
                y2="949.8082"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st51"
                fill={this.getColor("scotland")}
                d="M155.9,61.6c1.7,0-6.4,3-5.3,1.7c0.5-0.6,0.5,0.3,0.3-0.8c-0.3,0.6-0.4,0.5-0.6,1c-0.6,1.4-4.1-1.4-4.3-2
                  c-0.5,0.4-0.7-0.5-1-0.3c0.2-0.1-3.5,1.6-1.7-0.5c-0.3,0.3-1.9,0.3-1.7-0.7c0.1,0,0.9-0.1,0.8,0.1c2-1.5,1.4-5.4,4.7-1.3
                  c0.4-1.1,1.4-3.3,2.6-3.6c-0.1,0-0.2,1.8-0.1,1.7c0.5-0.5-1.7-0.2-0.8,0.5c0.5,0.3,0.9,0.7,1.3,1c0.8-0.4,1-0.3,1.7-0.7
                  c-0.1-0.1-1.3-0.3-1.1-0.7c0.8-0.6,2.3-0.8,2.2-0.8c0-0.1,0.3-1,0.6-0.9c1.9,1,0.7,2.4-0.2,2.3c-0.4,1,0.3,0.4-0.9,1.5
                  c1.3,1.1,0.4-0.4,0.5,0.8c0.6,0.1,1.1,0.6,1.6,0.8c-0.3-0.2-0.6-0.2-0.9-0.1c0,0.4,0.8,0.1,0.5,1.3
                  C154.5,61.8,155.8,61.6,155.9,61.6z"
              />

              <linearGradient
                id="SVGID_46_"
                gradientUnits="userSpaceOnUse"
                x1="153.7473"
                y1="950.3513"
                x2="153.7473"
                y2="952.2085"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st52"
                fill={this.getColor("scotland")}
                d="M154.8,52.7c1.2,0.9-1.6,1.9-2.4,1.7C152.8,53.6,153.7,52.3,154.8,52.7C155,52.8,154.7,52.6,154.8,52.7z"
              />

              <linearGradient
                id="SVGID_47_"
                gradientUnits="userSpaceOnUse"
                x1="151.25"
                y1="938.2004"
                x2="151.25"
                y2="941.2606"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st53"
                fill={this.getColor("scotland")}
                d="M154.8,64.3c-0.1,0.3-1.3,2.3-1.3,2c0.2,0.1,0.5,0.2,0.8,0.1c0.1,0-2.8-0.4-2.5-0.6
                  c0.1,0.3,0.4,0.6,0.8,0.7c-0.3,0.6-2.2-0.9-2.1-0.8c0,0-0.9,0.2-1.5-0.3c-0.4,0.2,0.9,1-0.1,0.9c0.2,0-1-2.8-1.2-2.6
                  c1.3-0.1,1.9,0.1,2.8,0.5c0,0-0.6-0.2-0.5-0.4C151.8,63.7,153.6,63.9,154.8,64.3z"
              />

              <linearGradient
                id="SVGID_48_"
                gradientUnits="userSpaceOnUse"
                x1="153.939"
                y1="937.3381"
                x2="153.939"
                y2="937.7679"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st54"
                fill={this.getColor("scotland")}
                d="M154.2,67.2c0,0-0.3,0.4-0.4,0.2C153.5,66.9,153.8,67,154.2,67.2z"
              />

              <linearGradient
                id="SVGID_49_"
                gradientUnits="userSpaceOnUse"
                x1="152.85"
                y1="935.8177"
                x2="152.85"
                y2="937.8178"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st55"
                fill={this.getColor("scotland")}
                d="M153.5,68c-0.2,0.1-0.3,0.6-0.6,1c-0.1-0.2-0.2-0.2-0.4-0.2c-0.1-0.4-0.2-0.9-0.3-1.6
                  c0.1-0.1,0.8,0.5,0.8,0.4c0.2-0.3-0.7-0.4-0.6-0.7C153.6,67.1,153.4,67.4,153.5,68z"
              />

              <linearGradient
                id="SVGID_50_"
                gradientUnits="userSpaceOnUse"
                x1="152.6666"
                y1="954.1713"
                x2="152.6666"
                y2="954.4215"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st56"
                fill={this.getColor("scotland")}
                d="M153.6,50.4C153.6,51,149.4,50.4,153.6,50.4C153.6,50.6,153.6,50.4,153.6,50.4z"
              />

              <linearGradient
                id="SVGID_51_"
                gradientUnits="userSpaceOnUse"
                x1="151.4091"
                y1="930.8066"
                x2="151.4091"
                y2="931.9007"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st57"
                fill={this.getColor("scotland")}
                d="M152,73.1c-0.3,0.2-0.8,0.6-0.3,0.8c0,0.1-0.8-0.3-0.9-0.4C151.8,72.3,152.1,73.1,152,73.1z"
              />

              <linearGradient
                id="SVGID_52_"
                gradientUnits="userSpaceOnUse"
                x1="150.9365"
                y1="936.4852"
                x2="150.9365"
                y2="937.9624"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st58"
                fill={this.getColor("scotland")}
                d="M152,68.4c-0.1,0-2.4-1.2-2.1-1.3c0.5-0.1,1.7-0.1,1.5,0C151.7,67.6,152,68.2,152,68.4z"
              />

              <linearGradient
                id="SVGID_53_"
                gradientUnits="userSpaceOnUse"
                x1="148.8716"
                y1="931.6459"
                x2="148.8716"
                y2="937.3501"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st59"
                fill={this.getColor("scotland")}
                d="M151.8,69.2c-0.2,0.1-0.2,0.5-0.9,0.2c0.1,0.4,0,0.3-0.2,0.4c0.1,0.1,0.5,0.3,0.8,0.5
                  c-0.9,0.5-0.8-0.8-1.1-0.8c-0.2,0,0.3,0.8,0.2,0.9c-0.5,0-0.2-0.5-0.6-0.6c0.1,0.4,0,0.5-0.2,0.3c0.4,1.1-0.4,0-0.3,0.5
                  c0,0.2,1.8,0.6,2,0.7c0,0-0.7-0.4-0.8-0.4c0.1,0,1.9,0.1,1.4,0.9c-0.2-0.1-0.4-0.2-0.7-0.2c0.2,0.2,0.4,0.3,0.6,0.5
                  c-0.3,0.3-1.7,0.1-1.7,0.2c-0.2,0.4,1.1,0,0.5,0.7c-0.8-0.4-1.6-0.8-2.4-1.2c0.4,0.3,1.2,1.1,1.7,1.4c-1-0.4-1-0.9-1.3-0.3
                  c0.2-0.4-3.7-2.2-3.2-4.3c0.5-1.7,2.8-1.3,4-1c-0.1-0.2,1.1,2.4,1.1,0.6c0,0,0.6,0.7,0.1,0.5C151.5,68.6,151.3,69,151.8,69.2z"
              />

              <linearGradient
                id="SVGID_54_"
                gradientUnits="userSpaceOnUse"
                x1="147.1"
                y1="913.6803"
                x2="147.1"
                y2="932.313"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st60"
                fill={this.getColor("scotland")}
                d="M151.5,79.2c-0.3,0.4-3.9,5.1-3.9,4.1c0-0.2,0.6,0.1,0.1-0.4c0.2,0.2,0.4,0.3,0.6,0.4
                  c0.2-0.2-2.8-1-2.6-1.2c-1.3,0.9,2.4,1.3,1.6,1c1.2,0,3.9,6.6-0.4,3.9c-0.1,0.1,0.4,0.7,0.4,0.7c-0.2,0.1-0.2,0.2,0.1,0.3
                  c-0.8-0.1-0.4-0.6-1.4-0.6c0,0.2-0.1,0.3-0.3,0.3c1.3,0.4,2.9,1,3.6,2.3c-0.5-0.3,1.2-0.5,0.9,0.2c-1.1,2-3.1-1-4.7,0.6
                  c-2.8-1.7-1.2-6.4-2.8-8.8c2.4-1.4,2.1-14,5.2-8.2c0.5,0,1.5-0.4,1.7,0.6c0.1-0.2-1.4-0.1-1.4-0.1c0.5,0.3,2.2,0.8,2.2,1.6
                  c0-0.1-1.5,0.1-1.5,0.1c1.1,0.1,1.3,0.3,2.2,0c0.1,0-3.1,0.8-2.5,0.3c-0.7,0.5,2.4,1.4,2.3,1.3C150.6,79.2,151.3,78.9,151.5,79.2
                  z"
              />

              <linearGradient
                id="SVGID_55_"
                gradientUnits="userSpaceOnUse"
                x1="150.4594"
                y1="950.2034"
                x2="150.4594"
                y2="951.2641"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st61"
                fill={this.getColor("scotland")}
                d="M150.6,53.6c0.3,0.2,0.3,1.4-0.3,1C149.8,53.8,150.5,53.9,150.6,53.6z"
              />

              <linearGradient
                id="SVGID_56_"
                gradientUnits="userSpaceOnUse"
                x1="149.3681"
                y1="919.014"
                x2="149.3681"
                y2="919.1142"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st62"
                fill={this.getColor("scotland")}
                d="M149.5,85.7C149.1,85.9,149.2,85.8,149.5,85.7L149.5,85.7z"
              />

              <linearGradient
                id="SVGID_57_"
                gradientUnits="userSpaceOnUse"
                x1="147.2893"
                y1="910.7479"
                x2="147.2893"
                y2="913.0377"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st63"
                fill={this.getColor("scotland")}
                d="M148.1,91.8c-0.2,0.6-0.1,1.4-1.1,1.3c1,0,0.2,0.8,0.1,0.9c-0.2-0.3-0.9-1.9-0.5-2.2
                  C146.9,91.7,147.9,92,148.1,91.8z"
              />

              <linearGradient
                id="SVGID_58_"
                gradientUnits="userSpaceOnUse"
                x1="146.7084"
                y1="937.9578"
                x2="146.7084"
                y2="941.2085"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st64"
                fill={this.getColor("scotland")}
                d="M147.6,64.4c0.2,0.4-0.2,1.7-0.3,2.4c-0.2-0.1-1.6-3-1.4-3.3c-0.7,0.9,1.5,0.1,1.2,0
                  C147,63.6,147.6,64.4,147.6,64.4z"
              />

              <linearGradient
                id="SVGID_59_"
                gradientUnits="userSpaceOnUse"
                x1="146.35"
                y1="947.7257"
                x2="146.35"
                y2="948.4592"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st65"
                fill={this.getColor("scotland")}
                d="M147.4,56.4c-0.9,0.3-0.9,1.4-2.1,0.2C145.8,56.5,146.8,56.5,147.4,56.4z"
              />

              <linearGradient
                id="SVGID_60_"
                gradientUnits="userSpaceOnUse"
                x1="145.85"
                y1="908.6596"
                x2="145.85"
                y2="909.2771"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st66"
                fill={this.getColor("scotland")}
                d="M146,95.6C145.6,96.4,145.6,96.3,146,95.6L146,95.6z"
              />

              <linearGradient
                id="SVGID_61_"
                gradientUnits="userSpaceOnUse"
                x1="145.1756"
                y1="908.2204"
                x2="145.1756"
                y2="908.6002"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st67"
                fill={this.getColor("scotland")}
                d="M145.7,96.4c0.3,0-0.8,0.5-1.1-0.1C145.2,96.2,145.5,96.4,145.7,96.4z"
              />

              <linearGradient
                id="SVGID_62_"
                gradientUnits="userSpaceOnUse"
                x1="145.2616"
                y1="941.4735"
                x2="145.2616"
                y2="942.5758"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st68"
                fill={this.getColor("scotland")}
                d="M145.9,63.3c0.4,0.3-2.2-0.6-1-1C145,62.2,145.8,63.2,145.9,63.3z"
              />

              <linearGradient
                id="SVGID_63_"
                gradientUnits="userSpaceOnUse"
                x1="144.3302"
                y1="907.3386"
                x2="144.3302"
                y2="907.7484"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st69"
                fill={this.getColor("scotland")}
                d="M144.4,97.1C144.6,97.2,143.9,97.9,144.4,97.1C144.5,97.1,144.4,97.1,144.4,97.1z"
              />

              <linearGradient
                id="SVGID_64_"
                gradientUnits="userSpaceOnUse"
                x1="144.05"
                y1="910.4274"
                x2="144.05"
                y2="911.436"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st70"
                fill={this.getColor("scotland")}
                d="M144.6,93.9c-0.6-0.1-0.5,0.9-1.1,0.1C143.9,92.6,143.9,93.9,144.6,93.9z"
              />

              <linearGradient
                id="SVGID_65_"
                gradientUnits="userSpaceOnUse"
                x1="140.9"
                y1="904.8753"
                x2="140.9"
                y2="911.4735"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st71"
                fill={this.getColor("scotland")}
                d="M144.1,97.9c-0.2-0.1-1,0.1-1.5-0.5c0.5,3-1.6,1.8-2.9,1.8c0.4,0-1.7,1.5-2,0c0.1-1.7,1.4-1.3,1.4-3.4
                  c0,0.8,2.6-1,2.6-2.4c0.6,1.9,0.7,0.4,0,2c0.1,0.1,1.7,1.5,1.7,1.5s-1-0.2-1.1-0.2C143,97.2,143.3,97.4,144.1,97.9z"
              />

              <linearGradient
                id="SVGID_66_"
                gradientUnits="userSpaceOnUse"
                x1="142.1099"
                y1="911.8018"
                x2="142.1099"
                y2="911.9883"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st72"
                fill={this.getColor("scotland")}
                d="M141.9,93C142.3,92.8,142.6,92.7,141.9,93L141.9,93z"
              />

              <linearGradient
                id="SVGID_67_"
                gradientUnits="userSpaceOnUse"
                x1="141.555"
                y1="902.6483"
                x2="141.555"
                y2="903.1008"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st73"
                fill={this.getColor("scotland")}
                d="M141.7,101.7C141.8,101.9,140.9,102.7,141.7,101.7C141.8,101.9,141.7,101.7,141.7,101.7z"
              />

              <linearGradient
                id="SVGID_68_"
                gradientUnits="userSpaceOnUse"
                x1="138.6534"
                y1="902.9367"
                x2="138.6534"
                y2="905.1113"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st74"
                fill={this.getColor("scotland")}
                d="M139.8,100.7c0.9,0.4-1.1,0.1-1.3,0.5c-0.3,0.8,1-0.1,1.1,0.4c0.1,0.7-3.2,0.3-2.1-1.9
                  C137.6,99.6,139.7,100.7,139.8,100.7z"
              />

              <linearGradient
                id="SVGID_69_"
                gradientUnits="userSpaceOnUse"
                x1="139.0156"
                y1="900.9945"
                x2="139.0156"
                y2="902.1812"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st75"
                fill={this.getColor("scotland")}
                d="M139.6,103.4c-0.5,0.1-0.7,1.4-1.2-0.7C139,102.4,139.8,103.4,139.6,103.4z"
              />

              <linearGradient
                id="SVGID_70_"
                gradientUnits="userSpaceOnUse"
                x1="138.9016"
                y1="939.5271"
                x2="138.9016"
                y2="940.8163"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st76"
                fill={this.getColor("scotland")}
                d="M139.2,64c0.4,0.2-0.4,1.3-0.5,1.3C138,65.2,139.3,64,139.2,64z"
              />

              <linearGradient
                id="SVGID_71_"
                gradientUnits="userSpaceOnUse"
                x1="137.5209"
                y1="940.3009"
                x2="137.5209"
                y2="940.792"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st77"
                fill={this.getColor("scotland")}
                d="M138.2,64.4c0.3,0.1-1.1,0.1-1.4,0.1C137.5,63.5,137.6,64.3,138.2,64.4z"
              />

              <linearGradient
                id="SVGID_72_"
                gradientUnits="userSpaceOnUse"
                x1="136.7216"
                y1="899.1199"
                x2="136.7216"
                y2="899.6972"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st78"
                fill={this.getColor("scotland")}
                d="M137.4,105.4c0.2,0-0.3,0.5-1.4,0.2C136.4,104.9,136.7,105.1,137.4,105.4z"
              />

              <linearGradient
                id="SVGID_73_"
                gradientUnits="userSpaceOnUse"
                x1="137"
                y1="900.3411"
                x2="137"
                y2="900.7234"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st79"
                fill={this.getColor("scotland")}
                d="M137,104.2C137,105,137,103.8,137,104.2C137,104.3,137,104.1,137,104.2z"
              />

              <linearGradient
                id="SVGID_74_"
                gradientUnits="userSpaceOnUse"
                x1="134.3"
                y1="895.2458"
                x2="134.3"
                y2="895.5976"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st80"
                fill={this.getColor("scotland")}
                d="M135,109.4c-0.4,0.1-0.5,0.3-1.4,0.2C134.2,108.9,134.3,109.6,135,109.4z"
              />

              <linearGradient
                id="SVGID_75_"
                gradientUnits="userSpaceOnUse"
                x1="134.2027"
                y1="895.9296"
                x2="134.2027"
                y2="897.7398"
                gradientTransform="matrix(1 0 0 -1 0 1004.85)"
              >
                <stop offset="0" style={{ stopColor: "#D80073" }} />
                <stop offset="1" style={{ stopColor: "#A80073" }} />
              </linearGradient>
              <path
                className="st81"
                fill={this.getColor("scotland")}
                d="M135,107.1c0.1-0.1-1.2,3.1-1.6,1.3C134,108,134.8,107.2,135,107.1z"
              />
            </g>
            <g>
              <path
                className="st4"
                d="M18.9,328.3c0,0.1,0,0.1,0.1,0.2C19,328.5,18.9,328.4,18.9,328.3z"
              />
              <path
                className="st4"
                d="M12.5,414.2c-0.2-0.5-0.5-0.7-1-0.7C11.5,413.9,12.4,413.9,12.5,414.2z"
              />
              <path
                className="st4"
                d="M62.8,346.3c-0.2,0-0.4,0-0.5,0C62.4,346.3,62.5,346.3,62.8,346.3z"
              />
              <path
                className="st4"
                d="M29.1,280.3l-0.1-0.1C29,280.4,29.1,280.4,29.1,280.3z"
              />
              <path
                className="st4"
                d="M28,275.1c0,0.2,0.1,0.3,0.3,0.3C28.1,275.3,28,275.1,28,275.1z"
              />
              <path
                className="st4"
                d="M181.5,352.7c-0.8-3.8-0.7-11.8-2.6-15.1c-0.9-1.6-5.2-2-5-4.6c0.1-1.7,3.6-1.9,4.2-2.3
                  c1.2-0.7,1.8,2.6,1.5-0.7c0,0-2.6-2.6-2.7-4.2c-0.1-1.7,2.3-3.7,2-5.5c-0.4-2.9-2.5-3.3-4.2-5.6c-3-4-1.3-6.5-2-10.8
                  c-0.8-4.9-4.7-3.8-6.1-8.2c-0.4-1.2,0.3-5.2,1.1-5.9c1.8-1.5,2.9,1.6,5.5,1.2c6-1,0.1-6.9-2.8-7.4c-3.1-0.6-6.6,3.2-9.8,3
                  c-3-0.2-2.2,2-4.4-0.9c-1.6-2.1,1.1-4.4-0.7-7c-1.4-2-2.9,0.1-4.1-1c-2.6-2.3-2.5-4.7-3.9-7.6c-1.6-3.2-4.1-7.1-7.8-5.8
                  c-6.5,2.2-4.1,9.4-5,12.5c0.2-0.6-3.1,5-2.9,4.9c-2.9,2.2-1.6,0.4-5.4,0.5c-0.4,0,0.9-1.7-0.6-1.8c-1.2-0.1-1.3,2.2-2.2,2.2
                  c-2.8-0.2-3.5-0.4-5.9-1.9c-0.7-0.4-1.3-2-2.2-2.5c-2-1.1-4.7-0.4-6.6-1.8c-1-0.7-2.9-5.7-4.4-7.3c-1.8-1.9-4.4-5.5-6.4-7.1
                  c-0.8-1-2.1-1.2-3.4-1.6c1,0.1,2,0,2.9,0.4c0.1-0.7,2.6-1.2,4-1.7c1.7-0.6,1.5-2.1,2-3c0.5-0.9,1.3-0.8,2.2-0.5s3,0,4,0
                  s2.2-2.4,3.9-3.6c1.8-1.2,1.7-1.2,1.5-2s-1.3,0.2-3.2,0c-1.8-0.2-2-1-3.5-2.2s-1.2-2.2,0.5-3.9c1.7-1.7,2.2-0.6,3.1,0.2
                  c0.8,0.8,1.3,0.7,2,0c0.7-0.7,2.8-2,4.4-2.1s2.4-0.2,2.5-1.4s0.3-1.8,2.1-3.3s1.8-3.8,1.8-5.3c-0.1-1.6,0.6-2.3,1.5-2.8
                  c0.9-0.4,0.1-2.3,0.3-4c0.2-1.7,1.8-2.7,2.8-3.1l3-2.2c0.7-3.3,3-5,5.7-6.9c3.7-2.7,6.6-2.7,3.5-5.7c-1.4-1.4-13-9.6-15.1-7.2
                  c-0.3,0.5,2.2,1.6,2.4,1.9c0.4,0.5-0.9,0.3-0.9,0.3s3.7,4.1,3.6,4.2c-1.4,0.4-3.5-1.3-2.7-1.5c-1.1,0.2-2.2,0.8-3.6,1
                  c-2.7,0.4-4.3-0.6-4.5,3.6c-0.2,3.3,2.7,5.4,2.2,9.3c-0.4,2.8,0.1,1.4-1.6,3.9c-0.6,0.8-4.6,3.2-4.6,3.9c0-1.9,5.4-6.1,1.1-4.9
                  c1.5-0.8,3-5,2.9-6.5c-0.3-1.5-2.3-2.1-2.6-3.1c-0.4-1.1,0.6-3-0.3-4c-1.4-1.4-1.8-2.3-4.1,0c-1.9,1.9-2.4,4.4,0.5,4.6
                  c0.3,0-0.8-2.7,0.3-2.9c0.8-0.1,2.1,7.1,0.8,8c0.2-0.9-3.9-6-3.6-5.3c0.3,0.7-2.8-4.4-0.8-4.4c-3.5,0.2-0.7,9-3.6,7.3
                  c-0.1,0,1.3-1.2,1-1.4c-0.5-0.2-0.7,0.2-1.1,0.3c-0.5-2.1-1.8-4.3-4-3.7c0.8-0.2-5.3,6.7-5.4,5.4c0-0.3,1.5-1.8,0.7-2
                  c-6.3-0.9-5.1,3.9-6.3,6.7c-1.2,1.5-2.9,1-3.8,3.6c-0.2,0.8-0.6,0.6-0.8,1.6c-0.2,0.6,1.9,2.5,2,2.4c-1.8,1.5-3.9,0.1-1.8,3.1
                  c1.3,1.8,2.4-0.7,4.3,0.9c0.5,0.6-2.5,0.8-2.6,1.1c-0.6,1.6,2.2,1.1,1.9,1.9c-0.5,0.8-8.4-1.3-8.4,0.9c0.1,0.6,5.7,3.8,5.4,4.1
                  c-0.2-0.1-4.6-2.1-4.8-1c-0.1,0.4,3.5,0.2,3,1.6c-3.1,1.3-5.7-1.5-9.5,0.7c-1.7,0.9-3.6,2.8-3.2,4.6l1.8,2.3
                  c3,1.8,8.7,1.6,10.1,2.2c0.8,0.2,2.2-1.8,3.4-1c0.6,0.9-3.8,3-2.6,3.7c0.6,0.4,3.2-3,3.9-3.3c1.4-0.6,1.9-1,2-1.1
                  c-0.1,0.2-0.3,0.7,0.2,1.6s4.5-1.5,5.4-1.5c1.2-0.1,2.8,2.8,0,1.2c0,0-4.5,7.6-5.6,8.8c0,0,0,0-0.1,0c-5.6,1.5-6,1.6-9.6,4.9
                  c-1.5,1.4-4.7,2.1-4,4c0.3,0.9,6.7,0,6.1,1.6c-0.5,0.5-2.4-0.5-2.5-0.5c-0.6,1.7,3.7,1.5,3.3,2.8c-0.5,1.4-4.1-0.8-5,0.9
                  c-0.3,0.6,3.6,3.1,3.3,3.5c-1.7,2.8-4.8-3.2-5.3-3.4c-2.5-1.2-4.4,0.6-6.4,0.4c-2.6-0.3-5.4-3.8-8.4-2.1c-1.7,1-3.1,7.4-3.9,9.3
                  c0.2-2.9-2.9-7.5-3.5-8.5c-2.5-3.8-4-2.5-8-2.4c-3.3,0-7.6-1.7-10.8-1.7c-8.2-0.1-2.2,1.3-1.3,4.1c-0.7-1.8-4.9,2-4.2,1.8
                  c0.3-0.1-0.2,0.2-0.9,0.5c0.1-0.2,0.2-0.5,0.1-0.9c0-0.6-2.9-2.5-3-2.6c-0.2-0.1-6.2,1.1-5,3.3c1.4,2.5,1,0.1,0.5,3
                  c-0.4,1.9-3,5.7,0.1,6.4c-0.4-0.1,1.5-5.6,1.6-6.1c0,1.9,1.3-2.4,1.2-2.3c0.4-0.4,0.8-0.4,1.1-0.2c0-0.2,0.2-0.5,0.5-0.9
                  c-0.1,0.1,0.9,0.7,1.8,0.8c-0.8,0.3-1.6,0.5-2,0.5c0.8,1.3,1,4.7,0.9,5c0.4,0.4,1.7,1.2,1.5,1.6c-0.4,0.5-5.2,3.7-0.9,3.6
                  c-0.2,0,4.5-4.1,4-3c-3.1,4.5-2.4,5.3,0.1,10.3c-0.3-0.8,1.9,0.9,1.3,2.6c0.3-0.7-3.9-4.7-5.3,0.3c-1.7,6.1,3.6,1.8,6.4,1.3
                  c3.6-0.6,6.2-0.3,7.3,2.1c0.4,0.9-1.6,1.3-1.6,1.9c-0.1,2.2,0.9,0.9,2.1,2.5c0.4,1.7-6.6,1.6-7.5,1.7c-4.3,0.4-6.5,0.3-6.9,5.2
                  c-0.3,3.1,2.6,3.9-0.2,6.1c-0.7,0.6-2.6-1.1-3.8-0.6c-3.1,1.2,0.8,1.1,0.1,2.4c-0.6,1.1-7-0.5-7.2,1.5c0.2,0.6,5.5,3.4,5.1,5.5
                  c-0.1,0.5-5.2,1.4-4.7,3c-0.2-1.1,7.6,2.3,6.2,2.3c1.1,0,4.2-0.8,5.6-1.6c-0.1,0.1-0.1,0.2-0.1,0.3c0.6,1.2,1.9-0.9,2.6-0.9
                  c0.1,0-0.3,1.1,1,1.3c-3.7,0-6.9,6.3-0.9,6.1c3.7-0.1,1.8-2,4.4-3.3c3.8-1.9,1.4-0.6,1.3,1.4c0.1,1.4,2.6-0.5,2.4-1.4
                  c0.2,1.3-0.7,2-0.6,2.9c0.1,3.3,0.5,6.9,2.1,3.3c0.6,0.9,2.3,2.3,2.5,2.5c2,0.7,5.7-0.5,7.9-0.6c1.6-0.1,14.1-1.9,14.2-1.2
                  c0,0.4-3.1,2.3-2.6,3c0.3,0.7,3.7,0.6,3.8,0.3c-1.1,2.4-0.7,2.5-0.8,4.8c0-2.1-3.8-1.3-5.1-1.3c0.8,0,2.2,0,2.2,0.7
                  s-4.6-0.8-5.3-0.1c-0.2,0.5,1.8,0.6,1.4,1c-1.9,0.9-3.1-0.6-4.4-0.2c-0.8,0.2-1.3-1.1-2.5-0.7c-0.8,0.3-0.9,2.7-1.5,3.4
                  c-1.2,1.4-7,9.1-6.6,10.8c0.6,0.8,4.3-0.6,4.6-0.1c0,0-3.8,6.6-4.1,7.2c-1.4,3.1-2.7,3.8-4.2,5.3c-1.1,1-13.7,13.2-15.1,12.1
                  c2,1.2,7.5-1,9-1.6c0,0,3.7-1.8,4.1-2.1c0.9-0.6,1.2-2.2,1.3-2.8c0.4,0.9,7,4.1,10.2,3.6c-0.5,0.3-0.8,0.7-0.7,0.9
                  c2.1,3,9.8-4.9,11.1-6.7c2.7-3.7,5.6-5.4,3.7,1c0.2-1.3,7.4,0.8,8.5,1.2c-2.1,0.8-2.2-0.1-4,0.4c-2.4,0.6-4,2-5.7,2.6
                  c-1.6,0.6-2.5,0-4,0.5c-2.2,0.7-5.4,2.4-7.7,2.7c-6.3,0.8-17-4.6-14.4,5.3c0-0.7-8.2,2.6-9.3,3.3c-5.4,3.6-0.1-0.5,0.9,2.8
                  c0.6,1.7-1.6,6.1-1.8,5.5c0.9,2.6,1.4,0.2,2.5,1.6c1,1.2,3.2-0.8,2.8,2c-0.1,1.1-3.6-0.5-3-0.5c-3.7,0.4-3.8,2-7.7-0.7
                  c0.5,0.4,0.1-5.1-1.4-3.1c-0.9,1.3,1.2,4.7-3.1,4.5c-3.2-0.1,0.5-2.8-2.2-3.1c-4.9-0.6-6.5,4.7-7.8,7.3c0,0.9-1.6-1.7-1.6-1.6
                  c0.2,0.4-4.9,4.1-1.1,6.7c0.8,0.5,3.9-1.8,5.6-1.8c0-0.1,0-0.1,0.1-0.2c0.5-0.5,4.5,1,5.2,1c1.4,0,2.6-0.9,3.9-0.8
                  c1.2,0,5,1.4,4.9,1.4c1.3-0.7-1.6-2.2,1.6-2.5c0.9-0.1,5.1,0.6,5.2,1.5c0,0.3-5.5,1.4-5.1,0.2c-0.1,0.3-1.3,4-1.1,4
                  c-1.2-0.5-0.9-0.1-1.2-1.4c0,3.6-12,4.8-14,8.1c0.2-0.6,2,3.3,2,3.4c0-0.1-5.4,1.9-5.5,2.3c0.2-0.4,1.7,5.5,1.7,5.5
                  c1.5,0.8,3.7-4.4,5.6-3.8c3.6,1.1-0.8,4.5-0.8,4.5c0-0.3,4.7,2.4,4,2.7c2.1-1.1,3.7-1.9,5.8-2.7c1.5-0.6,4.9-1.2,4.8-1.1
                  c2.8-1.1,7.4-5.6,11.4-4.3c-4,0-7.6,4.8-8.4,6.6c0.3-0.2-4.7,1.8-4.3,2c-0.3-0.3,0.2-1,0.1-1.3c-0.1-0.2-4.6,2.1-4.3,2.8
                  c0.4,0.4,1.3-0.1,1.5,0.4c0,3.1-4.4,1.1-5.5,2.3c0.1-0.2,0.3,2.5-0.2,3.3c-0.7,1.3-2.8-0.5-2.5,2.3c1.1,1.7,10.5-3.7,11.5-4.1
                  c6.3-2.1,10.2-0.5,13.1-6.5c0,0.8,4.5,3.7,4.3,2.1c0.5,3.5-2.9,3.2-5.2,4.3c-1.6,0.8-11.1,5.2-11,6.8c0.2,0.8,12.1-4.6,12.1-4.2
                  c-0.4,3.5-11.8,6-11.8,8.7c1.2,5.4,5.5-1.3,7.3-2c1.4-0.6,1,1.7,2.2,1.4c0.7-0.1,2-1.8,2.4-1.9c-0.3,0.1,5.4-1.8,4.4-2.2
                  c2.9,0.6-0.3,2.9,1.5,4.2c2.1,1.5,0.9,0.5,3.2,0.9c2.9,0.5,3.8-1.4,5.7-2.8c2.4-1.4,2.3,0.7,2.1-2.8c0.1-1.5,9,2.2,8.1,2.5
                  c1.3-0.5,2-3.4,3.4-3.9c1.4-0.5,4.4,1.2,4.2,1.3c2.7-0.9,1.3-2.5,2.6-3.2c3.2-1.7,3,0,5.9,0.8c-0.3,0,0.6-6.1,0.7-6.1
                  c-0.3,1,10.9-0.1,9.9-6.6c-0.4-2.8-5.1-4.4-4.1-5.5c-0.2,0.6,8.3,0.1,8.2,0.1c-0.4-0.1-7,7.9,2.5,5c4.1-1.3,2.2-1.6,4.9-3.6
                  c0.9-0.6,2.8,0,3.5-0.6c2-1.9-0.7-0.2-0.4-2.4c0-2.2,18-5.7,14.9-9.9c-1.2-0.9-1.8-1.2-2.1-1.2c0.2,0,0.5-0.1,1-0.6
                  c0.9-0.9,4.3-2.4,5.9-2.9c3.6-1.1,10.2,0.4,12.5-1.7c0.4-0.4-0.5-1.2-0.1-1.4c2.6-1.4,0.6,2.3,0.6,2.4c0,0.6,6.7-0.5,5.9-3.1
                  c-0.8-1.5-0.8-4.6-1.7-6.6c0.6,1.3,2.8,3.3,3.1,4.7c0.1,0.7-0.3,6.1-0.8,5.7c3.6,1.9,4.4-9.9,6.8-8.9c-0.1,0.2-1.3,3.2-1.1,3.3
                  c1.2,0.4,3.5-0.4,5-0.2c1.7,0.3,2.2,2.1,3.6,2.1c0.9,0,2.6-1.6,3.5-1.7c1.5-0.2,1,0.4,1,0.4c0.6,0.1,2.7-1,3.1-0.7
                  c4.1,5,0.3-8,0.1-8c-0.7,0.1-0.2,1.5-0.8,1.5c-3.2-0.7-0.9-3.6,1.7-3.2c-0.9,0,1.8-6.2,2.2-7c1-1.9,3.2-3.7,3.7-5.9
                  c0.5-2.1-0.7-3.7-0.6-5.6c0.4-4.7,2.6-10.1,4.3-14.5C181.8,360,182.8,359.3,181.5,352.7z M36.6,272.4c0,0.1,0,0.2,0,0.3
                  C36.6,272.6,36.6,272.5,36.6,272.4z M31.3,328.4c0-0.1,0.1-0.1,0.1-0.2C31.4,328.3,31.4,328.4,31.3,328.4z"
              />
              <path
                className="st4"
                fill={this.getColor("scotland")}
                d="M93.9,431.9c-0.4-0.7-1.7,3.2,1,1.5C96.9,431.5,94.4,432.6,93.9,431.9z"
              />
              <path
                className="st4"
                fill={this.getColor("scotland")}
                d="M42.5,460.4c1.6,0.8,2-1.4,2.4-1.9C44.6,458.5,41.8,459.9,42.5,460.4z"
              />
              <path
                className="st4"
                fill={this.getColor("scotland")}
                d="M46.4,457.9c0,1.3,0.6-0.3,0.9-1C46.9,457.4,46.7,457.1,46.4,457.9z"
              />
              <path
                className="st4"
                fill={this.getColor("scotland")}
                d="M47.3,456.9C47.4,456.6,47.4,456.7,47.3,456.9L47.3,456.9z"
              />
              <path
                className="st4"
                fill={this.getColor("scotland")}
                d="M32,446c0,0.7-6.2-0.6-5.3,1.5c0.6,0.6,3,0.2,3.7-0.1C31,447.2,31.8,446.7,32,446z"
              />
              <path
                className="st4"
                fill={this.getColor("scotland")}
                d="M13.9,449.5c1.4,0.5,2.2-1.5,2.4-1.7C16.3,447.8,12.7,449,13.9,449.5z"
              />
              <path
                className="st4"
                fill={this.getColor("scotland")}
                d="M3.6,414.3C3.7,414.3,3.5,414.2,3.6,414.3L3.6,414.3z"
              />
              <path
                className="st4"
                fill={this.getColor("scotland")}
                d="M0.8,416.5c0.4,0.4,3.8-1.4,2.8-2.2C3.4,414.2,0.2,415.9,0.8,416.5z"
              />
              <path
                className="st4"
                fill={this.getColor("scotland")}
                d="M43.6,353.3C44.3,353.8,45.5,351.6,43.6,353.3L43.6,353.3z"
              />
              <path
                className="st4"
                fill={this.getColor("scotland")}
                d="M42.4,350c-1,0.1-2.6,1.8-0.8,1.8C42.2,351.8,43,350,42.4,350z"
              />
              <path
                className="st4"
                fill={this.getColor("scotland")}
                d="M32.4,346.3c0.1-0.1,0.2-0.1,0.3-0.1C32.6,346.2,32.5,346.2,32.4,346.3z"
              />
              <path
                className="st4"
                fill={this.getColor("scotland")}
                d="M32.7,346.2c1.7-0.2,5.9,5.7,7.6,3.2c0.4-0.5-4.2-2.6-5.1-2.9C34.7,346.3,33.5,346,32.7,346.2z"
              />
              <path
                className="st4"
                fill={this.getColor("scotland")}
                d="M37.1,339.1C37.4,345.5,42.3,334.8,37.1,339.1L37.1,339.1z"
              />
              <path
                className="st4"
                fill={this.getColor("scotland")}
                d="M36,340.2c-0.5,0.9,0.5,0.5,0.6,0.7C37,340.5,36.6,339.4,36,340.2z"
              />
              <path
                className="st4"
                fill={this.getColor("scotland")}
                d="M38.2,337.4c0.5-0.1,1.2-0.3,1.5-0.7C40.9,335.3,35.1,337.5,38.2,337.4z"
              />
              <path
                className="st4"
                fill={this.getColor("scotland")}
                d="M17,315.3c2.1,1,3.1-1.9-0.6-1.1C15.5,314.7,17,315.4,17,315.3z"
              />
              <path
                className="st4"
                fill={this.getColor("scotland")}
                d="M22.1,309c0,0-1.3,0.9-0.5,1.1C22.5,310.2,23.4,308.7,22.1,309z"
              />
              <path
                className="st4"
                fill={this.getColor("scotland")}
                d="M24.8,303.6c0.3,0.7,4.9,0.4,3.2-1.5C27.2,301.3,24.5,302.5,24.8,303.6z"
              />
              <path
                className="st4"
                fill={this.getColor("scotland")}
                d="M29,292.8c-0.2-2.8,0.1-3.8-3.9-4.4c-2.6-0.4-7.3,3.1-8.1,3c1.4,0.2,4,0.1,5.2,0.7c2.7,1.2,1,1.8,2.4,2.9
                  C29.1,298.6,29.2,296.7,29,292.8z"
              />
              <path
                className="st4"
                fill={this.getColor("scotland")}
                d="M83.4,226.4C88.5,227.8,82.5,219.9,83.4,226.4L83.4,226.4z"
              />
            </g>
            <g>
              <path
                className="st82"
                d="M171.2,206.4c0.8,0.1,1.2-2.6-0.1-2.7c-0.6,0-3.8,0.5-2.7,0.8C169.9,205,170.6,206.2,171.2,206.4z"
              />
              <path
                className="st82"
                d="M74.1,246.3c0,0.2,0.1,0.3,0.1,0.5c0.3,0.8,0.9,1.3,1.6,1.8L74.1,246.3z"
              />
              <path
                id="ukSvg-northernIreland"
                fill={this.getColor("northernIreland")}
                className="st82"
                d="M195.3,243.7c-5.1-2.3-8.2,4.6-11.4,5.4c-5.2,0.8,7.4-10.1,7.1-9.5c1.3-2.4,0-5.1-1.8-6.8
                  c-2.4-2.4-0.6,1.8,0.5,3.6c-0.8-0.5-2.3-0.7-2.6-1.1c-0.9-1.1-0.8-3.2-1.6-4.7c-1.2-2.4-5-4.6-5.8-6.3c-0.5-1.1,1.2-2.7,0.4-4.2
                  c-0.6-1.3-3-0.3-3.1-0.8c-0.9-2.6,1.4-5.4-0.3-8c-2.4-3.7-4-1.1-7.2-1.6c-2.8-0.4-5.5-2.7-8.6-2.1c-2.4,0.4-5.3,2.5-7.9,3.4
                  c-2.7,0.9-4.8,0.9-7.6,1.6c-1.1,0.3-1.6,0.2-1.7,0c-0.1,0.3-0.4,0.7-1,1.5c-0.9,1.2-1,3.5-1.8,4.6c-1.5,1.9-1,2-3.5,2.8
                  c-0.8,0.2-4.2-2.3-5.1-0.6c0-0.3,0.1-0.7,0.2-1l-3,2.2c-0.9,0.4-2.5,1.4-2.8,3.1c-0.2,1.7,0.6,3.6-0.3,4s-1.6,1.2-1.5,2.8
                  s0,3.8-1.8,5.3c-1.8,1.5-2,2.1-2.1,3.3s-0.8,1.3-2.5,1.4s-3.8,1.4-4.4,2.1c-0.7,0.7-1.2,0.8-2,0s-1.3-2-3.1-0.2s-2,2.8-0.5,3.9
                  s1.7,2,3.5,2.2s3-0.8,3.2,0c0.2,0.8,0.2,0.8-1.5,2c-1.8,1.2-2.9,3.6-3.9,3.6s-3.1,0.2-4,0c-0.9-0.2-1.8-0.4-2.2,0.5
                  c-0.4,0.9-0.3,2.4-2,3c-1.5,0.5-3.9,1-4,1.7c0.1,0.1,0.3,0.1,0.4,0.2c1.7,1.3,4.6,5.6,6.4,7.4c0.5,0.5,2.9,3.3,3.4,3.9
                  c0.8,1.2,0.9,3.8,1.9,4.5c1.7,1.2,4.7,1.1,6.5,2.1c1.5,0.8,2.1,2.9,4,3.5c3.2,0.9,3.9-2,5.7-1.8c2.4,0.2,2.4,4.1,6.5,0.8
                  c-0.2,0.1,2.6-4.9,2.5-4.4c0.1-0.4,1.1-0.8,1.4-1.7c0.5-1.5-1.8-2.4-1.6-3.5c0.9-5.1,6-9.6,10.4-5.7c3.5,3,2.7,8.5,6.3,11.5
                  c1.5,1.2,2.9-0.7,4.2,1c2.1,2.7-0.7,4.7,0.9,7.2c3.4,5.1,14.7-5.2,21.3-0.1c0.3,0.9-1.7,2.5,1.8,1.6c1-0.3,3.9-2.8,4.5-3.7
                  c4-5.4-0.4-8.9,6.9-10.8c2.8-0.7,5.7,1.2,6.8-4c1.6-7.2-6.1-3.5-6-2.9c-0.2-1.3,2.5-4.1,2.5-6.3c0-1.6-1.4-2.6-1.7-3.4
                  c-1-3.1-1.5-7.2,2.9-2.2c1.3,1.4,1,13.3,3.9,12.6c1.1-0.6,1.8-8.8,2.5-10.3C199.7,253.1,199.3,245.5,195.3,243.7z"
              />
              <path
                className="st82"
                d="M143.7,212.7C144,212,143.4,212.4,143.7,212.7L143.7,212.7z"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

UKMapSVGContainer.propTypes = {
  className: PropTypes.string,
  currentLocation: PropTypes.string,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseMove: PropTypes.func,
  data: PropTypes.object,
};
