import "./styles.scss";

import PropTypes from "prop-types";
import React from "react";
import { YEARS } from "../../shared/constants";
import sharedData from "../../../../../data/shared_data";

const LINKS = sharedData.download_links.slice(0).reverse();
const IGNORE_YEARS = [];
const REVERSE_YEARS = YEARS.slice(0)
  .filter((year) => IGNORE_YEARS.indexOf(year) === -1)
  .reverse();

export default class DownloadLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = { visibility: false };
  }

  visibility(show) {
    this.setState({
      visible: show,
    });
  }

  render() {
    const staticLink = this.props.static ? "SharingLinks--static" : "";
    const className = `DownloadLinks ${staticLink}`;
    const menu = this.state.visible ?
      "DownloadLinks-hoverMenu DownloadLinks-hoverMenu--visible" :
      "DownloadLinks-hoverMenu";

    return (
      <div className={className}>
        <div className={menu}>
          { REVERSE_YEARS.map((year, key) =>
            <a
              className="DownloadLink"
              href={LINKS[key]}
              target="_blank"
              key={key}>{year} Report</a>
          )}
        </div>
        <div
          onMouseOver={ this.visibility.bind(this, true) }
          onMouseLeave={ this.visibility.bind(this, false) }>
          {this.props.children}
        </div>
      </div>
    );
  }
}

DownloadLinks.propTypes = {
  "static": PropTypes.bool,
  children: PropTypes.object,
};
