/* eslint-disable */
const locations = {
  summary: {
    displayTitle: "Intro",
    color: "#5093D5",
    summaryTitle: "Our economic impact in the U.S.",
    summary:
      "In the U.S., the group has seven operational and development sites, and two further facilities at the deep-water ports in three of its primary States: Alabama, Louisiana, and Mississippi. Drax also has an impact across other states in the U.S., but this impact is smaller, so this report does not provide a deep dive into these areas. This impact is however considered in the overall assessment of the U.S.",
    facebookString:
      "{jobsSupported} #jobs are supported by Drax in the U.S.. The #energy company has an {economicImpact} economic impact",
    twitterString:
      "{jobsSupported} #jobs supported & {economicImpact} economic impact by Drax in the U.S. via @DraxGroup @OxfordEconomics",
    linkedinString:
      "{jobsSupported} jobs are supported by Drax in the U.S.. The #energy company has an {economicImpact} economic impact",
    jobsDirect: [0, 0, 0, 0, 351],
    jobsIndirect: [0, 0, 0, 0, 4600],
    jobsInduced: [0, 0, 0, 0, 2400],
    jobsTotal: [0, 0, 0, 0, 7400],
    economicDirect: [0, 0, 0, 0, 82],
    economicIndirect: [0, 0, 0, 0, 580],
    economicInduced: [0, 0, 0, 0, 334],
    economicTotal: [0, 0, 0, 0, 997],
    taxesDirect: [0, 0, 0, 0, 8],
    taxesIndirect: [0, 0, 0, 0, 112],
    taxesInduced: [0, 0, 0, 0, 59],
    taxesTotal: [0, 0, 0, 0, 180],
    sharingLink: "#/Summary-content",
    sharingImage: "facebook_images/share-generic.jpg",
    downloadLink: [
      "/public/Oxford_Economics-Economic_impact_of_Drax_in_the_USA_2015.pdf",
      "/public/Oxford_Economics-Economic_impact_of_Drax_in_the_USA_2016.pdf",
      "/public/Oxford_Economics-Economic_impact_of_Drax_in_the_USA_2017.pdf",
    ],
  },
  alabama: {
    displayTitle: "Alabama",
    color: "#A50070",
    jobsDirect: [0, 0, 0, 0, 84],
    jobsIndirect: [0, 0, 0, 0, 1400],
    jobsInduced: [0, 0, 0, 0, 560],
    jobsTotal: [0, 0, 0, 0, 2040],
    economicDirect: [0, 0, 0, 0, 1],
    economicIndirect: [0, 0, 0, 0, 107],
    economicInduced: [0, 0, 0, 0, 45],
    economicTotal: [0, 0, 0, 0, 154],
    facebookString:
      "{jobsInduced} #jobs are supported by Drax in #Alabama, where the #energy company's economic impact is {economicTotal}",
    twitterString:
      "{jobsTotal} #jobs supported & {economicTotal} economic impact by Drax in #Alabama via @DraxGroup @OxfordEconomics",
    linkedinString:
      "{jobsInduced} jobs are supported by Drax in Alabama, where the energy company's economic impact is {economicTotal}",
    sharingLink: "#/alabama",
    sharingImage: "facebook_images/share-scotland.jpg",
    downloadLink:
      "/public/Oxford_Economics-Economic_impact_of_Drax_in_the_USA.pdf",
  },
  louisiana: {
    displayTitle: "Louisiana",
    color: "#733B99",
    jobsDirect: [0, 0, 0, 0, 165],
    jobsIndirect: [0, 0, 0, 0, 1650],
    jobsInduced: [0, 0, 0, 0, 890],
    jobsTotal: [0, 0, 0, 0, 2700],
    economicDirect: [0, 0, 0, 0, 70],
    economicIndirect: [0, 0, 0, 0, 72],
    economicInduced: [0, 0, 0, 0, 66],
    economicTotal: [0, 0, 0, 0, 207],
    facebookString:
      "{jobsTotal} #jobs are supported by Drax in the #Louisiana of England, where the #energy company's economic impact is {economicTotal}",
    twitterString:
      "{jobsTotal} #jobs supported & {economicTotal} economic impact by Drax in #Louisiana via @DraxGroup @OxfordEconomics #NE",
    linkedinString:
      "{jobsTotal} jobs are supported by Drax in Louisiana, where the energy company's economic impact is {economicTotal}",
    sharingLink: "#/louisiana",
    sharingImage: "facebook_images/share-north-east.jpg",
    downloadLink:
      "/public/Oxford_Economics-Economic_impact_of_Drax_in_the_USA.pdf",
  },
  mississippi: {
    displayTitle: "Mississippi",
    color: "#5758AC",
    jobsDirect: [0, 0, 0, 0, 71],
    jobsIndirect: [0, 0, 0, 0, 810],
    jobsInduced: [0, 0, 0, 0, 360],
    jobsTotal: [0, 0, 0, 0, 1240],
    economicDirect: [0, 0, 0, 0, 9],
    economicIndirect: [0, 0, 0, 0, 12],
    economicInduced: [0, 0, 0, 0, 27],
    economicTotal: [0, 0, 0, 0, 48],
    facebookString:
      "{jobsTotal} #jobs are supported by Drax in the #Mississippi of England, where the #energy company's economic impact is {economicTotal}",
    twitterString:
      "{jobsTotal} #jobs supported & {economicTotal} economic impact by Drax in #Mississippi via @DraxGroup @OxfordEconomics #NW",
    linkedinString:
      "{jobsTotal} jobs are supported by Drax in Mississippi, where the energy company's economic impact is {economicTotal}",
    sharingLink: "#/mississippi",
    sharingImage: "facebook_images/share-north-west.jpg",
    downloadLink:
      "/public/Oxford_Economics-Economic_impact_of_Drax_in_the_USA.pdf",
  },
  restOfUs: {
    displayTitle: "Rest of U.S.",
    jobsTotal: [0, 0, 0, 0, 1500],
  },
};

/* eslint-enable */

export default locations;
