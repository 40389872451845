import "./styles.scss";

import Close from "./close.inline.svg";
import PropTypes from "prop-types";
import React from "react";
import { SharingLinks } from "../";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import infoPaneActions from "../infopane/info_pane_actions.js";

/* eslint-disable max-len */

export default class MobileDropdownView extends React.Component {
  close() {
    this.props.mobileShareChanged({ open: false });
  }

  render() {
    const classNames = `MobileDropdown ${this.props.active ? "active" : ""}`;
    return (
      <div className={classNames}>
        <Close className="MobileDropdown-close" onClick={this.close.bind(this)} />
        <SharingLinks static="true" {...this.props.sharingData}/>
      </div>
    );
  }
}

MobileDropdownView.propTypes = {
  // just silencing an error caused by very bad spagetti code...
  mobileShareChanged: PropTypes.any,
  sharingData: PropTypes.object,
  active: PropTypes.bool,
};

function mapStateToProps(state) { // state
  return { ...state };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...infoPaneActions,
  }, dispatch);
}

export const MobileDropdownContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MobileDropdownView);
