/* eslint-disable */
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import shareLinkActions from '../sharing_links/share_links_actions';

import './styles.scss';

export default class LinkPopup extends React.Component {
  
  close() {
    this.props.hideCopyPopup();
  }
  
  render() {

    if(this.props.copyPopup){

      setTimeout(() => {
        document.querySelector('.link-popup__container input').select();
      }, 50);

    }

    return (
      <div className={this.props.copyPopup ? 'link-popup link-popup--show' : 'link-popup'}>
        <div className="link-popup__overlay" onClick={this.close.bind(this)} />
        <div className="link-popup__container">
          <input type="text" defaultValue={this.props.copyUrl} />
        </div>
      </div>
    )
  }
}

function stateToProps(state) { // state
  return state.sharingLinks;
}

function dispatchToProps(dispatch) {
  return bindActionCreators({
    ...shareLinkActions,
  }, dispatch);
}

export const LinkPopupContainer = connect(
  stateToProps,
  dispatchToProps
)(LinkPopup);

/* eslint-enable */
