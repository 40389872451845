import { Map } from "immutable";

import { dataMap } from "data";

const init = new Map({
  map_hover: {
    value: false,
    props: {},
  },
});

function validateMapHover(state, action) {
  const props = { props: action.props };
  const country = action.props.country;
  const AreaData = dataMap[country];

  return AreaData.hasOwnProperty(action.props.location) ?
    state.set("map_hover", { ...props,
      value: true,
      data: AreaData[action.props.location],
      color: AreaData[action.props.location].color,
      title: AreaData[action.props.location].displayTitle,
      jobsSupported: AreaData[action.props.location].jobsSupported,
      economicImpact: AreaData[action.props.location].economicImpact,
    }) :
    state.set("map_hover", { ...state.get("map_hover"), value: false });
}

export default (state = init, action) => {
  switch (action.type) {
    case "MAP_AREA_HOVER":
      return validateMapHover(state, action);
    default:
      return state;
  }
};
