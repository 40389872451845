"use strict";

import React from "react";
const directScroller = require('../mixins/direct-scroller');
import Helpers from "../mixins/Helpers";

const DirectLink = function (props) {
  return React.DOM.a(props, props.children);
};

export default Helpers.Scroll(DirectLink, directScroller);
