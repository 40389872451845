import "./styles.scss";

import DraxLogo from "public/assets/svg/drax_logo.inline.svg";
import Link from "../../utilities/scroll-spy/components/Link";
import MiniUkInline from "public/assets/svg/mini-uk.inline.svg";
import OxfordLogo from "public/assets/svg/oxford-logo.inline.svg";
import React from "react";
import ScrollDiagram from "public/assets/svg/intro-scroll.inline.svg";
import { CountrySelector } from "./country_selector";
export default class IntroView extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <div className="IntroElement IntroPage">
        <div className="IntroElement-contentContainerWrapper">
          <section className="IntroElement-contentContainer">
            <header>
              <a
                className="IntroElement-logo"
                target="_blank"
                href="http://www.drax.com/"
              >
                <DraxLogo />
              </a>
              <a
                className="IntroElement-oxfordLogo"
                target="_blank"
                href="http://www.oxfordeconomics.com/"
              >
                <OxfordLogo />
              </a>
            </header>
            <p className="IntroElement-introParagraph">
              Drax is the global leader in the production, generation, and
              supply of renewable power from sustainably sourced biomass, and a
              pioneer in carbon removal technology. Its purpose is to enable a
              zero carbon, lower cost energy future.
              <br />
              <br />
              <b>This website presents</b> the latest findings of reports
              produced by Oxford Economics that analyses Drax's economic
              footprint across the United Kingdom, United States and Canada.
            </p>
            <div className="TotalImpact">
              <p>
                <b>Total Drax economic impact in 2021:</b>
              </p>
              <ul className="StatGrid">
                <li>
                  35,600<p>jobs supported</p>
                </li>
                <li>
                  £3.1 bn<p>contribution to GDP</p>
                </li>
                <li>
                  £689 mn<p>tax contribution</p>
                </li>
              </ul>
            </div>
            <div className="IntroElement-introColumnWrapper">
              <div className="IntroElement-introColumn">
                <ScrollDiagram className="IntroElement-scrollDiagram" />
                <p>
                  Scroll the page to see Drax’s impact on different regions.
                </p>
              </div>
              <div className="IntroElement-introColumn">
                <MiniUkInline />
                <p>
                  Hover over a region on the map to jump to a detailed report on
                  that area.
                </p>
              </div>
            </div>
            <Link
              activeClass="active"
              to="countrySelector"
              smooth
              offset={window.innerWidth > 600 ? 0 : 90}
              duration={500}
              className="IntroElement-introButton"
            >
              Select a country
            </Link>
          </section>
          <CountrySelector />
        </div>
      </div>
    );
  }
}
