/* eslint-disable */
const locations = {
  summary: {
    displayTitle: "Intro",
    color: "#5093D5",
    summaryTitle: "Our economic impact in the U.K.",

    summary:
      "Drax Group generates 11% of the U.K.’s renewable electricity. It also makes an important economic contribution to the country. Through the people we employ, the supply chain we support and the goods and services we purchase, we help power the U.K. economy.",
    facebookString:
      "{jobsSupported} #jobs are supported by Drax in the U.K.. The #energy company has an {economicImpact} economic impact",
    twitterString:
      "{jobsSupported} #jobs supported & {economicImpact} economic impact by Drax in the U.K. via @DraxGroup @OxfordEconomics",
    linkedinString:
      "{jobsSupported} jobs are supported by Drax in the U.K.. The #energy company has an {economicImpact} economic impact",
    jobsDirect: [1298, 2011, 2315, 2724, 2427],
    jobsIndirect: [7700, 10300, 9300, 9500, 9500],
    jobsInduced: [5150, 6100, 5800, 7000, 5800],
    jobsTotal: [14150, 18500, 17500, 19200, 17800],
    economicDirect: [284.1, 301, 355, 549, 510],
    economicIndirect: [649.8, 908, 921, 1174, 819],
    economicInduced: [310.8, 464, 373, 477, 437],
    economicTotal: [1244.7, 1674, 1648, 2201, 1765],
    taxesDirect: [0, 0, 0, 0, 88],
    taxesIndirect: [0, 0, 0, 0, 213],
    taxesInduced: [0, 0, 0, 0, 97],
    taxesTotal: [0, 0, 0, 0, 398],
    sharingLink: "#/Summary-content",
    sharingImage: "facebook_images/share-generic.jpg",
    downloadLink: [
      "/public/Oxford_Economics-Economic_impact_of_Drax_in_the_UK_2015.pdf",
      "/public/Oxford_Economics-Economic_impact_of_Drax_in_the_UK_2016.pdf",
      "/public/Oxford_Economics-Economic_impact_of_Drax_in_the_UK_2017.pdf",
    ],
  },
  scotland: {
    displayTitle: "Scotland",
    introSentence: "Home to Drax’s hydro assets including Cruachan",
    color: "#A50070",
    jobsDirect: [0, 0, 0, 148, 152],
    jobsIndirect: [1250, 550, 450, 600, 750],
    jobsInduced: [450, 350, 250, 450, 350],
    jobsTotal: [1700, 900, 700, 1200, 1250],
    economicDirect: [0, 0, 0, 68, 20],
    economicIndirect: [90.2, 53.1, 51.1, 114, 86],
    economicInduced: [30.2, 23, 17.1, 29, 27],
    economicTotal: [120.4, 76.1, 68.2, 211, 133],
    facebookString:
      "{jobsInduced} #jobs are supported by Drax in #Scotland, where the #energy company's economic impact is {economicTotal}",
    twitterString:
      "{jobsTotal} #jobs supported & {economicTotal} economic impact by Drax in #Scotland via @DraxGroup @OxfordEconomics",
    linkedinString:
      "{jobsInduced} jobs are supported by Drax in Scotland, where the energy company's economic impact is {economicTotal}",
    sharingLink: "#/scotland",
    sharingImage: "facebook_images/share-scotland.jpg",
    downloadLink:
      "/public/Oxford_Economics-Economic_impact_of_Drax_in_the_UK.pdf",
  },
  northEast: {
    displayTitle: "North East",
    color: "#733B99",
    jobsDirect: [0, 0, 0, 0, 0],
    jobsIndirect: [750, 48, 700, 650, 620],
    jobsInduced: [350, 280, 400, 700, 350],
    jobsTotal: [1100, 760, 1100, 1300, 970],
    economicDirect: [0, 0, 0, 0, 2],
    economicIndirect: [61.5, 36.5, 51.9, 52, 62],
    economicInduced: [20.7, 20.3, 25.3, 43, 26],
    economicTotal: [82.2, 56.8, 77.2, 95, 90],
    facebookString:
      "{jobsTotal} #jobs are supported by Drax in the #NorthEast of England, where the #energy company's economic impact is {economicTotal}",
    twitterString:
      "{jobsTotal} #jobs supported & {economicTotal} economic impact by Drax in #NorthEast via @DraxGroup @OxfordEconomics #NE",
    linkedinString:
      "{jobsTotal} jobs are supported by Drax in the North East of England, where the energy company's economic impact is {economicTotal}",
    sharingLink: "#/north-east",
    sharingImage: "facebook_images/share-north-east.jpg",
    downloadLink:
      "/public/Oxford_Economics-Economic_impact_of_Drax_in_the_U.K.pdf",
  },
  northWest: {
    displayTitle: "North West",
    color: "#5758AC",
    jobsDirect: [25, 26, 0, 11, 9],
    jobsIndirect: [500, 1050, 900, 650, 830],
    jobsInduced: [350, 600, 500, 500, 450],
    jobsTotal: [850, 1650, 1400, 1100, 1300],
    economicDirect: [0.3, 0.9, 0, 5, 2],
    economicIndirect: [28.3, 58.1, 67.9, 44, 54],
    economicInduced: [22.1, 42.3, 32.9, 35, 35],
    economicTotal: [50.8, 101.3, 100.8, 85, 90],
    facebookString:
      "{jobsTotal} #jobs are supported by Drax in the #NorthWest of England, where the #energy company's economic impact is {economicTotal}",
    twitterString:
      "{jobsTotal} #jobs supported & {economicTotal} economic impact by Drax in #NorthWest via @DraxGroup @OxfordEconomics #NW",
    linkedinString:
      "{jobsTotal} jobs are supported by Drax in the North West of England, where the energy company's economic impact is {economicTotal}",
    sharingLink: "#/north-west",
    sharingImage: "facebook_images/share-north-west.jpg",
    downloadLink:
      "/public/Oxford_Economics-Economic_impact_of_Drax_in_the_UK.pdf",
  },
  yorkshireAndHumber: {
    displayTitle: "Yorkshire and the Humber",
    introSentence:
      "Home of Drax Power Station, which provides eight percent of the U.K.'s electricity.",
    color: "#BA0070",

    jobsDirect: [932, 785, 962, 980, 926],
    jobsIndirect: [2300, 1800, 1350, 1900, 1200],
    jobsInduced: [1300, 1050, 950, 1300, 1330],
    jobsTotal: [4500, 3650, 3200, 4200, 3450],
    economicDirect: [276.6, 230.5, 281.1, 339, 405],
    economicIndirect: [153.1, 117, 96.9, 134, 80],
    economicInduced: [63.3, 71.7, 53.4, 75, 83],
    economicTotal: [493.1, 419.2, 431.4, 548, 569],
    facebookString:
      "{jobsTotal} #jobs are supported by Drax in #Yorkshire and the #Humber, where the #energy company's economic impact is {economicTotal}",
    twitterString:
      "{jobsTotal} #jobs supported & {economicTotal} economic impact by Drax in #Yorkshire and the #Humber via @DraxGroup @OxfordEconomics",
    linkedinString:
      "{jobsTotal} jobs are supported by Drax in Yorkshire and the Humber, where the #energy company's economic impact is {economicTotal}",
    sharingLink: "#/yorkshire-and-humber",
    sharingImage: "facebook_images/share-yorkshire.jpg",
    downloadLink:
      "/public/Oxford_Economics-Economic_impact_of_Drax_in_the_UK.pdf",
  },
  westMidlands: {
    displayTitle: "West Midlands",
    color: "#4A76C1",
    jobsDirect: [0, 19, 0, 0, 0],
    jobsIndirect: [400, 900, 600, 600, 860],
    jobsInduced: [300, 550, 500, 550, 480],
    jobsTotal: [700, 1450, 1100, 1200, 1340, 1340],
    economicDirect: [0, 0, 0, 0, 2],
    economicIndirect: [24.2, 80.1, 53.7, 54, 71],
    economicInduced: [18.6, 44.7, 31.2, 38, 37],
    economicTotal: [42.8, 124.8, 84.9, 92, 110],
    facebookString:
      "{jobsTotal} #jobs are supported by Drax in the #WestMidlands, where the #energy company's economic impact is {economicTotal}",
    twitterString:
      "{jobsTotal} #jobs supported & {economicTotal} economic impact by Drax in #WestMidlands via @DraxGroup @OxfordEconomics #Midlands",
    linkedinString:
      "{jobsTotal} jobs are supported by Drax in the West Midlands, where the energy company's economic impact is {economicTotal}",
    sharingLink: "#/west-midlands",
    sharingImage: "facebook_images/share-west-midlands.jpg",
    downloadLink:
      "/public/Oxford_Economics-Economic_impact_of_Drax_in_the_U.K..pdf",
  },
  wales: {
    displayTitle: "Wales",
    color: "#2B78C2",
    jobsDirect: [0, 35, 0, 137, 99],
    jobsIndirect: [300, 950, 400, 400, 480],
    jobsInduced: [250, 600, 300, 350, 330],
    jobsTotal: [550, 1600, 750, 900, 910],
    economicDirect: [0, 0, 0, 6, 6],
    economicIndirect: [18.2, 95.9, 33.9, 35, 48],
    economicInduced: [15.8, 34.2, 17.1, 21, 21],
    economicTotal: [34.0, 130.1, 51, 61, 75],
    facebookString:
      "{jobsTotal} #jobs are supported by Drax in #Wales, where the #energy company's economic impact is {economicTotal}",
    twitterString:
      "{jobsTotal} #jobs supported & {economicTotal} economic impact by Drax in #Wales via @DraxGroup @OxfordEconomics #Cymru",
    linkedinString:
      "{jobsTotal} jobs are supported by Drax in Wales, where the energy company's economic impact is {economicTotal}",
    sharingLink: "#/wales",
    sharingImage: "facebook_images/share-wales.jpg",
    downloadLink:
      "/public/Oxford_Economics-Economic_impact_of_Drax_in_the_U.K..pdf",
  },
  eastMidlands: {
    displayTitle: "East Midlands",
    introSentence: "Home to Opus Energy’s operations in Northampton.",
    color: "#733B99",
    jobsDirect: [0, 619, 885, 723, 700],
    jobsIndirect: [700, 650, 1000, 1200, 1150],
    jobsInduced: [400, 650, 750, 800, 800],
    jobsTotal: [1100, 1900, 2700, 2700, 2650],
    economicDirect: [0, 60.4, 55.2, 31, 32],
    economicIndirect: [37.8, 44, 135.1, 211, 106],
    economicInduced: [22.5, 42.6, 42.2, 47, 51],
    economicTotal: [60.3, 147, 232.5, 289, 190],
    facebookString:
      "{jobsTotal} #jobs are supported by Drax in the #EastMidlands, where the #energy company's economic impact is {economicTotal}",
    twitterString:
      "{jobsTotal} #jobs supported & {economicTotal} economic impact by Drax in #EastMidlands via @DraxGroup @OxfordEconomics #Midlands",
    linkedinString:
      "{jobsTotal} jobs are supported by Drax in the East Midlands, where the energy company's economic impact is {economicTotal}",
    sharingLink: "#/east-midlands",
    sharingImage: "facebook_images/share-east-midlands.jpg",
    downloadLink:
      "/public/Oxford_Economics-Economic_impact_of_Drax_in_the_U.K..pdf",
  },
  eastEngland: {
    displayTitle: "East of England",
    introSentence:
      "Home of Ipswich-based Drax Customers, electricity supplier to U.K. businesses.",
    color: "#733B99",
    jobsDirect: [328, 367, 424, 462, 365],
    jobsIndirect: [450, 1250, 1200, 1600, 990],
    jobsInduced: [650, 550, 800, 900, 580],
    jobsTotal: [1400, 2200, 2500, 2900, 1930],
    economicDirect: [7.1, 9.5, 18.3, 39, 24],
    economicIndirect: [147.2, 179.5, 206.1, 294, 93],
    economicInduced: [36.3, 43.4, 48.9, 58, 44],
    economicTotal: [190.5, 232.1, 273.2, 392, 160],
    facebookString:
      "{jobsTotal} #jobs are supported by Drax in the #EastOfEngland, where the #energy company's economic impact is {economicTotal}",
    twitterString:
      "{jobsTotal} #jobs supported & {economicTotal} economic impact by Drax & Haven Power in #EastOfEngland via @DraxGroup @OxfordEconomics",
    linkedinString:
      "{jobsTotal} jobs are supported by Drax in the East of England, where the energy company's economic impact is {economicTotal}",
    sharingLink: "#/east-england",
    sharingImage: "facebook_images/share-east-england.jpg",
    downloadLink:
      "/public/Oxford_Economics-Economic_impact_of_Drax_in_the_U.K..pdf",
  },
  london: {
    displayTitle: "London",
    color: "#4A76C1",
    jobsDirect: [13, 18, 44, 61, 109],
    jobsIndirect: [300, 1050, 1200, 700, 930],
    jobsInduced: [250, 400, 400, 400, 300],
    jobsTotal: [600, 1450, 1700, 1100, 1340],
    economicDirect: [0, 0, 0, 21, 6],
    economicIndirect: [27.8, 99.3, 100.4, 70, 74],
    economicInduced: [24.5, 55.3, 41.4, 49, 42],
    economicTotal: [52.2, 154.6, 141.8, 140, 122],
    facebookString:
      "{jobsTotal} #jobs are supported by Drax in #London, where the #energy company's economic impact is {economicTotal}",
    twitterString:
      "{jobsTotal} #jobs supported & {economicTotal} economic impact by Drax in #London via @DraxGroup @OxfordEconomics",
    linkedinString:
      "{jobsTotal} jobs are supported by Drax in London, where the energy company's economic impact is {economicTotal}",
    sharingLink: "#/london",
    sharingImage: "facebook_images/share-london.jpg",
    downloadLink:
      "/public/Oxford_Economics-Economic_impact_of_Drax_in_the_U.K..pdf",
  },
  southEast: {
    displayTitle: "South East",
    color: "#4A76C1",
    jobsDirect: [0, 132, 0, 202, 67],
    jobsIndirect: [300, 1000, 700, 800, 800],
    jobsInduced: [300, 550, 450, 600, 400],
    jobsTotal: [600, 1650, 1200, 1700, 1260],
    economicDirect: [0, 0, 0, 39, 9],
    economicIndirect: [23.1, 75.9, 67.2, 119, 68],
    economicInduced: [22.8, 48.1, 33.9, 50, 37],
    economicTotal: [46.0, 124, 101.1, 208, 113],
    facebookString:
      "{jobsTotal} #jobs are supported by Drax in the #SouthEast, where the #energy company's economic impact is {economicTotal}",
    twitterString:
      "{jobsTotal} #jobs supported & {economicTotal} economic impact by Drax in #SouthEast via @DraxGroup @OxfordEconomics #SE",
    linkedinString:
      "{jobsTotal} jobs are supported by Drax in the South East, where the energy company's economic impact is {economicTotal}",
    sharingLink: "#/south-east",
    sharingImage: "facebook_images/share-south-east.jpg",
    downloadLink:
      "/public/Oxford_Economics-Economic_impact_of_Drax_in_the_U.K..pdf",
  },
  southWest: {
    displayTitle: "South West",
    color: "#4A76C1",
    jobsDirect: [0, 6, 0, 0, 0],
    jobsIndirect: [250, 550, 500, 300, 550],
    jobsInduced: [300, 400, 350, 350, 320],
    jobsTotal: [550, 950, 850, 600, 870],
    economicDirect: [0, 0, 0, 0, 1],
    economicIndirect: [22.3, 54.5, 42.1, 33, 51],
    economicInduced: [19.6, 29.3, 22.1, 24, 25],
    economicTotal: [41.9, 83.8, 64.1, 56, 77],
    facebookString:
      "{jobsTotal} #jobs are supported by Drax in the #SouthWest, where the #energy company's economic impact is {economicTotal}",
    twitterString:
      "{jobsTotal} #jobs supported & {economicTotal} economic impact by Drax in #SouthWest via @DraxGroup @OxfordEconomics #SW",
    linkedinString:
      "{jobsTotal} jobs are supported by Drax in the South West, where the energy company's economic impact is {economicTotal}",
    sharingLink: "#/south-west",
    sharingImage: "facebook_images/share-south-west.jpg",
    downloadLink:
      "/public/Oxford_Economics-Economic_impact_of_Drax_in_the_U.K..pdf",
  },
  northernIreland: {
    displayTitle: "Northern Ireland",
    color: "#5093D5",
    jobsDirect: [0, 0, 0, 0, 0],
    jobsIndirect: [200, 200, 200, 150, 340],
    jobsInduced: [250, 150, 150, 150, 150],
    jobsTotal: [450, 350, 350, 300, 490],
    economicDirect: [0.0, 0, 0, 0, 0],
    economicIndirect: [16.1, 14.8, 14.7, 15, 26],
    economicInduced: [14.4, 9.6, 7.4, 9, 10],
    economicTotal: [30.5, 24.4, 22.1, 24, 36],
    facebookString:
      "{jobsTotal} #jobs are supported by Drax in #NorthernIreland, where the #energy company's economic impact is {economicTotal}",
    twitterString:
      "{jobsTotal} #jobs supported & {economicTotal} economic impact by Drax in #NorthernIreland via @DraxGroup @OxfordEconomics #NI",
    linkedinString:
      "{jobsTotal} jobs are supported by Drax in Northern Ireland, where the energy company's economic impact is {economicTotal}",
    sharingLink: "#/northern-ireland",
    sharingImage: "facebook_images/share-northern-ireland.jpg",
    downloadLink:
      "/public/Oxford_Economics-Economic_impact_of_Drax_in_the_U.K..pdf",
  },
};

/* eslint-enable */

export default locations;
