import "../../modules/components/cookie_notice/cookie-notice.scss";
import "./styles.scss";
import isEqual from "lodash.isequal";

import {
  Events,
  scrollSpy,
  scroller,
} from "../../modules/utilities/scroll-spy";

import { ComponentView } from "../";
import { Intro } from "../../modules/components";
import PropTypes from "prop-types";
import React from "react";
import ScrollActions from "./scroll_actions";
import { ScrollContainer } from "../../modules/components";
import { bindActionCreators } from "redux";
import camelToHyphen from "../../utils/camelToHyphen.js";
import { connect } from "react-redux";
import dataActions from "../../modules/shared/data_actions";
import hyphenToCamel from "../../utils/hyphenToCamel.js";
import { indexToYear } from "../../modules/shared/year_map";
import { indexToCountry } from "../../modules/shared/country_map";
export default class HomeView extends React.Component {
  UNSAFE_componentWillMount() {
    this.initFacebook();

    this.initSVGclassListShim();

    const location = hyphenToCamel(this.props.location.pathname || "");
    const startYear = this.props.year;

    if (startYear >= 2015) {
      this.props.setYear(startYear);
    }

    const locations = Object.keys(this.props.data)
      .filter((key) => {
        return key !== "restOfUs";
      })
      .map((loc) => (loc === "summary" ? "SummaryContent" : loc));

    const country = indexToCountry(this.props.country);

    let loading = false;
    let animatingScroll = false;
    let linkedLocation = false;
    let pastIntro = false;
    let scrollReset = false;

    if (locations.indexOf(location) > -1) {
      loading = true;
      animatingScroll = true;
      scrollReset = true;
      pastIntro = true;
      linkedLocation = location;
      this.props.dispatchScrollAreaEntered({ country, location });
    }

    this.setState({
      pastIntro,
      scrollReset,
      animatingScroll,
      linkedLocation,
      locations,
      loading,
    });

    Events.scrollEvent.register("begin", () => {
      this.setState({
        animatingScroll: true,
      });
    });

    Events.scrollEvent.register("end", () => {
      scrollSpy.update();
      this.setState({
        animatingScroll: false,
        loading: false,
        linkedLocation: false,
      });
    });
  }

  componentDidMount() {
    if (this.state.linkedLocation) {
      scroller.scrollTo(this.state.linkedLocation, {
        duration: 0,
        delay: 0,
        smooth: true,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(next) {
    const dataisEqual = isEqual(this.props.data, next.data);
    const locations = Object.keys(next.data)
      .filter((key) => {
        return key !== "restOfUs";
      })
      .map((loc) => (loc === "summary" ? "SummaryContent" : loc));

      const country = indexToCountry(this.props.country);

    if (!dataisEqual) {
      this.setState({
        locations,
        country,
      });
    }


    const countryUpdated = this.props.country !== next.country;
    const yearUpdated = this.props.year !== next.year;
    if (countryUpdated.country || yearUpdated && this.props.currentArea) {
      const location = this.props.currentArea.toJS()["active_area"].location;
      this.sectionChanged(location);
    }
  }

  componentDidUpdate() {
    /* eslint-disable react/no-did-update-set-state */

    if (!this.state.scrollReset && this.state.pastIntro) {
      window.scrollTo(0, 0);

      this.setState({
        scrollReset: true,
      });
    }

    /* eslint-enable react/no-did-update-set-state */
  }

  initSVGclassListShim() {
    /* eslint-disable */

    if (!("classList" in document.createElementNS("http://www.w3.org/2000/svg", "g"))) {
      var descr = Object.getOwnPropertyDescriptor(HTMLElement.prototype, 'classList');
      Object.defineProperty(SVGElement.prototype, 'classList', descr);
    }

    /* eslint-enable */
  }

  initFacebook() {
    const devFbAppId = "322561968094605"; // test!
    const prodFbAppId = "1368903973128666";
    console.log(__DEV__ ? devFbAppId : prodFbAppId);
    window.fbAsyncInit = () => {
        window.FB.init({
          appId: __DEV__ ? devFbAppId : prodFbAppId,
          cookie: true,
          xfbml: true,
          version: "v16.0",
        });

      window.FB.AppEvents.logPageView();
    };

    /* eslint-disable */
  (function(d, s, id){
     var js, fjs = d.getElementsByTagName(s)[0];
     if (d.getElementById(id)) {return;}
     js = d.createElement(s); js.id = id;
     js.src = "https://connect.facebook.net/en_US/sdk.js";
     fjs.parentNode.insertBefore(js, fjs);
   }(document, 'script', 'facebook-jssdk'));
    /* eslint-enable */
  }

  updateUrl(location) {
    // Fixes crazy Firefox svg fill bug https://bugzilla.mozilla.org/show_bug.cgi?id=652991
    setTimeout(() => {
      const year = indexToYear(this.props.year);
      const country = indexToCountry(this.props.country);
      const loc = camelToHyphen(location || "");
      history.replaceState({}, null, `/#/${country}/${year}/${loc}`);
    }, 0);
  }

  sectionChanged(location) {
    if (!this.state.animatingScroll) {
      const country = indexToCountry(this.props.country);
      this.updateUrl(location);
      this.props.dispatchScrollAreaEntered({ country, location });
    }
  }

  introPassed() {
    this.setState({
      pastIntro: true,
    });
  }

  /* eslint-disable react/jsx-boolean-value */

  render() {
    const className = `PageSection ${this.state.loading ?
      "PageSection--loading" : ""}`;
    return (
      <section className={className}>
        <span className="LoadingIndicator">Loading</span>
        {this.state.pastIntro ? null :
          <ScrollContainer
            className="ScrollContainer--intro"
            activeClass="ScrollContainer--introScrolledPast"
            isDynamic={true}
            onSetActive={() => this.introPassed()}
            to="contentStart"
            spy={true}
            smooth={true}
          >
            <Intro />
          </ScrollContainer>}
        <ScrollContainer
          className="ScrollContainer--content"
          isDynamic={true}
          multipleElements={this.state.locations}
          offset={-300}
          offsetActive={this.state.pastIntro}
          onNewActiveSection={(location) => this.sectionChanged(location)}
          spy={true}
          smooth={true}
        >
          <ComponentView introVisible={!this.state.pastIntro} />
        </ScrollContainer>
      </section>
    );
  }

  /* eslint-enable react/jsx-boolean-value */
}

HomeView.propTypes = {
  dispatchScrollAreaEntered: PropTypes.func,
  year: PropTypes.number,
  country: PropTypes.number,
  data: PropTypes.object,
  setYear: PropTypes.func,
  setCountry: PropTypes.func,
  currentArea: PropTypes.object,
  location: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...ScrollActions,
      setYear: dataActions.setYear,
      setCountry: dataActions.setCountry,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentArea: state.area,
    year: state.data.get("year"),
    country: state.data.get("country"),
    data: state.data.get("data"),
  };
}

export const HomeViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HomeView);
