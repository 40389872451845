import { default as UKAreaData } from "./area_data_uk.js";
import { default as USAAreaData } from "./area_data_usa.js";
import { default as CANAreaData } from "./area_data_canada.js";
export { default as SharedData } from "./shared_data.js";

export const dataMap = {
  uk: UKAreaData,
  canada: CANAreaData,
  usa: USAAreaData,
};
