import React from "react";
import PropTypes from "prop-types";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { scroller } from "../../../modules/utilities/scroll-spy";
import { ToolTip } from "..";

import MapActions from "./map_actions";

import MapLegendSVG from "./map_legend.inline.svg";
import "./styles.scss";

export default class MapLegend extends React.Component {
  UNSAFE_componentWillMount() {
    this.setState({
      toolTipPosition: {
        left: 0,
        top: 0,
      },
      toolTipVisible: false,
      legend1: {
        color: "#0096d5",
        text: "0 to 500",
      },
      legend2: {
        color: "#2b78c1",
        text: "500 to 749",
      },
      legend3: {
        color: "#565aae",
        text: "750 to 999",
      },
      legend4: {
        color: "#813c9b",
        text: "1,000 to 1,499",
      },
      legend5: {
        color: "#ad2287",
        text: "1,500 to 2,000",
      },
      legend6: {
        color: "#d82572",
        text: "2,000+",
      },
    });
  }

  clipMapNamespace(target) {
    return target.substring(6);
  }

  mapLegendHover(event) {
    if (event.target.id) {
      const legendPos = event.target.getBoundingClientRect();
      this.setState({
        toolTipPosition: {
          left: legendPos.left + (legendPos.width / 2),
          top: legendPos.top + (legendPos.height / 2),
        },
        toolTipVisible: true,
        backgroundColor: this.state[event.target.id].color,
        toolTipText: this.state[event.target.id].text,
      });
    } else {
      this.setState({
        toolTipVisible: false,
      });
    }
  }

  mapLegendLeave() {
    this.setState({
      toolTipVisible: false,
    });
  }

  mapSectionClick(event) {
    const target = event.target.id;

    this.props.dispatchMapAreaClick({
      location: this.clipMapNamespace(target),
    });

    scroller.scrollTo(this.clipMapNamespace(target), {
      duration: 0,
      delay: 0,
      smooth: true,
    });
  }

  toolTipContent(hovered) {
    let toolTipString = "";
    if (hovered) {
      toolTipString = this.props.map.get("map_hover").data.toolTip;
    }

    return toolTipString;
  }

  render() {
    return (
      <div className="MapLegend">
        <ToolTip
          visible={this.state.toolTipVisible}
          position={this.state.toolTipPosition}
          theme="legend"
          backgroundColor={this.state.backgroundColor}
        >
          <div>{this.state.toolTipText}</div>
        </ToolTip>
        <span className="MapLegend-axis">Fewer Jobs</span>
        <MapLegendSVG
          onMouseMove={this.mapLegendHover.bind(this)}
          onMouseLeave={this.mapLegendLeave.bind(this)}
        />
        <span className="MapLegend-axis">More Jobs</span>
      </div>
    );
  }
}

MapLegend.propTypes = {
  dispatchMapAreaHover: PropTypes.func,
  dispatchMapAreaClick: PropTypes.func,
  introVisible: PropTypes.bool,
  map: PropTypes.object,
  area: PropTypes.object,
};

function mapStateToProps(state) { // state
  return {
    map: state.map,
    area: state.area,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...MapActions,
  }, dispatch);
}

export const MapLegendContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MapLegend);
