import "./styles.scss";

import {
  InfoPaneContent,
  MobileDownloadDropdown,
  MobileSearchDropdown,
  MobileShareDropdown,
  MobileCountryDropdown,
  MobileYearDropdown,
  CountryLinks,
  YearLinks,
} from "../";
import { formatCountrys } from "../../shared/constants";

import BackToTop from "./back-to-top.inline.svg";
import DownloadIcon from "./download-icon.inline.svg";
import DraxColourLogo from "public/assets/svg/drax_black.inline.svg";
import PropTypes from "prop-types";
import React from "react";
import SearchIcon from "./search-icon.inline.svg";
import ShareIcon from "./share-icon.inline.svg";
import { SharedData } from "../../../../../data";
import SummaryPaneContent from "../infopane_content/summary_pane_content";
import { animateScroll } from "../../../modules/utilities/scroll-spy";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import dataActions from "../../shared/data_actions";
import { indexToYear } from "../../shared/year_map";
import { indexToCountry } from "../../shared/country_map";
import infoPaneActions from "./info_pane_actions";

export default class InfoPane extends React.Component {
  backToTop() {
    animateScroll.scrollTo(0, {
      duration: 500,
      delay: 0,
      smooth: true,
    });
  }

  handleDownloadClick() {
    this.props.mobileDownloadChanged({
      open: !this.props.mobileDownload.get("mobile_download").open,
    });
  }

  handleSearchClick() {
    this.props.mobileSearchChanged({
      open: !this.props.mobileSearch.get("mobile_search").open,
    });
  }

  handleShareClick() {
    this.props.mobileShareChanged({
      open: !this.props.mobileShare.get("mobile_share").open,
    });
  }

  handleYearClick() {
    this.props.mobileYearChanged({
      open: !this.props.mobileYear.get("mobile_year").open,
    });
  }

  handleCountryClick() {
    this.props.mobileCountryChanged({
      open: !this.props.mobileCountry.get("mobile_country").open,
    });
  }

  renderChildViews() {
    const data = this.props.data.get("data");
    const children = [];
    const currentData = this.props.area.get("active_area").data ?? data.summary;

    for (const key in data) {
      if ({}.hasOwnProperty.call(data, key)) {
        if (key !== "summary") {
          if (key !== "restOfUs") {
            children.push(
              <InfoPaneContent
                AreaData={data[key]}
                SharedData={SharedData}
                key={key}
                id={key}
                year={this.props.data.get("year")}
                country={this.props.data.get("country")}
                currentData={currentData}
              />
            );
          }
        }
      }
    }

    return children;
  }

  render() {
    const children = this.renderChildViews();
    const data = this.props.data.get("data");


    const currentData = this.props.area.get("active_area").data ?? data.summary;
    const sharingData = currentData;

    const className = this.props.introVisible ?
      ["InfoPane"] :
      ["InfoPane", "InfoPane--pastIntro"];

    const currentYear = this.props.data.get("year");
    const currentCountry = this.props.data.get("country");
    const { mobileDownload, mobileSearch, mobileShare, mobileYear } =
      this.props;
    const isUk = currentCountry === 0;

    return (
      <div id="contentStart" className={className.join(" ")}>
        <header className="InfoPaneContent-header">
          <a className="InfoPaneContent-logo" href="/">
            <DraxColourLogo />
          </a>
          <a
            href="#"
            className="InfoPaneContent-backToTop"
            onClick={this.backToTop}
          >
            Back to top <BackToTop />
          </a>
          <div className="InfoPaneContent-dropdowns">
            <CountryLinks>
              <a className="InfoPaneContent-yearLink">
                Country:
                <span
                  className="InfoPaneContent__year-switch__year
               InfoPaneContent__year-switch__year--active"
                >
                  {formatCountrys[indexToCountry(currentCountry)]}
                </span>
              </a>
            </CountryLinks>
            <YearLinks>
              <a className="InfoPaneContent-yearLink">
                Year:
                <span
                  className={`InfoPaneContent__year-switch__year
               ${isUk ? "InfoPaneContent__year-switch__year--active" : ""}`}
                >
                  {indexToYear(currentYear)}
                </span>
              </a>
            </YearLinks>
          </div>
          <a
            onClick={this.handleCountryClick.bind(this)}
            className={`InfoPaneContent-header-country
              ${mobileYear.get("mobile_country").open ? "active" : ""}`}
          >
            Country:{" "}
            <span
              className="InfoPaneContent__country-switch__country
             InfoPaneContent__country-switch__country--active"
            >
              {formatCountrys[indexToCountry(currentCountry)]}
            </span>
          </a>

          <a
            onClick={this.handleYearClick.bind(this)}
            className={`InfoPaneContent-header-year
              ${mobileYear.get("mobile_year").open ? "active" : ""}`}
          >
            Year:{" "}
            <span
              className="InfoPaneContent__year-switch__year
             InfoPaneContent__year-switch__year--active"
            >
              {indexToYear(currentYear)}
            </span>
          </a>

          <a
            onClick={this.handleSearchClick.bind(this)}
            className={`InfoPaneContent-header-search
              ${mobileSearch.get("mobile_search").open ? "active" : ""}`}
          >
            <SearchIcon />
          </a>
          <a
            onClick={this.handleDownloadClick.bind(this)}
            className={`InfoPaneContent-header-download
              ${mobileDownload.get("mobile_download").open ? "active" : ""}`}
          >
            <DownloadIcon />
          </a>
          <a
            onClick={this.handleShareClick.bind(this)}
            className={`InfoPaneContent-header-share
              ${mobileShare.get("mobile_share").open ? "active" : ""}`}
          >
            <ShareIcon />
          </a>
        </header>
        <MobileSearchDropdown
          items={[
            "wales",
            "london",
            "southEast",
            "southWest",
            "eastEngland",
            "eastMidlands",
            "westMidlands",
            "yorkshireAndHumber",
            "northWest",
            "northEast",
            "scotland",
            "northernIreland",
          ]}
          active={this.props.mobileSearch.get("mobile_search").open}
          className="MobileDropdown"
        />
        <MobileShareDropdown
          active={this.props.mobileShare.get("mobile_share").open}
          sharingData={sharingData}
          className="MobileDropdown"
        />
        <MobileDownloadDropdown
          active={this.props.mobileDownload.get("mobile_download").open}
          className="MobileDropdown"
        />
        <SummaryPaneContent
          id="SummaryContent"
          SharedData={SharedData}
          AreaData={data.summary}
          year={currentYear}
          country={currentCountry}
          currentData={sharingData}
          key={sharingData.displayTitle}
        />
        <MobileYearDropdown
          active={this.props.mobileYear.get("mobile_year").open}
        />
        <MobileCountryDropdown
          active={this.props.mobileCountry.get("mobile_country").open}
        />
        {children}
        <footer className="mobileFooter-container">
          <button className="ot-sdk-show-settings mobileFooter-link">
            Cookie settings
          </button>
          <a
            className="mobileFooter-link"
            href="https://www.drax.com/drax-impact-use-of-cookies/"
            target="_blank"
          >
            Privacy policy
          </a>
        </footer>
      </div>
    );
  }
}

InfoPane.propTypes = {
  data: PropTypes.object,
  area: PropTypes.object,
  mobileSearch: PropTypes.object,
  mobileShare: PropTypes.object,
  mobileDownload: PropTypes.object,
  mobileYear: PropTypes.object,
  mobileCountry: PropTypes.object,
  mobileSearchChanged: PropTypes.func,
  mobileShareChanged: PropTypes.func,
  mobileDownloadChanged: PropTypes.func,
  mobileCountryChanged: PropTypes.func,
  mobileYearChanged: PropTypes.func,
  setYear: PropTypes.func,
  setCountry: PropTypes.func,
  introVisible: PropTypes.bool,
};

function mapStateToProps(state) {
  // state
  return { ...state };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...infoPaneActions,
      setYear: dataActions.setYear,
      setCountry: dataActions.setCountry,
    },
    dispatch
  );
}

export const InfoPaneContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoPane);
