import "./styles.scss";

import { BreakdownGraph } from "..";
import DownloadLinks from "../download_links";
import PropTypes from "prop-types";
import React from "react";
import ShareIcon from "./share-icon.inline.svg";
import { SharingLinks } from "..";
import data from "../../../../../data/area_data_uk";
import { indexToYear } from "../../shared/year_map";
import { currencyArray } from "../../shared/constants";

const row = data.summary;
const formatJobNumber = (nr) => nr.toLocaleString();
const formatCurrency = (v, digits = 0, currency = "£") => {
 return `${currency}${(v / 1000).toFixed(digits)} bn`;
};

const formatCurrencyMn = (v, digits = 0, currency = "£") => {
  return `${currency} ${v.toFixed(digits)} mn`;
};



const SummaryInfoPaneContent = (props) => {
  function generateShareUrl(key) {
    let copy = props.AreaData[key];

    const currentData = props.currentData;

    const jobsSupported = formatJobNumber(currentData.jobsTotal[props.year]);
    const impact = formatCurrency(
      currentData.economicTotal[props.year],
      2,
      currencyArray[props.country]
    );
    copy = copy.replace("{jobsSupported}", jobsSupported);
    copy = copy.replace("{economicImpact}", impact);

    return copy;
  }


  const hasEconTotal = props.AreaData.taxesTotal[props.year] !== 0;
  /* eslint-disable max-len */
  return (
    <div id={props.id} className="InfoPane-content InfoPane-content--summary">
      <h1 className="InfoPaneContent-heading">{props.AreaData.summaryTitle}</h1>

      <p className="InfoPaneContent-lede">{props.AreaData.summary}</p>

      <h2 className="InfoPaneContent-subHeading">
        Total direct, indirect and induced impact {indexToYear(props.year)}
      </h2>

      {props.AreaData.economicTotal ? (
        <div className="InfoPaneContent-columns">
          <div
            className={`${
              hasEconTotal ? "u-1/3" : "u-1/2"
            }  InfoPaneContent-column`}
          >
            <span className="Stat">
              {formatJobNumber(props.AreaData.jobsTotal[props.year])}
            </span>
            <span className="Stat--legend">jobs supported</span>
          </div>
          <div
            className={`${
              hasEconTotal ? "u-1/3" : "u-1/2"
            }  InfoPaneContent-column`}
          >
            <span className="Stat">
              {formatCurrency(
                props.AreaData.economicTotal[props.year],
                2,
                currencyArray[props.country]
              )}
            </span>
            <span className="Stat--legend">contribution to GDP</span>
          </div>
          {hasEconTotal && (
            <div className={"u-1/3  InfoPaneContent-column"}>
              <span className="Stat">
                {formatCurrencyMn(
                  props.AreaData.taxesTotal[props.year],
                  0,
                  currencyArray[props.country]
                )}
              </span>
              <span className="Stat--legend">tax contribution</span>
            </div>
          )}
        </div>
      ) : (
        ""
      )}

      <div className="InfoPaneContent-graphs">
        <div className="InfoPaneContent-graphsTitles">
          <h3 className="InfoPaneContent-graphTitle InfoPaneContent-graphTitle--main">
            Breakdown of impact
          </h3>
          <h4 className="InfoPaneContent-graphTitle InfoPaneContent-graphTitle--employment">
            <span className="u-bold">Employment</span> <br />
            (jobs)
          </h4>
          <h4 className="InfoPaneContent-graphTitle InfoPaneContent-graphTitle--economic">
            <span className="u-bold">
              Economic <br />
              Contribution
            </span>
            <br />
            {` (${currencyArray[props.country]} mn)`}
          </h4>
          {props.AreaData.taxesTotal[props.year] ? (
            <h4 className="InfoPaneContent-graphTitle InfoPaneContent-graphTitle--taxes">
              <span className="u-bold">
                Tax <br />
                Contribution
              </span>
              <br />
              {` (${currencyArray[props.country]} mn)`}
            </h4>
          ) : (
            ""
          )}
        </div>

        <span className="InfoPaneContent-standout InfoPaneContent-standout--mobile">
          <span className="u-extraBold">Employment</span> (jobs)
        </span>

        <div className="InfoPaneContent-graphsInner">
          <BreakdownGraph
            customTotalRadius="28"
            total1={props.AreaData.jobsTotal[props.year]}
            value1={props.AreaData.jobsDirect[props.year]}
            value2={props.AreaData.jobsIndirect[props.year]}
            value3={props.AreaData.jobsInduced[props.year]}
            total2={props.AreaData.economicTotal[props.year]}
            value4={props.AreaData.economicDirect[props.year]}
            value5={props.AreaData.economicIndirect[props.year]}
            value6={props.AreaData.economicInduced[props.year]}
            total3={props.AreaData.taxesTotal[props.year]}
            value7={props.AreaData.taxesDirect[props.year]}
            value8={props.AreaData.taxesIndirect[props.year]}
            value9={props.AreaData.taxesInduced[props.year]}
            directPercentageOfRowAbove={
              props.AreaData.taxesDirect[props.year] /
              props.AreaData.economicDirect[props.year]
            }
            indirectPercentageOfRowAbove={
              props.AreaData.taxesIndirect[props.year] /
              props.AreaData.economicIndirect[props.year]
            }
            inducedPercentageOfRowAbove={
              props.AreaData.taxesInduced[props.year] /
              props.AreaData.economicInduced[props.year]
            }
            totalPercentageOfRowAbove={
              props.AreaData.taxesTotal[props.year] /
              props.AreaData.economicTotal[props.year]
            }
            color1="#B6C93E"
            color2="#65AC85"
            color3="#2C4491"
            color4="#5494D2"
            color5="#C4D35D"
            color6="#7ABC9D"
            color7="#5166A6"
            color8="#68AADC"
            color9="#D0DC7D"
            color10="#95C9B1"
            color11="#7485B8"
            color12="#86BBE3"
            toolTipHighlight1="#CCDA6F"
            toolTipHighlight2="#88C4A9"
            toolTipHighlight3="#6579B1"
            toolTipHighlight4="#76B4E0"
            inactiveColor="#e6dfdf"
            headings
            legend1="Direct"
            legend2="Indirect"
            legend3="Induced"
            legend4="Total"
            helpToolTips={props.SharedData.helpToolTips}
            className="InfoPaneContent-topGraph"
            currency={currencyArray[props.country]}
          />

          <span className="InfoPaneContent-standout InfoPaneContent-standout--mobile">
            <span className="u-extraBold">Economic Contribution</span>
            {` (${currencyArray[props.country]} mn)`}
          </span>

          <BreakdownGraph
            customTotalRadius="28"
            total1={row.jobsTotal[props.year]}
            value1={row.jobsDirect[props.year]}
            value2={row.jobsIndirect[props.year]}
            value3={row.jobsInduced[props.year]}
            color1="#C4D35D"
            color2="#7ABC9D"
            color3="#5166A6"
            color4="#68AADC"
            toolTipHighlight1="#CCDA6F"
            toolTipHighlight2="#88C4A9"
            toolTipHighlight3="#6579B1"
            toolTipHighlight4="#76B4E0"
            headings
            legend1="Direct"
            legend2="Indirect"
            legend3="Induced"
            legend4="Total"
            helpToolTips={props.SharedData.helpToolTips}
            className="InfoPaneContent-mobileOnly"
          />

          <span className="InfoPaneContent-standout InfoPaneContent-standout--mobile">
            <span className="u-extraBold">Tax Contribution</span>
            {` (${currencyArray[props.country]} mn)`}
          </span>
          <BreakdownGraph
            graphType="tax"
            customTotalRadius="30"
            total1={row.taxesTotal[props.year]}
            value1={row.taxesDirect[props.year]}
            value2={row.taxesIndirect[props.year]}
            value3={row.taxesInduced[props.year]}
            totalPercentageOfRowAbove={
              (row.taxesTotal[props.year] / row.jobsTotal[props.year]) * 100
            }
            color1="#C4D35D"
            color2="#7ABC9D"
            color3="#5166A6"
            color4="#68AADC"
            toolTipHighlight1="#CCDA6F"
            toolTipHighlight2="#88C4A9"
            toolTipHighlight3="#6579B1"
            toolTipHighlight4="#76B4E0"
            headings
            legend1="Direct"
            legend2="Indirect"
            legend3="Induced"
            legend4="Total"
            helpToolTips={props.SharedData.helpToolTips}
            className="InfoPaneContent-mobileOnly"
            currency={currencyArray[props.country]}
          />
        </div>
      </div>

      <div className="InfoPaneContent-toolsPanel">
        <SharingLinks
          sharingImage={props.AreaData.sharingImage}
          facebookString={generateShareUrl("facebookString")}
          twitterString={ generateShareUrl("twitterString")}
          linkedinString={generateShareUrl("linkedinString")}
          sharingLink={props.AreaData.sharingLink}
          key={props.currentData.displayTitle}
        >
          <a className="InfoPaneContent-sharingLink">
            <ShareIcon />
            Share this page
          </a>
        </SharingLinks>
        <DownloadLinks>
          <a className="InfoPaneContent-downloadLink">
            <ShareIcon />
            Download report
          </a>
        </DownloadLinks>
        <div className="desktopFooter-container">
          <div>
            <a className="desktopFooter-link ot-sdk-show-settings InfoPaneContent-cookieLink">
              Cookie settings
            </a>
            <a
              className="desktopFooter-link InfoPaneContent-privacyLink"
              href="https://www.drax.com/drax-impact-use-of-cookies/"
            >
              Privacy policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

/* eslint-enable max-len */


SummaryInfoPaneContent.propTypes = {
  id: PropTypes.string,
  AreaData: PropTypes.object,
  currentData: PropTypes.object,
  SharedData: PropTypes.object,
  year: PropTypes.number,
  country: PropTypes.number,
};

export default SummaryInfoPaneContent;
