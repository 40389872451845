import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import infoPaneActions from "../infopane/info_pane_actions.js";

import { YEARS } from "../../shared/constants";
import sharedData from "../../../../../data/shared_data";
const LINKS = sharedData.download_links.slice(0).reverse();
const REVERSE_YEARS = YEARS.slice(0).reverse();


import "./styles.scss";
import Close from "./close.inline.svg";

/* eslint-disable max-len */

export default class MobileDropdownView extends React.Component {
  close() {
    this.props.mobileDownloadChanged({ open: false });
  }

  render() {
    const classNames = `MobileDropdown ${this.props.active ? "active" : ""}`;
    return (
      <div className={classNames}>
        <Close className="MobileDropdown-close" onClick={this.close.bind(this)} />
        { [2022, ...REVERSE_YEARS].map((year, key) => {
          return (
            <a
              className="MobileDropdown-item MobileDropdown-item--downloads"
              href={LINKS[key]}
              target="_blank"
              key={key}
            >
              {year} Report
            </a>
          );
        })}
      </div>
    );
  }
}

MobileDropdownView.propTypes = {
  sharingData: PropTypes.object,
  mobileDownloadChanged: PropTypes.func,
  active: PropTypes.bool,
};

function mapStateToProps(state) { // state
  return { ...state };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...infoPaneActions,
  }, dispatch);
}

export const MobileDropdownContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MobileDropdownView);
