import { dataMap } from "data";
import { Map } from "immutable";
import { yearToIndex } from "./year_map";
import { countryToIndex } from "./country_map";

const init = new Map({
  year: 4,
  country: 0,
  data: dataMap.uk,
});

export default (state = init, action) => {
  switch (action.type) {
    case "SET_COUNTRY":
      const country = countryToIndex(action.props);
      return state.set("country", country);
    case "SET_YEAR":
      const year = yearToIndex(action.props);
      return state.set("year", year);
    case "SET_DATA":
      const data = dataMap[action.props];
        return state.set("data", data);
    default:
      return state;
  }
};
