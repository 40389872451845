"use strict";

var React = require("react");
import Helpers from "../mixins/Helpers";

class Link extends React.Component {
  render() {
    return (
      <a
        className={this.props.className}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </a>
    );
  }
}

export default Helpers.Scroll(Link);
