import "./styles.scss";

import PropTypes from "prop-types";
import React from "react";
import { YEARS } from "../../shared/constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import dataActions from "../../shared/data_actions";

export default class YearLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = { visible: false };
  }

  visibility(show) {
    this.setState({
      visible: show,
    });
  }

  handleYearClick(year) {
    this.props.setYear(year);
  }

  render() {
    const currentCountry = this.props.country;

    const className = `YearLinks  ${currentCountry !== 0 && "inactive"}`;
    const menu = this.state.visible ?
      "YearLinks-hoverMenu YearLinks-hoverMenu--visible" :
      "YearLinks-hoverMenu";
    const menuWrapper = "YearLinks-hoverMenu-wrapper";

    const currentYear = this.props.year;

    return (
      <div className={className}>
        <div className={menu}>
          <div className={menuWrapper}>
            {YEARS.map((year, k) => {
              const switchClasses =
                k === currentYear ?
                  [
                    "InfoPaneContent__year-switch__year",
                    "InfoPaneContent__year-switch__year--active",
                  ] :
                  ["InfoPaneContent__year-switch__year"];
              return (
                <a
                  className={switchClasses.join(" ")}
                  onClick={this.handleYearClick.bind(this, year)}
                  target="_blank"
                  key={year}
                >
                  {year}
                </a>
              );
            })}
          </div>
        </div>
        <div
          onMouseOver={() => {
            if (currentCountry !== 0) { return; }

            this.visibility.bind(this, true);
          }}
          onMouseLeave={() => this.visibility.bind(this, false)}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

YearLinks.propTypes = {
  children: PropTypes.object,
  year: PropTypes.number,
  country: PropTypes.number,
  setYear: PropTypes.func,
};
function mapStateToProps(state) {
  // state
  return {
    year: state.data.get("year"),
    country: state.data.get("country"),
    ...state,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setYear: dataActions.setYear,
    },
    dispatch
  );
}

export const YearLinksContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(YearLinks);
