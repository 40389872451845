/* eslint-disable */
const locations = {
  summary: {
    displayTitle: "Intro",
    color: "#5093D5",
    summaryTitle: "Our economic impact in Canada",

    summary:
      "In 2021, Drax acquired Pinnacle Renewable Energy and its pellet production assets on Canada’s west coast. This addition increased the group’s pellet production capacity by 2.9 megatonnes per annum (to around 5.0 megatonnes per annum) and brought the total number of Drax sites in Canada to 11, with nine pellet production facilities (seven in British Columbia and two in Alberta), and facilities at the deep water ports in Prince Rupert and Vancouver, both British Columbia, used to export pellets to key Asian markets.",
    facebookString:
      "{jobsSupported} #jobs are supported by Drax in the Canada. The #energy company has an {economicImpact} economic impact",
    twitterString:
      "{jobsSupported} #jobs supported & {economicImpact} economic impact by Drax in the Canada via @DraxGroup @OxfordEconomics",
    linkedinString:
      "{jobsSupported} jobs are supported by Drax in the Canada. The #energy company has an {economicImpact} economic impact",
    jobsDirect: [0, 0, 0, 0, 436],
    jobsIndirect: [0, 0, 0, 0, 7700],
    jobsInduced: [0, 0, 0, 0, 2300],
    jobsTotal: [0, 0, 0, 0, 10400],
    economicDirect: [0, 0, 0, 0, 63],
    economicIndirect: [0, 0, 0, 0, 739],
    economicInduced: [0, 0, 0, 0, 260],
    economicTotal: [0, 0, 0, 0, 1062],
    taxesDirect: [0, 0, 0, 0, 8],
    taxesIndirect: [0, 0, 0, 0, 203],
    taxesInduced: [0, 0, 0, 0, 65],
    taxesTotal: [0, 0, 0, 0, 277],
    sharingLink: "#/Summary-content",
    sharingImage: "facebook_images/share-generic.jpg",
    downloadLink: [
      "/public/Oxford_Economics-Economic_impact_of_Drax_in_the_Canada_2015.pdf",
      "/public/Oxford_Economics-Economic_impact_of_Drax_in_the_Canada_2016.pdf",
      "/public/Oxford_Economics-Economic_impact_of_Drax_in_the_Canada_2017.pdf",
    ],
  },
  britishColumbia: {
    displayTitle: "British Columbia",
    color: "#A50070",
    jobsDirect: [0, 0, 0, 0, 413],
    jobsIndirect: [0, 0, 0, 0, 2730],
    jobsInduced: [0, 0, 0, 0, 1560],
    jobsTotal: [0, 0, 0, 0, 4700],
    economicDirect: [0, 0, 0, 0, 55],
    economicIndirect: [0, 0, 0, 0, 324],
    economicInduced: [0, 0, 0, 0, 183],
    economicTotal: [0, 0, 0, 0, 562],
    facebookString:
      "{jobsInduced} #jobs are supported by Drax in #BritishColumbia, where the #energy company's economic impact is {economicTotal}",
    twitterString:
      "{jobsTotal} #jobs supported & {economicTotal} economic impact by Drax in #BritishColumbia via @DraxGroup @OxfordEconomics",
    linkedinString:
      "{jobsInduced} jobs are supported by Drax in British Columbia, where the energy company's economic impact is {economicTotal}",
    sharingLink: "#/british-columbia",
    sharingImage: "facebook_images/share-scotland.jpg",
    downloadLink:
      "/public/Oxford_Economics-Economic_impact_of_Drax_in_the_Canada.pdf",
  },
  restOfCanada: {
    displayTitle: "Rest of Canada",
    color: "#733B99",
    jobsDirect: [0, 0, 0, 0, 23],
    jobsIndirect: [0, 0, 0, 0, 5000],
    jobsInduced: [0, 0, 0, 0, 740],
    jobsTotal: [0, 0, 0, 0, 5700],
    economicDirect: [0, 0, 0, 0, 8],
    economicIndirect: [0, 0, 0, 0, 415],
    economicInduced: [0, 0, 0, 0, 77],
    economicTotal: [0, 0, 0, 0, 500],
    facebookString:
      "{jobsTotal} #jobs are supported by Drax in the #RestOfCanada, where the #energy company's economic impact is {economicTotal}",
    twitterString:
      "{jobsTotal} #jobs supported & {economicTotal} economic impact by Drax in #RestOfCanada via @DraxGroup @OxfordEconomics #NE",
    linkedinString:
      "{jobsTotal} jobs are supported by Drax in the rest of Canada, where the energy company's economic impact is {economicTotal}",
    sharingLink: "#/rest-of-canada",
    sharingImage: "facebook_images/share-north-east.jpg",
    downloadLink:
      "/public/Oxford_Economics-Economic_impact_of_Drax_in_the_Canada.pdf",
  },
};

/* eslint-enable */

export default locations;
