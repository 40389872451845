import { Map } from "immutable";
import { dataMap } from "data";

const init = new Map({
  active_area: {
    props: false,
    location: false,
    previousLocation: false,
    data: null,
    scrolling: false,
  },
});

function validateAreaSelect(state, action, isScroll) {
  let newState = state;
  const AreaData =
    dataMap[action.props.country] ?? dataMap.uk;

  if (AreaData.hasOwnProperty(action.props.location)) {
    const activeArea = state.get("active_area");
    const data = AreaData[action.props.location];
    const props = {
      props: action.props,
      location: action.props.location,
      data,
    };

    if (activeArea.location &&
      (action.props.location !== activeArea.location)) {
      props.previousLocation = activeArea.location;
      newState = state.set("active_area", { ...props });
    } else if (!state.previousLocation && !activeArea.location) {
      props.previousLocation = false;
      newState = state.set("active_area", { ...props });
    }
  } else if (isScroll) {
    newState = state.set("active_area", init);
  }
  return newState;
}

export default (state = init, action) => {
  switch (action.type) {
    case "MAP_AREA_CLICK":
      return validateAreaSelect(state, action);
    case "SCROLL_AREA_ENTERED":
      return validateAreaSelect(state, action, true);
    default:
      return state;
  }
};
