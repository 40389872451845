import { Map } from "immutable";

const init = new Map({
  intro_visibility: {
    value: true,
    props: {},
  },
});

export default (state = init, action) => {
  switch (action.type) {
    case "INTRO_VISIBILITY_CHANGED":
      return state.set("intro_visibility", {
        props: action.props,
        value: action.props.visible,
      });
    default:
      return state;
  }
};
