"use strict";

import React from "react";
import Helpers from "../mixins/Helpers";

const Element = function ({ children }) {
  return <div>{children}</div>;
};

export default Helpers.Element(Element);
