import React from "react";
import PropTypes from "prop-types";

import { Map, InfoPane, LinkPopup } from "../../modules/components";

export default class ComponentView extends React.Component {
  render() {
    return (
      <section className="PageSection--nested">
        <InfoPane introVisible={this.props.introVisible} />
        <Map introVisible={this.props.introVisible} />
        <LinkPopup />
      </section>
    );
  }
}

ComponentView.propTypes = {
  introVisible: PropTypes.bool.isRequired,
};
