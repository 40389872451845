/* eslint-disable */

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import shareLinkActions from './share_links_actions';

import TwitterLogo from './twitter.inline.svg';
import FacebookLogo from './facebook-icon.inline.svg';
import LinkedinLogo from './linkedin.inline.svg';
import CopyLogo from './copy-link.inline.svg';

import './styles.scss';

export default class SharingLinks extends React.Component {

  constructor(props) {
		super(props);
		this.state = { visibility: false };
	}

  openPopup(url, width=570, height=250) {
    const position = {
      top: (window.innerHeight / 2) - (height / 2),
      left: (window.innerWidth / 2) - (width / 2),
    };
    const options =
      `status=1,width=${width},height=${height},top=${position.top},left=${position.left}`;
    window.open(url, '', options);
  }

  shareToFacebook() {
    const baseUrl = "https://staging.draximpact.co.uk/"; //window.location.hostname;
    const navUrl =  `${baseUrl}/${this.props.sharingLink}`;
 

    window.FB.ui(
      {
        method: "share_open_graph",
        action_type: "og.shares",
        action_properties: JSON.stringify({
          object: {
            "og:url": navUrl, // your url to share
            "og:title": "Drax",
            "og:description": `${this.props.facebookString}`,
            "og:image": `${baseUrl}/public/assets/images/social-assets/${this.props.sharingImage}`,
          },
        }),
      },
      (response) => {
        console.log("Posted to Facebook", response);
      }
    );
  }

  shareToTwitter() {
    const baseUrl = window.location.hostname;
    let url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(this.props.twitterString)}`;
    url += ` ${encodeURIComponent(`${baseUrl}/${this.props.sharingLink}`)}`;
    this.openPopup(url);
  }

  shareToLinkedin() {
    const baseUrl = window.location.hostname;
    let url = `https://www.linkedin.com/shareArticle?summary=${encodeURIComponent(this.props.linkedinString)}`;
    url += `&url=${encodeURIComponent(`${baseUrl}/${this.props.sharingLink}`)}`
    url += `&title=${encodeURIComponent('Drax Economics')}`
    url += '&mini=true'
    this.openPopup(url, 650, 550);
  }

  copyLink() {
    const baseUrl = window.location.hostname;
    const url = `http://${baseUrl}/${this.props.sharingLink}`;
    this.props.showCopyPopup({
      text: url
    });
  }

  visibility(show) {
    this.setState({
      visible: show
    });
  }

  render() {
    const className = `SharingLinks ${this.props.static ? 'SharingLinks--static' : ''}`;
    return (
      <div className={className}>
        <div className={this.state.visible ? 'SharingLinks-hoverMenu SharingLinks-hoverMenu--visible' : 'SharingLinks-hoverMenu'}>
          <span className="SharingLink SharingLink--noHover"><span className="u-bold">Share with:</span></span>
          <a className="SharingLink" onClick={this.shareToTwitter.bind(this)}><TwitterLogo /></a>
          <a className="SharingLink" onClick={this.shareToLinkedin.bind(this)}><LinkedinLogo /></a>

        </div>
        <div onMouseOver={ this.visibility.bind(this, true) } onMouseLeave={ this.visibility.bind(this, false) }>
          {this.props.children}
        </div>
      </div>
    )
  }
}

function stateToProps(state) { // state
  return state.sharingLinks;
}

function dispatchToProps(dispatch) {
  return bindActionCreators({
    ...shareLinkActions,
  }, dispatch);
}

export const SharingLinksContainer = connect(
  stateToProps,
  dispatchToProps
)(SharingLinks);

/* eslint-enable */
