import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

/* eslint-disable eqeqeq */
/* eslint-disable max-len */

export default class GraphComponent extends React.Component {
  UNSAFE_componentWillMount() {
    this.setState({
      defaultTotalRadius: 45,
      hovered: false,
      isUpper: false,
      isLower: false,
      isExtra: false,
      helpToolTip: false,
    });
  }

  onMouseOver(event) {
    const target = event.target;

    if (target.nodeName === "circle") {
      this.setState({
        hovered: true,
        isUpper: target.parentNode.classList[0] === "upper",
        isLower: target.parentNode.classList[0] === "lower",
        isExtra: target.parentNode.classList[0] === "extra",
      });
    } else {
      this.setState({
        hovered: false,
        isUpper: false,
        isLower: false,
        isExtra: false,
      });
    }
  }

  onMouseLeave() {
    this.setState({
      hovered: false,
    });
  }

  getHelpContent(position) {
    return (this.props.helpToolTips && this.props.helpToolTips[position]) ? this.props.helpToolTips[position] : "";
  }

  createHelpButton(position) {
    return (
      <span className="BreakdownGraph-heading-help">
        <div
          className="BreakdownGraph-heading-help-button"
          style={{ background: "#2B2845" }}
          onMouseOver={this.helpToolTip.bind(this, position)}
          onMouseLeave={this.helpToolTip.bind(this, false)}
        >
          ?
        </div>
      </span>
    );
  }

  helpToolTip(position) {
    this.setState({
      helpToolTip: position,
      hovered: false,
      isUpper: false,
      isLower: false,
    });
  }

  ascertainPercentageValue(value, total) {
    const MIN_AREA = 40;
    let area = Math.PI * Math.pow(this.props.customTotalRadius ? this.props.customTotalRadius : this.state.defaultTotalRadius, 2);
    area -= MIN_AREA;

    const percentage = (value / total);
    const adjustedRadius = Math.sqrt(((MIN_AREA + (area * percentage)) / Math.PI));

    return adjustedRadius;
  }

  render() {
    const className = `BreakdownGraph ${this.props.className}`;
    const upperTipsClassName = `Graph-toolTip--top ${this.state.isUpper ? "Graph-toolTip--activeTips" : ""}`;
    const lowerTipsClassName = `Graph-toolTip--bottom ${
      this.state.isLower ? "Graph-toolTip--activeTips" : ""
    }`;
    const extraRowTipsClassName = `Graph-toolTip--extraRow ${
      this.state.isExtra ? "Graph-toolTip--activeTips" : ""
    }`;

    return (
      <div className={className}>
        {this.props.headings ? (
          <div className="BreakdownGraph-headings">
            <span className="BreakdownGraph-heading">{this.props.legend1}</span>
            <span className="BreakdownGraph-heading">{this.props.legend2}</span>
            <span className="BreakdownGraph-heading">{this.props.legend3}</span>
            <span className="BreakdownGraph-heading">
              <span className="u-bold">{this.props.legend4}</span>
            </span>

            {this.createHelpButton(1, this.props.color1)}
            {this.createHelpButton(2, this.props.color2)}
            {this.createHelpButton(3, this.props.color3)}
          </div>
        ) : null}

        <div className="Graph-toolTip--help">
          <div
            className={
              this.state.helpToolTip === 1 ?
                "Graph-toolTip Graph-toolTip--active" :
                "Graph-toolTip"
            }
          >
            <span
              className="Graph-toolTip-content"
              style={{
                backgroundColor: "#2B2845",
                borderColor: this.props.toolTipHighlight1,
              }}
            >
              {this.getHelpContent(0)}
            </span>
          </div>
          <div
            className={
              this.state.helpToolTip === 2 ?
                "Graph-toolTip Graph-toolTip--active" :
                "Graph-toolTip"
            }
          >
            <span
              className="Graph-toolTip-content"
              style={{
                backgroundColor: "#2B2845",
                borderColor: this.props.toolTipHighlight2,
              }}
            >
              {this.getHelpContent(1)}
            </span>
          </div>
          <div
            className={
              this.state.helpToolTip === 3 ?
                "Graph-toolTip Graph-toolTip--active" :
                "Graph-toolTip"
            }
          >
            <span
              className="Graph-toolTip-content"
              style={{
                backgroundColor: "#2B2845",
                borderColor: this.props.toolTipHighlight3,
              }}
            >
              {this.getHelpContent(2)}
            </span>
          </div>
          <div
            className={
              this.state.helpToolTip === 4 ?
                "Graph-toolTip Graph-toolTip--active" :
                "Graph-toolTip"
            }
          >
            <span
              className="Graph-toolTip-content"
              style={{
                backgroundColor: this.props.toolTipHighlight4,
                borderColor: this.props.toolTipHighlight4,
              }}
            >
              {this.getHelpContent(3)}
            </span>
          </div>
        </div>

        <div className={upperTipsClassName}>
          <div className="Graph-toolTip">
            <span
              className="Graph-toolTip-content"
              style={{
                backgroundColor: this.props.toolTipHighlight1,
                borderColor: this.props.toolTipHighlight1,
              }}
            >
              {this.props.value1.toLocaleString()}
            </span>
          </div>
          <div className="Graph-toolTip">
            <span
              className="Graph-toolTip-content"
              style={{
                backgroundColor: this.props.toolTipHighlight2,
                borderColor: this.props.toolTipHighlight2,
              }}
            >
              {this.props.value2.toLocaleString()}
            </span>
          </div>

          <div className="Graph-toolTip">
            <span
              className="Graph-toolTip-content"
              style={{
                backgroundColor: this.props.toolTipHighlight3,
                borderColor: this.props.toolTipHighlight3,
              }}
            >
              {this.props.value3.toLocaleString()}
            </span>
          </div>

          <div className="Graph-toolTip">
            <span
              className="Graph-toolTip-content"
              style={{
                backgroundColor: this.props.toolTipHighlight4,
                borderColor: this.props.toolTipHighlight4,
              }}
            >
              {this.props.total1.toLocaleString()}
            </span>
          </div>
        </div>

        {this.props.total2 ? (
          <div className={lowerTipsClassName}>
            <div className="Graph-toolTip">
              <span
                className="Graph-toolTip-content"
                style={{
                  backgroundColor: this.props.toolTipHighlight1,
                  borderColor: this.props.toolTipHighlight1,
                }}
              >
                {`${this.props.value4}`}
              </span>
            </div>
            <div className="Graph-toolTip">
              <span
                className="Graph-toolTip-content"
                style={{
                  backgroundColor: this.props.toolTipHighlight2,
                  borderColor: this.props.toolTipHighlight2,
                }}
              >
                {`${this.props.value5}`}
              </span>
            </div>

            <div className="Graph-toolTip">
              <span
                className="Graph-toolTip-content"
                style={{
                  backgroundColor: this.props.toolTipHighlight3,
                  borderColor: this.props.toolTipHighlight3,
                }}
              >
                {`${this.props.value6}`}
              </span>
            </div>

            <div className="Graph-toolTip">
              <span
                className="Graph-toolTip-content"
                style={{
                  backgroundColor: this.props.toolTipHighlight4,
                  borderColor: this.props.toolTipHighlight4,
                }}
              >
                {`${this.props.total2.toLocaleString()}`}
              </span>
            </div>
          </div>
        ) : (
          ""
        )}
        {this.props.total3 ? (
          <div className={extraRowTipsClassName}>
            <div className="Graph-toolTip">
              <span
                className="Graph-toolTip-content"
                style={{
                  backgroundColor: this.props.toolTipHighlight1,
                  borderColor: this.props.toolTipHighlight1,
                }}
              >
                {`${this.props.value7}`}
              </span>
            </div>
            <div className="Graph-toolTip">
              <span
                className="Graph-toolTip-content"
                style={{
                  backgroundColor: this.props.toolTipHighlight2,
                  borderColor: this.props.toolTipHighlight2,
                }}
              >
                {`${this.props.value8}`}
              </span>
            </div>

            <div className="Graph-toolTip">
              <span
                className="Graph-toolTip-content"
                style={{
                  backgroundColor: this.props.toolTipHighlight3,
                  borderColor: this.props.toolTipHighlight3,
                }}
              >
                {`${this.props.value9}`}
              </span>
            </div>

            <div className="Graph-toolTip">
              <span
                className="Graph-toolTip-content"
                style={{
                  backgroundColor: this.props.toolTipHighlight4,
                  borderColor: this.props.toolTipHighlight4,
                }}
              >
                {`${this.props.total3.toLocaleString()}`}
              </span>
            </div>
          </div>
        ) : (
          ""
        )}

        <div
          className={`GraphSVGContainer ${
            this.props.total3 && "extra-padding"
          }`}
        >
          <svg
            className={this.state.hovered ? "Graph--activeHover" : ""}
            preserveAspectRatio="xMinYMin slice"
            viewBox={`0 0 491.5 ${this.props.total3 ? 275 : 185}`}
            onMouseLeave={this.onMouseLeave.bind(this)}
            onMouseOver={this.onMouseOver.bind(this)}
          >
            {this.props.total3 && this.props.totalPercentageOfRowAbove ? (
              <g className="extra">
                <circle
                  cx="61.4375"
                  cy="220"
                  r={2 * (Math.max(
                    2,
                    this.ascertainPercentageValue(
                      this.props.value4,
                      this.props.total2
                    ) * this.props.directPercentageOfRowAbove
                  ))}
                  style={{
                    fill:
                      this.props.value4 == 0 ?
                        this.props.inactiveColor :
                        this.props.color9,
                  }}
                />
                <circle
                  cx="184.32"
                  cy="220"
                  r={2 * (Math.max(
                    2,
                    this.ascertainPercentageValue(
                      this.props.value5,
                      this.props.total2
                    ) * this.props.indirectPercentageOfRowAbove
                  ))}
                  style={{ fill: this.props.color10 }}
                />
                <circle
                  cx="307.195"
                  cy="220"
                  r={2 * (Math.max(
                    2,
                    this.ascertainPercentageValue(
                      this.props.value6,
                      this.props.total2
                    ) * this.props.inducedPercentageOfRowAbove
                  ))}
                  style={{ fill: this.props.color11 }}
                />
                <circle
                  cx="430.0625"
                  cy="220"
                  r={
                    2 * ((this.customTotalRadius ?
                      this.customTotalRadius :
                      this.state.defaultTotalRadius) *
                    this.props.totalPercentageOfRowAbove
                    )}
                  style={{ fill: this.props.color12 }}
                />
              </g>
            ) : null}

            {this.props.total2 ? (
              <g className="lower">
                <circle
                  cx="61.4375"
                  cy="135"
                  r={this.ascertainPercentageValue(
                    this.props.value4,
                    this.props.total2
                  )}
                  style={{
                    fill:
                      this.props.value4 == 0 ?
                        this.props.inactiveColor :
                        this.props.color5,
                  }}
                />
                <circle
                  cx="184.32"
                  cy="135"
                  r={this.ascertainPercentageValue(
                    this.props.value5,
                    this.props.total2
                  )}
                  style={{ fill: this.props.color6 }}
                />
                <circle
                  cx="307.195"
                  cy="135"
                  r={this.ascertainPercentageValue(
                    this.props.value6,
                    this.props.total2
                  )}
                  style={{ fill: this.props.color7 }}
                />
                <circle
                  cx="430.0625"
                  cy="135"
                  r={
                    this.customTotalRadius ?
                      this.customTotalRadius :
                      this.state.defaultTotalRadius
                  }
                  style={{ fill: this.props.color8 }}
                />
              </g>
            ) : null}
            <g className="upper">
              <circle
                cx="61.4375"
                cy="50"
                r={this.ascertainPercentageValue(
                  this.props.value1,
                  this.props.total1
                )}
                style={{
                  fill:
                    this.props.value1 == 0 ?
                      this.props.inactiveColor :
                      this.props.color1,
                }}
              />
              <circle
                cx="184.32"
                cy="50"
                r={this.ascertainPercentageValue(
                  this.props.value2,
                  this.props.total1
                )}
                style={{ fill: this.props.color2 }}
              />
              <circle
                cx="307.195"
                cy="50"
                r={this.ascertainPercentageValue(
                  this.props.value3,
                  this.props.total1
                )}
                style={{ fill: this.props.color3 }}
              />
              <circle
                cx="430.0625"
                cy="50"
                r={
                  this.customTotalRadius ?
                    this.customTotalRadius :
                    this.state.defaultTotalRadius
                }
                style={{ fill: this.props.color4 }}
              />
            </g>
          </svg>
        </div>
      </div>
    );
  }

  /* eslint-enable eqeqeq */
  /* eslint-enable max-len */
}

GraphComponent.propTypes = {
  className: PropTypes.string,
  customTotalRadius: PropTypes.string,
  headings: PropTypes.bool,
  color1: PropTypes.string,
  color2: PropTypes.string,
  color3: PropTypes.string,
  color4: PropTypes.string,
  color5: PropTypes.string,
  color6: PropTypes.string,
  color7: PropTypes.string,
  color8: PropTypes.string,
  color9: PropTypes.string,
  color10: PropTypes.string,
  color11: PropTypes.string,
  color12: PropTypes.string,
  total1: PropTypes.number,
  total2: PropTypes.number,
  total3: PropTypes.number,
  toolTipHighlight1: PropTypes.string,
  toolTipHighlight2: PropTypes.string,
  toolTipHighlight3: PropTypes.string,
  toolTipHighlight4: PropTypes.string,
  inactiveColor: PropTypes.string,
  legend1: PropTypes.string,
  legend2: PropTypes.string,
  legend3: PropTypes.string,
  legend4: PropTypes.string,
  value1: PropTypes.number,
  value2: PropTypes.number,
  value3: PropTypes.number,
  value4: PropTypes.number,
  value5: PropTypes.number,
  value6: PropTypes.number,
  value7: PropTypes.number,
  value8: PropTypes.number,
  value9: PropTypes.number,
  helpToolTips: PropTypes.array,
  currency: PropTypes.string,
  directPercentageOfRowAbove: PropTypes.number,
  indirectPercentageOfRowAbove: PropTypes.number,
  inducedPercentageOfRowAbove: PropTypes.number,
  totalPercentageOfRowAbove: PropTypes.number,
};
