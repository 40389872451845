import "./styles.scss";

import PropTypes from "prop-types";
import React from "react";
import { COUNTRIES, formatCountrys } from "../../shared/constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import dataActions from "../../shared/data_actions";

import { updateMetaImages } from "../../../utils/updateMetaImages";

export default class CountryLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = { visible: false };
  }

  visibility(show) {
    this.setState({
      visible: show,
    });
  }

  handleCountryClick(country) {
    if (country !== "uk") {
      this.props.setYear(2021);
    }
    this.props.setCountry(country);
    this.props.setData(country);
    updateMetaImages(country);
  }

  render() {
    const className = "CountryLinks";
    const menu = this.state.visible ?
      "CountryLinks-hoverMenu CountryLinks-hoverMenu--visible" :
      "CountryLinks-hoverMenu";
    const menuWrapper = "CountryLinks-hoverMenu-wrapper";

    const currentCountry = this.props.country;

    return (
      <div className={className}>
        <div className={menu}>
          <div className={menuWrapper}>
            {COUNTRIES.map((country, k) => {
              const switchClasses =
                k === currentCountry ?
                  [
                      "InfoPaneContent__year-switch__year",
                      "InfoPaneContent__year-switch__year--active",
                    ] :
                  ["InfoPaneContent__year-switch__year"];

              return (
                <a
                  className={switchClasses.join(" ")}
                  onClick={this.handleCountryClick.bind(this, country)}
                  target="_blank"
                  key={country}
                >
                  {formatCountrys[country]}
                </a>
              );
            })}
          </div>
        </div>
        <div
          onMouseOver={this.visibility.bind(this, true)}
          onMouseLeave={this.visibility.bind(this, false)}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

CountryLinks.propTypes = {
  children: PropTypes.object,
  country: PropTypes.number,
  setCountry: PropTypes.func,
  setYear: PropTypes.func,
  setData: PropTypes.func,
};
function mapStateToProps(state) {
  // state
  return { country: state.data.get("country"), ...state };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setCountry: dataActions.setCountry,
      setYear: dataActions.setYear,
      setData: dataActions.setData,
    },
    dispatch
  );
}

export const CountryLinksContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CountryLinks);
