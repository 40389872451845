const init = {
  copyPopup: false,
  copyUrl: "",
};

export default (state = init, action) => {
  switch (action.type) {
    case "SHOW_COPY_POPUP":
      return {
        copyPopup: true,
        copyUrl: action.text,
      };
    case "HIDE_COPY_POPUP":
      return {
        copyPopup: false,
      };
    default:
      return state;
  }
};
