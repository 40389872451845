export const COLORS_LEGEND = [
  "#1ABBFF",
  "#448AD4",
  "#59629D",
  "#805795",
  "#BC3890",
  "#FF2E95",
];

export const COLORS = [
  "#1ABBFF",
  "#448AD4",
  "#59629D",
  "#805795",
  "#BC3890",
  "#FF2E95",
  ];

export const YEARS = [2015, 2016, 2017, 2019, 2021];

export const COUNTRIES = ["uk", "usa", "canada"];

export const AREAS = ["region", "state", "province"];
export const currencyArray = ["£", "$", "CA$"];

export const formatCountrys = {
  uk: "U.K.",
  usa: "U.S.",
  canada: "Canada",
};

