import { HashRouter, Route, Switch } from "react-router-dom";

import App from "../app";
import { Home } from "../views";
import React from "react";

export default () => (
  <HashRouter component={App}>
    <Switch>
      <Route path="/:country/:year/:location" component={Home} />
      <Route path="*" component={Home} />
    </Switch>
  </HashRouter>
);
