var assign = require('object-assign');

var animateScroll = require('./animate-scroll');
var events = require('./scroll-events');

var __mapped = {};
var __activeLink = [];
var __currentlyInside;

module.exports = {

  unmount: function() {
    __mapped = {};
  },

  register: function(name, element){
    console.log(arguments);
    __mapped[name] = element;
  },

  unregister: function(name) {
    delete __mapped[name];
  },

  get: function(name) {
    return __mapped[name];
  },

  setActiveLink: function(link) {
    __activeLink.push(link);
  },

  setCurrentlyInside: function(link) {
    __currentlyInside = link;
  },

  getCurrentlyInside: function() {
    return __currentlyInside;
  },

  setCurrentlyOutside: function() {
    __currentlyInside = false;
  },

  removeAnActiveLink: function(link) {
    var what, a = arguments, L = a.length, ax;
    while (L && __activeLink.length) {
        what = a[--L];
        while ((ax = __activeLink.indexOf(what)) !== -1) {
            __activeLink.splice(ax, 1);
        }
    }
    return __activeLink;
  },

  getActiveLink: function() {
    return __activeLink;
  },

  scrollTo: function(to, props) {

     /*
     * get the mapped DOM element
     */

      var target = this.get(to);

      if(!target) {
        throw new Error("target Element not found");
      }

      props = assign({}, props, { absolute : false });


      if(events.registered['begin']) {
        events.registered['begin'](to, target);
      }

      var containerId = props.containerId;
      var containerElement = containerId ? document.getElementById(containerId) : null;

      var scrollOffset;

      if(containerId && containerElement) {
        props.absolute = true;
        if(containerElement !== target.offsetParent) {
          if(!containerElement.contains(target)) {
            throw new Error('Container with ID ' + containerId + ' is not a parent of target ' + to);
          } else {
            throw new Error('Container with ID ' + containerId + ' is not a positioned element');
          }
        }

        scrollOffset = target.offsetTop;
      } else {
        var coordinates = target.getBoundingClientRect();
        var bodyRect = document.body.getBoundingClientRect();
        scrollOffset = coordinates.top;
      }

      scrollOffset += (props.offset || 0);


      /*
       * if animate is not provided just scroll into the view
       */
      if(!props.smooth) {
        if(containerId && containerElement) {
          containerElement.scrollTop = scrollOffset;
        } else {
          window.scrollTo(0, scrollOffset);
        }

        if(events.registered['end']) {
          events.registered['end'](to, target);
        }

        return;
      }

      /*
       * Animate scrolling
       */

      animateScroll.animateTopScroll(scrollOffset, props, to, target);
  }
};
