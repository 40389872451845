import "./styles.scss";

import { BreakdownGraph } from "..";
import DownloadLinks from "../download_links";
import PropTypes from "prop-types";
import React from "react";
import ShareIcon from "./share-icon.inline.svg";
import { SharingLinks } from "..";
import { currencyArray } from "../../shared/constants";

const formatJobNumber = (nr) => nr.toLocaleString();
const formatCurrency = (v, digits = 0, currency = "£") =>
  `${currency}${v.toFixed(digits)} mn`;

function generateShareUrl(props, key) {
  let copy = props.currentData[key];
  const areaData = props.currentData;

  const jobsInduced = formatJobNumber(areaData.jobsInduced[props.year]);
  const jobsTotal = formatJobNumber(areaData.jobsTotal[props.year]);
  const economicTotal = formatCurrency(
    areaData.economicTotal[props.year],
    0,
    currencyArray[props.country]
  );

  copy = copy.replace("{jobsInduced}", jobsInduced);
  copy = copy.replace("{jobsTotal}", jobsTotal);
  copy = copy.replace("{economicTotal}", economicTotal);

  return copy;
}

const InfoPaneContent = (props) => (
  /* eslint-disable max-len */
  <div id={props.id} className="InfoPane-content">
    <h1 className="InfoPaneContent-heading">{props.AreaData.displayTitle}</h1>

    {props.AreaData.economicTotal ? (
      <p className="InfoPaneContent-lede">
        <span className="InfoPaneContent-standout">
          <span className="u-extraBold">
            {formatJobNumber(props.AreaData.jobsTotal[props.year])}
          </span>
          jobs supported
        </span>
        <span className="InfoPaneContent-standout">
          <span className="u-extraBold">
            {`${formatCurrency(
              props.AreaData.economicTotal[props.year],
              1,
              currencyArray[props.country]
            )} `}
          </span>
          Drax economic impact
        </span>
      </p>
    ) : (
      ""
    )}

    {props.AreaData.introSentence ? (
      <p className="InfoPaneContent-lede InfoPaneContent-lede--subText">
        {props.AreaData.introSentence}
      </p>
    ) : null}

    <div className="InfoPaneContent-graphs">
      <div className="InfoPaneContent-graphsTitles">
        <h3 className="InfoPaneContent-graphTitle InfoPaneContent-graphTitle--main">
          Breakdown of impact
        </h3>
        <h4 className="InfoPaneContent-graphTitle InfoPaneContent-graphTitle--employment">
          <span className="u-bold">Employment</span> <br />
          (jobs)
        </h4>
        <h4 className="InfoPaneContent-graphTitle InfoPaneContent-graphTitle--economic">
          <span className="u-bold">
            Economic <br />
            Contribution
          </span>{" "}
          <br />
          {`(${currencyArray[props.country]} mn)`}
        </h4>
      </div>

      {props.AreaData.jobsDirect ? (
        <div className="InfoPaneContent-graphsInner">
          <span className="InfoPaneContent-standout InfoPaneContent-standout--mobile InfoPaneContent-standout--top">
            <span className="u-extraBold">Employment</span> (jobs)
          </span>

          <BreakdownGraph
            customTotalRadius="45"
            total1={props.AreaData.jobsTotal[props.year]}
            value1={props.AreaData.jobsDirect[props.year]}
            value2={props.AreaData.jobsIndirect[props.year]}
            value3={props.AreaData.jobsInduced[props.year]}
            total2={props.AreaData.economicTotal[props.year]}
            value4={props.AreaData.economicDirect[props.year]}
            value5={props.AreaData.economicIndirect[props.year]}
            value6={props.AreaData.economicInduced[props.year]}
            color1="#B6C93E"
            color2="#65AC85"
            color3="#2C4491"
            color4="#5494D2"
            color5="#C4D35D"
            color6="#7ABC9D"
            color7="#5166A6"
            color8="#68AADC"
            toolTipHighlight1="#CCDA6F"
            toolTipHighlight2="#88C4A9"
            toolTipHighlight3="#6579B1"
            toolTipHighlight4="#76B4E0"
            inactiveColor="#e6dfdf"
            headings
            legend1="Direct"
            legend2="Indirect"
            legend3="Induced"
            legend4="Total"
            helpToolTips={props.SharedData.helpToolTips}
            className="InfoPaneContent-topGraph"
            currency={currencyArray[props.country]}
          />

          <span className="InfoPaneContent-standout InfoPaneContent-standout--mobile InfoPaneContent-standout--bottom">
            <span className="u-extraBold">Economic Contribution</span>
            {` (${currencyArray[props.country]} mn)`}
          </span>

          <BreakdownGraph
            customTotalRadius="45"
            total1={props.AreaData.jobsTotal[props.year]}
            value1={props.AreaData.jobsDirect[props.year]}
            value2={props.AreaData.jobsIndirect[props.year]}
            value3={props.AreaData.jobsInduced[props.year]}
            color1="#C4D35D"
            color2="#7ABC9D"
            color3="#5166A6"
            color4="#68AADC"
            toolTipHighlight1="#CCDA6F"
            toolTipHighlight2="#88C4A9"
            toolTipHighlight3="#6579B1"
            toolTipHighlight4="#76B4E0"
            inactiveColor="#e6dfdf"
            headings
            legend1="Direct"
            legend2="Indirect"
            legend3="Induced"
            legend4="Total"
            helpToolTips={props.SharedData.helpToolTips}
            className="InfoPaneContent-mobileOnly"
            currency={currencyArray[props.country]}
          />
        </div>
      ) : (
        ""
      )}
    </div>

    {props.AreaData.imageUrl ? (
      <div className="InfoPaneContent-detailPanel">
        <img src={props.AreaData.imageUrl} className="InfoPaneContent-image" />

        <div className="InfoPaneContent-detailPanel-overlay">
          <h2 className="CaseStudyHeader">{props.AreaData.caseStudyHeader}</h2>
          <p>{props.AreaData.caseStudy}</p>
        </div>
      </div>
    ) : null}

    {props.AreaData.jobsInduced ? (
      <div className="InfoPaneContent-toolsPanel">
        <SharingLinks
          sharingImage={props.AreaData.sharingImage}
          facebookString={generateShareUrl(props, "facebookString")}
          twitterString={generateShareUrl(props, "twitterString")}
          linkedinString={generateShareUrl(props, "linkedinString")}
          sharingLink={props.AreaData.sharingLink}
        >
          <a className="InfoPaneContent-sharingLink">
            <ShareIcon />
            Share this page
          </a>
        </SharingLinks>
        <DownloadLinks>
          <a className="InfoPaneContent-downloadLink">
            <ShareIcon />
            Download report
          </a>
        </DownloadLinks>
        <div className="desktopFooter-container">
          <div>
            <a className="desktopFooter-link ot-sdk-show-settings InfoPaneContent-cookieLink">
              Cookie settings
            </a>
            <a
              className="desktopFooter-link InfoPaneContent-privacyLink"
              href="https://www.drax.com/drax-impact-use-of-cookies/"
            >
              Privacy policy
            </a>
          </div>
        </div>
      </div>
    ) : (
      ""
    )}

    {props.AreaData.imageUrl ? (
      <img
        src={props.AreaData.imageUrl}
        className="InfoPaneContent-mobileImage"
      />
    ) : null}
  </div>
);

/* eslint-enable max-len */


InfoPaneContent.propTypes = {
  id: PropTypes.string,
  AreaData: PropTypes.object,
  SharedData: PropTypes.object,
  year: PropTypes.number,
  country: PropTypes.number,
};

export default InfoPaneContent;
