import { YEARS } from "./constants";

export function indexToYear(key) {
  return key in YEARS ? YEARS[key] : 2017;
}

export function yearToIndex(year) {
  const key = YEARS.indexOf(year);
  return key > -1 ? key : 2;
}
