import "./styles.scss";

import Close from "./close.inline.svg";
import PropTypes from "prop-types";
import React from "react";
import { YEARS } from "../../shared/constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import dataActions from "../../shared/data_actions";
import infoPaneActions from "../infopane/info_pane_actions.js";

/* eslint-disable max-len */

export default class MobileDropdownView extends React.Component {
  close() {
    this.props.mobileYearChanged({ open: false });
  }

  handleYearClick(year) {
    this.props.setYear(year);
    this.close();
  }

  render() {
    const classNames = `MobileDropdown MobileDropdown--year ${this.props.active ? "active" : ""}`;
    const currentYear = this.props.year;

    return (
      <div className={classNames}>
        {YEARS.map((year, k) => {
          const switchClasses =
            k === currentYear ?
              [
                "InfoPaneContent__year-switch__year",
                "InfoPaneContent__year-switch__year--active",
              ] :
              ["InfoPaneContent__year-switch__year"];

          return (
            <a
              className={switchClasses.join(" ")}
              onClick={this.handleYearClick.bind(this, year)}
              target="_blank"
              key={year}
            >
              {year}
            </a>
          );
        })}
        <Close
          className="MobileDropdown-close MobileDropdown-close--dark"
          onClick={this.close.bind(this)}
        />
      </div>
    );
  }
}

MobileDropdownView.propTypes = {
  mobileYearChanged: PropTypes.any,
  active: PropTypes.bool,
  year: PropTypes.number,
  setYear: PropTypes.func,
};

function mapStateToProps(state) {
  // state
  return { year: state.data.get("year"), ...state };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...infoPaneActions,
      setYear: dataActions.setYear,
    },
    dispatch
  );
}

export const MobileDropdownContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileDropdownView);
