export function updateMetaImages(country) {
  const twitter = document.querySelector("meta[name=\"twitter:image\"]");
  if (twitter) {
    twitter.setAttribute(
      "content",
      `https://draximpact.co.uk/public/assets/images/social-assets/twitter/DRAX_${country}.png`
    );
  }
  const og = document.querySelector("meta[property=\"og:image\"]");
  if (og) {
    og.setAttribute(
      "content",
      `https://draximpact.co.uk/public/assets/images/social-assets/twitter/DRAX_${country}.png`
    );
  }
}
