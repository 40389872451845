import "./styles.scss";

import Close from "./close.inline.svg";
import PropTypes from "prop-types";
import React from "react";
import { COUNTRIES } from "../../shared/constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import dataActions from "../../shared/data_actions";
import infoPaneActions from "../infopane/info_pane_actions.js";
import { updateMetaImages } from "../../../utils/updateMetaImages";

/* eslint-disable max-len */

export default class MobileDropdownView extends React.Component {
  close() {
    this.props.mobileCountryChanged({ open: false });
  }

  handleCountryClick(country) {
    this.props.setCountry(country);
    this.props.setData(country);
    this.close();
    updateMetaImages(country);
  }

  render() {
    const classNames = `MobileDropdown MobileDropdown--country ${
      this.props.active ? "active" : ""
    }`;
    const currentYear = this.props.country;

    return (
      <div className={classNames}>
        {COUNTRIES.map((country, k) => {
          const switchClasses =
            k === currentYear ?
              [
                  "InfoPaneContent__country-switch__country",
                  "InfoPaneContent__country-switch__country--active",
                ] :
              ["InfoPaneContent__country-switch__country"];

          return (
            <a
              className={switchClasses.join(" ")}
              onClick={this.handleCountryClick.bind(this, country)}
              target="_blank"
              key={country}
            >
              {country}
            </a>
          );
        })}
        <Close
          className="MobileDropdown-close MobileDropdown-close--dark"
          onClick={this.close.bind(this)}
        />
      </div>
    );
  }
}

MobileDropdownView.propTypes = {
  mobileCountryChanged: PropTypes.any,
  active: PropTypes.bool,
  country: PropTypes.number,
  setCountry: PropTypes.func,
  setData: PropTypes.func,
};

function mapStateToProps(state) {
  // state
  return { country: state.data.get("country"), ...state };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...infoPaneActions,
      setCountry: dataActions.setCountry,
      setData: dataActions.setData,
    },
    dispatch
  );
}

export const MobileDropdownContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileDropdownView);
