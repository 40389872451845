import "./styles.scss";

import PropTypes from "prop-types";
import React from "react";
import { indexToYear } from "../../shared/year_map";
import { currencyArray } from "../../shared/constants";

const formatJobNumber = (nr) => nr.toLocaleString();
const formatCurrency = (v, digits = 0, currency = "£") =>
  `${currency}${v.toFixed(digits)} mn`;

const MapToolTipContent = (props) => (
  <div>
    <h4 className="MapToolTip-title">
      {props.toolTip.data.displayTitle} &nbsp; (
      {indexToYear(props.toolTip.year)})
    </h4>
    <span className="MapToolTip-stat">
      {formatJobNumber(props.toolTip.data.jobsTotal[props.toolTip.year])}
    </span>
    jobs supported{" "}
    {props.toolTip.data.economicTotal &&
      props.toolTip.data.economicTotal[props.toolTip.year] && (
        <span className="MapToolTip-stat">
          {formatCurrency(
            props.toolTip.data.economicTotal[props.toolTip.year],
            1,
            currencyArray[props.country]
          )}
        </span>
      )}
    Drax economic <br />
    impact
  </div>
);


MapToolTipContent.propTypes = {
  toolTip: PropTypes.object,
  country: PropTypes.number,
};


export default MapToolTipContent;
