var React = require('react');
var ReactDOM = require('react-dom');
import PropTypes from 'prop-types';
var createReactClass = require('create-react-class');

var animateScroll = require('./animate-scroll');
var scrollSpy = require('./scroll-spy');
var defaultScroller = require('./scroller');
var assign = require('object-assign');


var protoTypes = {
  to: PropTypes.string,
  containerId: PropTypes.string,
  activeClass: PropTypes.string,
  spy: PropTypes.bool,
  smooth: PropTypes.bool,
  offset: PropTypes.number,
  delay: PropTypes.number,
  isDynamic: PropTypes.bool,
  onClick: PropTypes.func,
  duration: PropTypes.oneOfType([PropTypes.number, PropTypes.func])
};


var Helpers = {

  Scroll: function (Component, customScroller) {

    var scroller = customScroller || defaultScroller;

    return createReactClass({

      propTypes: protoTypes,

      getDefaultProps: function () {
        return { offset: 0 };
      },

      scrollTo: function (to, props) {
        scroller.scrollTo(to, props);
      },

      handleClick: function (event) {

        /*
         * give the posibility to override onClick
         */

        if (this.props.onClick) {
          this.props.onClick(event);
        }

        /*
         * dont bubble the navigation
         */

        if (event.stopPropagation) event.stopPropagation();
        if (event.preventDefault) event.preventDefault();

        /*
         * do the magic!
         */
        this.scrollTo(this.props.to, this.props);

      },

      UNSAFE_componentWillMount: function () {
        this.setState({ currentlyInside: false });
      },

      spyHandler: function (y) {
        var offsetY = y - (this.props.offsetActive ? this.props.offset : 0);
        if (!this.props.multipleElements) {
          var element = scroller.get(this.props.to);
          if (!element) return;
          var cords = element.getBoundingClientRect();
          var topBound = cords.top + y;
          var bottomBound = topBound + cords.height;
          var to = this.props.to;
        } else {
          var multipleElements = this.props.multipleElements;
          var arrayOfElements = [];

          for (var i = 0; i < multipleElements.length; i++) {
            arrayOfElements.push(scroller.get(multipleElements[i]));
            arrayOfElements[i].cords = arrayOfElements[i].getBoundingClientRect();
            arrayOfElements[i].elemTopBound = (arrayOfElements[i].cords.top + y);
            arrayOfElements[i].elemBottomBound = arrayOfElements[i].elemTopBound + arrayOfElements[i].cords.height;
            var isInside = (offsetY >= Math.floor(arrayOfElements[i].elemTopBound) && offsetY <= Math.floor(arrayOfElements[i].elemBottomBound));
            var isOutside = (offsetY < Math.floor(elemTopBound) || offsetY > Math.floor(elemBottomBound));

            if (isInside && (multipleElements[i] !== this.state.currentlyInside)) {

              if (this.state.currentlyInside) {
                scroller.get(this.state.currentlyInside)
                  .classList.remove('InfoPane-content--active');
              }

              scroller.get(multipleElements[i]).classList.add('InfoPane-content--active');
              scroller.setCurrentlyInside(multipleElements[i]);
              this.setState({ currentlyInside: multipleElements[i] });
              this.props.onNewActiveSection(multipleElements[i]);
            }
          }
        }

        if (!this.props.multipleElements) {
          var offsetY = y - (this.props.offsetActive ? this.props.offset : 0);
          var isInside = (offsetY >= Math.floor(elemTopBound));
          var isOutside = (offsetY < Math.floor(elemTopBound));
          var activeLink = scroller.getActiveLink();

          if (isOutside && activeLink.indexOf(to) > -1) {
            scroller.removeAnActiveLink(to);
            this.setState({ active: false });

          } else if (isInside && activeLink.indexOf(to) === -1) {
            scroller.setActiveLink(to);
            this.setState({ active: true });

            if (this.props.onSetActive) {
              this.props.onSetActive(to);
            }
          }
        }

        scrollSpy.updateStates();
      },

      componentDidMount: function () {

        var containerId = this.props.containerId;

        var scrollSpyContainer = containerId ? document.getElementById(containerId) : document;

        if (!scrollSpy.isMounted(scrollSpyContainer)) {
          scrollSpy.mount(scrollSpyContainer);
        }

        if (this.props.spy) {
          var to = this.props.to;
          var element = null;
          var elemTopBound = 0;
          var elemBottomBound = 0;

          scrollSpy.addStateHandler((function () {
            if (scroller.getActiveLink().indexOf(to) === -1) {
              this.setState({ active: false });
            }
          }).bind(this));

          var spyHandler = function (y) {
            if (!element || this.props.isDynamic || this.props.multipleElements) {

              if (this.props.multipleElements) {
                var offsetY = y - (this.props.offsetActive ? this.props.offset : 0);
                var multipleElements = this.props.multipleElements;
                var arrayOfElements = [];

                for (var i = 0; i < multipleElements.length; i++) {
                  arrayOfElements.push(scroller.get(multipleElements[i]));
                  arrayOfElements[i].cords = arrayOfElements[i].getBoundingClientRect();
                  arrayOfElements[i].elemTopBound = (arrayOfElements[i].cords.top + y);
                  arrayOfElements[i].elemBottomBound = arrayOfElements[i].elemTopBound + arrayOfElements[i].cords.height;
                  var isInside = (offsetY >= Math.floor(arrayOfElements[i].elemTopBound) && offsetY <= Math.floor(arrayOfElements[i].elemBottomBound));
                  var isOutside = (offsetY < Math.floor(elemTopBound) || offsetY > Math.floor(elemBottomBound));

                  if (isInside && (multipleElements[i] !== this.state.currentlyInside)) {
                    if (scroller.get(this.state.currentlyInside)) {
                      scroller
                        .get(this.state.currentlyInside)
                        .classList.remove("InfoPane-content--active");
                    }

                    scroller
                      .get(multipleElements[i])
                      .classList.add("InfoPane-content--active");
                    scroller.setCurrentlyInside(multipleElements[i]);
                    this.setState({ currentlyInside: multipleElements[i] });
                    this.props.onNewActiveSection(multipleElements[i]);
                  }
                }
              }
            }

            if (!this.multipleElements) {

              element = scroller.get(to);
              if (!element) { return; }
              var cords = element.getBoundingClientRect();
              elemTopBound = (cords.top + y);
              elemBottomBound = elemTopBound + cords.height;
              var offsetY = y - (this.props.offsetActive ? this.props.offset : 0);
              var isInside = (offsetY >= Math.floor(elemTopBound));
              var isOutside = (offsetY < Math.floor(elemTopBound));
              var activeLink = scroller.getActiveLink();

              if (isOutside && activeLink.indexOf(to) > -1) {
                scroller.removeAnActiveLink(to);
                this.setState({ active: false });

              } else if (isInside && activeLink.indexOf(to) === -1) {
                scroller.setActiveLink(to);
                this.setState({ active: true });

                if (this.props.onSetActive) {
                  this.props.onSetActive(to);
                }
                // scrollSpy.updateStates();
              }
            }

            scrollSpy.updateStates();

          }.bind(this);

          scrollSpy.addSpyHandler(spyHandler, scrollSpyContainer);
        }
      },
      componentWillUnmount: function () {

      },
      render: function () {

        var props = assign({}, this.props);

        var className = "";
        if (this.state && this.state.active) {
          className = ((this.props.className || "") + " " + (this.props.activeClass || "active")).trim();
          props.scrolledPast = true;
        } else if (this.state && this.state.currentlyInside) {
          className = ((this.props.className || "") + " " + (this.props.activeClass || this.state.currentlyInside)).trim();
        } else {
          className = this.props.className;
        }

        for (var prop in protoTypes) {
          if (props.hasOwnProperty(prop)) {
            delete props[prop];
          }
        }

        props.className = className;
        props.onClick = this.handleClick;

        return React.createElement(Component, props);
      }
    });
  },


  Element: function (Component) {
    return createReactClass({
      propTypes: {
        name: PropTypes.string.isRequired
      },
      componentDidMount: function () {
        var domNode = ReactDOM.findDOMNode(this);
        defaultScroller.register(this.props.name, domNode);
      },
      componentWillUnmount: function () {
        defaultScroller.unregister(this.props.name);
      },
      render: function () {
        return React.createElement(Component, this.props);
      }
    });
  }
};

export default Helpers;
