import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { scroller } from "../../../modules/utilities/scroll-spy";

import MobileSearchActions from "./mobile_search_actions";
import infoPaneActions from "../infopane/info_pane_actions.js";
import SearchItem from "./search_item";

import "./styles.scss";
import Close from "../mobile_share_dropdown/close.inline.svg";

/* eslint-disable max-len */

class MobileDropdownView extends React.Component {
  UNSAFE_componentWillMount() {
    this.setState({
      filteredData: false,
    });
  }

  close() {
    this.props.mobileSearchChanged({ open: false });
  }

  search(event) {
    const queryText = event.target.value;
    const queryResult = [];

    this.props.items.forEach((item) => {
      if (item.toLowerCase().indexOf(queryText.toLowerCase()) !== -1) {
        queryResult.push(item);
      }
    });

    this.setState({
      query: queryText,
      filteredData: queryResult,
    });
  }

  clickItem(item, dispatchClosed) {
    dispatchClosed({ open: false });

    scroller.scrollTo(item, {
      duration: 500,
      delay: 0,
      smooth: true,
    });
  }

  renderResults() {
    const resultsToRender = [];
    let itemsToFilter = this.props.items;

    if (this.state.filteredData) {
      itemsToFilter = this.state.filteredData;
    }

    itemsToFilter.forEach((item) => {
      /* eslint-disable max-len */

      const jsxToPush = <SearchItem itemClicked={this.clickItem} dispatchClose={this.props.mobileSearchChanged} key={item} item={item} />;

      /* eslint-enable max-len */

      resultsToRender.push(jsxToPush);
    });

    return resultsToRender;
  }

  render() {
    const classNames = `MobileDropdown ${this.props.active ? "active" : ""}`;
    return (
      <div className={classNames}>

        <Close
          className="MobileDropdown-close"
          onClick={this.close.bind(this)}
        />
        <input
          className="MobileSearchDropdown-searchBar"
          onKeyUp={this.search.bind(this)}
          type="text"
        />
        {this.renderResults(this.props.items)}
      </div>
    );
  }
}

MobileDropdownView.propTypes = {
  data: PropTypes.object,
  items: PropTypes.array,
  mobileSearchChanged: PropTypes.func,
  active: PropTypes.bool,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...MobileSearchActions,
    infoPaneActions,
  }, dispatch);
}

export const MobileDropdown = connect(
  null,
  mapDispatchToProps,
)(MobileDropdownView);
