import "./styles.scss";
import PropTypes from "prop-types";
import Link from "../../utilities/scroll-spy/components/Link";

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import dataActions from "../../shared/data_actions";
import { COUNTRIES } from "../../shared/constants";
import { updateMetaImages } from "../../../utils/updateMetaImages";


const CountrySelectorSection = ({ setCountry, setData }) => {
  const isMobile = window.innerWidth < 600;
  const countryMap = !isMobile ?
    {
        uk: "United Kingdom",
        usa: "United States",
        canada: "Canada",
      } :
    {
        uk: "U.K.",
        usa: "U.S.",
        canada: "CA",
      };

  return (
    <section
      id="countrySelector"
      className="IntroElement-countrySelectorWrapper"
    >
      <h3>Select a country</h3>
      <div className="IntroElement-countrySelector">
        {COUNTRIES.map((country) => {
          return (
            <Link
              activeClass="active"
              to="contentStart"
              smooth
              offset={0}
              duration={500}
              key={Math.random()}
            >
              <div
                onClick={() => {
                  setCountry(country);
                  setData(country);
                  updateMetaImages(country);
                }}
              >
                <div>
                  <img src={`/public/assets/images/countries/${country}.png`} />
                </div>
                <button>{countryMap[country]}</button>
              </div>
            </Link>
          );
        })}
      </div>
    </section>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setCountry: dataActions.setCountry,
      setData: dataActions.setData,
    },
    dispatch
  );
}
CountrySelectorSection.propTypes = {
  setCountry: PropTypes.func,
  setData: PropTypes.func,
};



export const CountrySelector = connect(
  null,
  mapDispatchToProps
)(CountrySelectorSection);
