import React from "react";
import PropTypes from "prop-types";
import Helpers from "../../utilities/scroll-spy/mixins/Helpers";

import "./styles.scss";

class ScrollContainerElement extends React.Component {
  render() {
    return (
      <div className={this.props.className}>
        { React.cloneElement(this.props.children, this.props) }
      </div>
    );
  }
}

ScrollContainerElement.propTypes = {
  className: PropTypes.string,
  children: PropTypes.object,
};

const ScrollContainer = Helpers.Scroll(ScrollContainerElement);

export default ScrollContainer;
