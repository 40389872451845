/* eslint-disable */
const sharedData = {
  helpToolTips: [
    "The economic activity generated through the day-to-day running of Drax Group's businesses",
    "The economic activity and employment supported by Drax's purchase of goods and services from its supply chain",
    "The economic activity supported by our employees and our suppliers' employees purchasing good and services in their local area",
  ],
  download_links: [
    "/public/Oxford_Economics-Economic_impact_of_Drax_in_the_UK_2015.pdf",
    "/public/Oxford_Economics-Economic_impact_of_Drax_in_the_UK_2016.pdf",
    "/public/Oxford_Economics-Economic_impact_of_Drax_in_the_UK_2017.pdf",
    "/public/Oxford_Economics-Economic_impact_of_Drax_in_the_UK_2019.pdf",
    "/public/Oxford_Economics-Economic_impact_of_Drax_in_the_UK_2022.pdf",
  ],
};
/* eslint-enable */
export default sharedData;
