import "./styles.scss";
import { AREAS, COUNTRIES } from "../../shared/constants";

import MapActions from "./map_actions";
import MapLegend from "../map_legend";
import UKMapSVGContainer from "./uk_map_svg_container";
import USAMapSVGContainer from "./usa_map_svg_container";
import CanadaMapSVGContainer from "./canada_map_svg_container";
import MapToolTipContent from "./map_tooltip_content";
import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import { ToolTip } from "..";
import { bindActionCreators } from "redux";
import camelToHyphen from "../../../utils/camelToHyphen.js";
import { connect } from "react-redux";
import { indexToYear } from "../../shared/year_map";
import { indexToCountry } from "../../shared/country_map";
import { scroller } from "../../../modules/utilities/scroll-spy";

export default class Map extends React.Component {
  UNSAFE_componentWillMount() {
    this.setState({
      toolTipPosition: {
        left: 0,
        top: 0,
      },
      currentArea: false,
      previousArea: false,
      toolTipVisible: false,
    });
  }

  componentDidMount() {
    this.setMapArea();
  }

  componentDidUpdate() {
    this.setMapArea();
  }

  setMapArea() {
    const value = this.props.area.get("active_area").location;

    if (value !== this.state.currentLocation) {
      // if (!this.mapDomNode) {
      this.mapDomNode = ReactDOM.findDOMNode(this.mapElement);

      // }

      this.setActiveMapArea(
        this.props.area.get("active_area"),
        this.mapDomNode
      );
    }
  }

  getClassName(hovered) {
    const value = hovered ? "SvgContainer--validMapHover" : "";
    return `SvgContainer ${value}`;
  }

  getMapClass(activeArea) {
    const value = activeArea.location ? "MapSVG--activeArea" : "";
    return `MapSVG ${value}`;
  }

  setActiveMapArea(activeArea, map) {
    const value = activeArea.location;
    let previousLocationPath;
    let prevOtherLocationPath;
    let currentLocationPath;
    let otherLocationPath;
    const country = indexToCountry(this.props.data.get("country"));
    const countryShorthand = country.substring(0, 2);
    if (value) {
      if (!activeArea.previousLocation && !this.state.currentLocation) {
        currentLocationPath = map.querySelector(
          `#${countryShorthand}Svg-${activeArea.location}`
        );
        otherLocationPath = map.querySelector(
          `#${countryShorthand}Arc-${activeArea.location}`
        );
        if (currentLocationPath) { currentLocationPath.classList.add("activeArea"); }
        if (otherLocationPath) { otherLocationPath.classList.add("activeArea"); }
        this.setState({
          currentLocation: value,
          currentLocationPath,
          otherLocationPath,
        });
      } else if (value !== this.state.currentLocation) {
        previousLocationPath = this.state.currentLocationPath;
        prevOtherLocationPath = this.state.otherLocationPath;
        currentLocationPath = map.querySelector(
          `#${countryShorthand}Svg-${activeArea.location}`
        );
        otherLocationPath = map.querySelector(
          `#${countryShorthand}Arc-${activeArea.location}`
        );
        if (currentLocationPath) { currentLocationPath.classList.add("activeArea"); }
        if (otherLocationPath) {
          otherLocationPath.classList.add("activeArea");
        }
        if (previousLocationPath) {
          previousLocationPath.classList.remove("activeArea");
        }
        if (prevOtherLocationPath) {
          prevOtherLocationPath.classList.remove("activeArea");
        }
        this.setState({
          currentLocation: value,
          currentLocationPath,
          otherLocationPath,
        });
      }
    } else if (this.state.currentLocationPath) {
      this.state.currentLocationPath.classList.remove("activeArea");

      this.setState({
        currentLocation: false,
        currentLocationPath: false,
      });
      if (this.state.otherLocationPath) {
        this.state.otherLocationPath.classList.remove("activeArea");
        this.setState({
          otherLocationPath: false,
        });
      }
    }
  }

  clipMapNamespace(target) {
    return !target ? "restOfCanada" : target.substring(6);
  }

  hoverRegionGroup(event, show) {
    // show islands who are part of same region, yet not same shape
    const shapeGroup = event.target.parentNode.id;
    const isRegionGroup =
      shapeGroup === "ukSvg-scotland" || shapeGroup === "ukSvg-wales";

    if (isRegionGroup && show) {
      // show hover for other shapes in same region
      const elements = event.target.parentNode.querySelectorAll("path");
      for (let i = 0; i < elements.length; i++) {
        elements[i].classList.add("hover");
      }
    } else {
      // hide all region hovers
      const elements = document.querySelectorAll(".MapSVG path");

      for (let i = 0; i < elements.length; i++) {
        elements[i].classList.remove("hover");
      }
    }
  }

  mapSectionEnter(event) {
    let target = !event.target.id ?
      event.target.parentNode.id :
      event.target.id;

    if (event.target.parentNode.id === "ukSvg-scotland") {
      target = "ukSvg-scotland";
    }
    if (event.target.parentNode.id === "ukSvg-wales") {
      target = "ukSvg-wales";
    }

    // make sure hover is applied to all shapes of region
    this.hoverRegionGroup(event, true);
    const country = indexToCountry(this.props.data.get("country"));
    if (this.state.otherHighlightedElement) {
      this.state.otherHighlightedElement.classList.remove("hover");
    }
    const shape = target.includes("Svg") ? "Arc" : "Svg";
    this.mapDomNode = ReactDOM.findDOMNode(this.mapElement);

    const id = `#${country.substring(0, 2)}${shape}-${this.clipMapNamespace(
      target
    )}`;
    const elem = this.mapDomNode.querySelector(id);

    if (elem) {
      this.setState({
        otherHighlightedElement: elem,
      });

      elem.classList.add("hover");
    }

    // add hover attribute to arc caArc-britishColumbia
    this.props.dispatchMapAreaHover({
      location: this.clipMapNamespace(target),
      country,
    });
  }

  mapSectionHover(event) {
    const toolTipVisible = this.props.map.get("map_hover").value;

    if (!toolTipVisible) {
      // make sure hover is de-applied to all shapes of region
      this.hoverRegionGroup(event, false);
    }

    this.setState({
      toolTipPosition: {
        left: event.clientX,
        top: event.clientY,
      },
      toolTipVisible,
    });
  }

  updateUrl(location) {
    const country = indexToCountry(this.props.data.get("country"));
    const year = indexToYear(this.props.data.get("year"));
    history.replaceState(
      {},
      null,
      `/#/${country}/${year}/${camelToHyphen(location)}`
    );
  }

  mapSectionClick(event) {
    let target = event.target.id;
    const country = indexToCountry(this.props.data.get("country"));

    if (!target) {
      target = event.target.parentNode.id;
    }

    // fix for grouping of scotland
    target = target.replace("scotlandMainLand", "scotland");
    target = target.replace("wales1", "wales");
    target = target.replace("wales2", "wales");

    if (!target || target.includes("restOfUs")) {
      return;
    }

    this.props.dispatchMapAreaClick({
      location: this.clipMapNamespace(target),
      country,
    });

    this.updateUrl(this.clipMapNamespace(target));

    const header = document.querySelector(".InfoPaneContent-header");
    const headerHeight = -header.clientHeight;

    if (this.clipMapNamespace(target).length > 4) {
      scroller.scrollTo(this.clipMapNamespace(target), {
        duration: 500,
        delay: 0,
        smooth: true,
        offset: headerHeight + 25,
      });
    }
  }

  render() {
    const country = this.props.data.get("country");

    const year = this.props.data.get("year");
    const currentCountry = COUNTRIES[country];

    const introVisible = this.props.introVisible;
    const activeArea = this.props.area.get("active_area");
    const mapHiddenClass = introVisible ? "" : "Map--introHidden";
    const mainClassName = `Map ${mapHiddenClass}`;
    const hoverData = {
      year: this.props.data.get("year"),
      country: this.props.data.get("country"),
      data: this.props.map.get("map_hover").data,
    };

    return (
      <div className={mainClassName}>
        <div className={this.getClassName(hoverData.data)}>
          {currentCountry === "uk" ? (
            <MapLegend />
          ) : (
            <h2 className="Map-heading description">Jobs supported:</h2>
          )}
          <>
            <ToolTip
              visible={this.state.toolTipVisible}
              position={this.state.toolTipPosition}
              theme="Map"
              color="#2B2845"
            >
              {hoverData.data ? (
                <MapToolTipContent country={country} toolTip={hoverData} />
              ) : null}
            </ToolTip>
          </>

          <h2 className="Map-heading">{`Total employment by ${AREAS[country]}`}</h2>
          {currentCountry === "uk" && (
            <UKMapSVGContainer
              className={`${this.getMapClass(activeArea)} ${currentCountry}`}
              onMouseMove={this.mapSectionHover.bind(this)}
              onClick={this.mapSectionClick.bind(this)}
              onMouseOver={this.mapSectionEnter.bind(this)}
              ref={(map) => {
                this.mapElement = map;
              }}
              activeArea={this.state.currentLocation}
              data={this.props.data}
            />
          )}
          {currentCountry === "usa" && (
            <USAMapSVGContainer
              className={`${this.getMapClass(activeArea)} ${currentCountry}`}
              onMouseMove={this.mapSectionHover.bind(this)}
              onClick={this.mapSectionClick.bind(this)}
              onMouseOver={this.mapSectionEnter.bind(this)}
              ref={(map) => {
                this.mapElement = map;
              }}
              activeArea={this.state.currentLocation}
              data={this.props.data}
            />
          )}
          {currentCountry === "canada" && (
            <CanadaMapSVGContainer
              className={`${this.getMapClass(activeArea)} ${currentCountry}`}
              onMouseMove={this.mapSectionHover.bind(this)}
              onClick={this.mapSectionClick.bind(this)}
              onMouseOver={this.mapSectionEnter.bind(this)}
              ref={(map) => {
                this.mapElement = map;
              }}
              activeArea={this.state.currentLocation}
              data={this.props.data}
            />
          )}
          {currentCountry === "canada" && (
            <div className={"pieMapKey"}>
              <div>
                <div>British Columbia</div>
                <div>
                  {this.props.data
                    .get("data")
                    .britishColumbia.jobsTotal[year].toLocaleString()}
                </div>
              </div>
              <div>
                <div>Rest of Canada</div>
                <div>
                  {this.props.data
                    .get("data")
                    .restOfCanada.jobsTotal[year].toLocaleString()}
                </div>
              </div>
            </div>
          )}
          {currentCountry === "usa" && (
            <div className={"pieMapKey"}>
              <div>
                <div>Louisiana</div>
                <div>
                  {this.props.data
                    .get("data")
                    .louisiana.jobsTotal[year].toLocaleString()}
                </div>
              </div>
              <div>
                <div>Mississippi</div>
                <div>
                  {this.props.data
                    .get("data")
                    .mississippi.jobsTotal[year].toLocaleString()}
                </div>
              </div>
              <div>
                <div>Alabama</div>
                <div>
                  {this.props.data
                    .get("data")
                    .alabama.jobsTotal[year].toLocaleString()}
                </div>
              </div>
              <div>
                <div>Rest of U.S.</div>
                <div>
                  {(
                    this.props.data.get("data").summary.jobsTotal[year] -
                    this.props.data.get("data").louisiana.jobsTotal[year] -
                    this.props.data.get("data").mississippi.jobsTotal[year] -
                    this.props.data.get("data").alabama.jobsTotal[year]
                  ).toLocaleString()}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Map.propTypes = {
  dispatchMapAreaHover: PropTypes.func.isRequired,
  dispatchMapAreaClick: PropTypes.func.isRequired,
  introVisible: PropTypes.bool.isRequired,
  map: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired,
  data: PropTypes.object,
};

function mapStateToProps(state) {
  // state
  return {
    map: state.map,
    area: state.area,
    data: state.data,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...MapActions,
    },
    dispatch
  );
}

export const MapContainer = connect(mapStateToProps, mapDispatchToProps)(Map);
