import { Map } from "immutable";

const init = new Map({
  mobile_share: {
    open: false,
    props: {},
  },
});

/* eslint-disable max-len */

export default (state = init, action) => {
  switch (action.type) {
    case "MOBILE_SHARE":
      return state.set("mobile_share", { props: action.props, open: action.props.open });
    case "MOBILE_SEARCH":
    case "MOBILE_YEAR":
    case "MOBILE_DOWNLOAD":
      return state.set("mobile_share", { props: {}, open: false });
    default:
      return state;
  }
};


/* eslint-enable max-len */
