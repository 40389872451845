import { COUNTRIES } from "./constants";

export function indexToCountry(key) {
  return key in COUNTRIES ? COUNTRIES[key] : "UK";
}

export function countryToIndex(country) {
  const key = COUNTRIES.indexOf(country);
  return key > -1 ? key : 2;
}
