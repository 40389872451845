import area from "./modules/shared/area_reducer";
import { combineReducers } from "redux";
import data from "./modules/shared/data_reducer";
import intro from "./modules/components/intro/intro_reducer";
import map from "./modules/components/map/map_reducer";
/* eslint-disable max-len */
import mobileDownload from "./modules/components/mobile_download_dropdown/mobile_download_reducer";
import mobileSearch from "./modules/components/mobile_search_dropdown/mobile_search_reducer";
import mobileShare from "./modules/components/mobile_share_dropdown/mobile_share_reducer";
import mobileYear from "./modules/components/mobile_year_dropdown/mobile_year_reducer";
import mobileCountry from "./modules/components/mobile_country_dropdown/mobile_year_reducer";
import sharingLinks from "./modules/components/sharing_links/share_links_reducer";
/* eslint-enable max-len */



export default combineReducers({
  intro,
  area,
  data,
  map,
  mobileShare,
  mobileSearch,
  mobileDownload,
  mobileCountry,
  mobileYear,
  sharingLinks,
});
