import React from "react";
import PropTypes from "prop-types";

function toSpaceCase(string) {
  return string.replace(
    /([a-z][A-Z])/g,
    (g) => {
      return `${g[0]} ${g[1]}`;
    });
}

function firstCharacterCapitalization(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const MobileSearchItem = (props) => {
  return (
    <span
      onClick={ () => { props.itemClicked(props.item, props.dispatchClose); }}
      className="MobileDropdown-item">
      {firstCharacterCapitalization(toSpaceCase(props.item))}
    </span>
  );
};

MobileSearchItem.propTypes = {
  dispatchClose: PropTypes.func,
  item: PropTypes.string,
  itemClicked: PropTypes.func,
};

export default MobileSearchItem;
