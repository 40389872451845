import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

export default class ToolTip extends React.Component {
  getToolTipStyle() {
    const computedPosition = this.computePosition(this.props.position);

    const evalStyle = {};

    if (this.props.color) {
      evalStyle.color = this.props.color;
    }

    if (this.props.backgroundColor) {
      evalStyle.backgroundColor = this.props.backgroundColor;
      evalStyle.borderColor = `${this.props.backgroundColor}`;
    }

    return Object.assign(computedPosition, evalStyle);
  }

  computePosition(position) {
    let computedPosition;

    if (this.curElement) {
      const rect = this.curElement.getBoundingClientRect();
      computedPosition = {
        top: Math.round(position.top - (rect.height + 15), 2),
        left: Math.round(position.left - (rect.width / 2), 2),
      };
    } else {
      computedPosition = {
        top: 0,
        left: 0,
      };
    }

    return computedPosition;
  }

  render() {
    const toolTipStyle = this.getToolTipStyle();

    let toolTipClass = "ToolTip";
    if (this.props.visible) {
      toolTipClass += " ToolTip--visible";
    } if (this.props.theme) {
      toolTipClass += ` ToolTip--${this.props.theme}`;
    }

    return (
      <div
        ref={(element) => { this.curElement = element; }}
        className={toolTipClass}
        style={toolTipStyle}
      >
        {this.props.children}
      </div>
    );
  }
}

ToolTip.propTypes = {
  position: PropTypes.object.isRequired,
  visible: PropTypes.bool,
  children: PropTypes.object,
  theme: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
};
